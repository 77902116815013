export const feedback_questions = {
    "tyky_feedback_1":{id:"tyky_feedback_1", type: "options_check_horizontal", form_type: "feedback", title:
        {fi:"Ohjasiko testistä aikaisemmin saamasi palaute hyödyntämään sinulle suositeltuja työkykyä tukevia palveluita?",
         sv: "Gjorde responsen i det tidigare testet att du började utnyttja tjänster som rekommenderades dig?"},
         options: [
            {id: "true", fi: "Kyllä", sv: "Ja"},
            {id: "false", fi: "Ei", sv: "Nej"},
            {id: "no_answer", fi: "En osaa sanoa", sv: "Kan inte säga"}
         ]
    },
    "tyky_feedback_2":{id:"tyky_feedback_2", form_type: "feedback", show_only_when: {question: "tyky_feedback_1", answer: "true"}, type: "options_check_horizontal", title:
        {fi: "Koitko palveluohjauksen hyödylliseksi?",
         sv: "Upplevde du att hänvisningen till tjänsterna var nyttig?"},
        options: [
            {id: "true", fi: "Kyllä", sv: "Ja"},
            {id: "false", fi: "Ei", sv: "Nej"},
            {id: "no_answer", fi: "En osaa sanoa", sv: "Kan inte säga"}
        ]
    }
}