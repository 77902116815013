import I18n from 'i18n-js'

import fi from './config/locales/fi';
import sv from './config/locales/sv';

const windowUrl = window.location.search;
const sp = new URLSearchParams(windowUrl);
I18n.translations = {
    "fi": fi,
    "sv": sv
}
I18n.defaultLocale = "fi"
I18n.fallbacks = "fi"
I18n.locale = sp.get("lang") || localStorage.getItem("locale") ||  I18n.defaultLocale
export default I18n
