import { Component } from 'react';
import jQuery, { type } from 'jquery';

import I18n from '../locales';
class AccessibilityStatement extends Component {

  constructor(props) {
    super(props);
   }

  render () { 
    const sections = I18n.t("accesibility_content.sections");
    return <div className="AccessibilityStatement">
      <div className="container mb-5">
        {sections.map(s => {
          return <>
          <div className="row mb-2">
            <h4 className='mela-blue' style={{fontWeight: 600}}>{s["title"]}</h4>
            {s["texts"].map(t => {
              if (Array.isArray(t)) {
                return <p>
                {t.map( tt => {
                  if (typeof tt == String || tt.constructor == String){
                    return tt
                  } else if (tt.constructor == Object){
                    return <a href={tt["href"]} target="_blank" >{tt["text"]}</a>
                  }
                })}
                </p>
              }else{
                return <p>{t}</p>
              }
            })}
            </div>
          
          </>
        })}
      </div>
    </div>
  }





}

export default AccessibilityStatement;
