import React, { Component } from 'react'
import I18n from 'i18n-js';
export default class MaintenanceAlert extends Component {

    constructor(props) {
        super(props);
    };

    formatDate = (date, sep = ".") => {
        if (!date) return ""
        let day = '' + date.getDate();
        let year = date.getFullYear();
        let month = '' + (date.getMonth() + 1);
        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [day, month, year].join(sep);
    }

    render() {
        let { maintenanceEstimatedEnd } = this.props;

        let parsedDate = maintenanceEstimatedEnd && new Date(maintenanceEstimatedEnd);
        let includesHour = !!(parsedDate.getHours() || parsedDate.getMinutes())

        return maintenanceEstimatedEnd && includesHour ? <div class="alert alert-warning" role="alert" style={{marginBottom: "6em"}}>
            {I18n.t('maintenance.message_estimate_hour', { 'date': this.formatDate(parsedDate), 'time' : parsedDate.getHours()})}
        </div> : maintenanceEstimatedEnd && !includesHour ? <div class="alert alert-warning" role="alert" style={{marginBottom: "6em"}}>
            {I18n.t('maintenance.message_estimate', { 'date': this.formatDate(parsedDate)})}
        </div> : <div class="alert alert-warning" role="alert" style={{marginBottom: "6em"}}>
            {I18n.t('maintenance.message')}
        </div>
    }
}

export const handleMaintenanceException = (e, component, updateState = true) => {
    let response = (e.status === 503 && e.responseText && JSON.parse(e.responseText)) || {};
    if (e.status === 503 && response['maintenance']) {
        if (updateState) {
            component.setState({ maintenanceEnabled: true, maintenanceEstimatedEnd: response['maintenance_estimated_end'] });
        }
        return true;
    }
    return false;
}