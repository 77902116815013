import React, { Component } from 'react'
import I18n from 'i18n-js';
import './QuestionForm.scss';
import Select from "react-select";
import Modal from '../components/Modal';

import { BsQuestionCircle} from "react-icons/bs";
import { production_line_range } from "../config/questions";

const customStyles = {
    control: (styles) => ({
                ...styles,
                borderRadius: "0 !important",
            }),
    menu: (styles) => ({
                ...styles,
                borderRadius: "0 !important",
            }),
  }

export default class QuestionForm extends Component {

    constructor(props)
    {
        super(props);
    };

    render(){
        let {handleCheckChange, handleProductionLineChange, answers, question, disabled} = this.props;
        let key = question.id;
    return <>
    <h6 className={`mela-blue ${!question['form_number'] ? 'ms-3' : ''}`}>{(question['form_number'] ? `${question['form_number']}. ` : "") + question.title[I18n.locale]} {question.type == "user_profile" && <Modal toggleButton={<BsQuestionCircle className="ms-1" style={{marginBottom: "0.2rem", cursor:"pointer", color: "inherit"}}/>}>
            <p className='m-2' style={{fontSize: "90%"}}>{I18n.t("form.profile_help")}</p>
            </Modal>}</h6>

            
            {// Dropdown options select
            question.type == "options_dropdown" && <div className="form-options q_options">
              <select className="form-select form-select-sm" aria-label=".form-select-sm example">
              {question.options.map((opt, i) => {
                return <option value={opt.id} key={i}>{opt[I18n.locale]}</option>
              })}
              </select>
            </div>}

            {// Checkbox options select
            question.type == "options_check" && <div className="form-options q_options">
              {question.options.map((opt, i) => {
                return <div className="form-check" key={i}>
                  <input className="form-check-input" type="radio" disabled={disabled} value={opt.id} name={key} id={key+"_"+opt.id} 
                  checked={answers[key]==opt.id} onChange={handleCheckChange}/>
                  <label className="form-check-label" htmlFor={key+"_"+opt.id}>
                    {opt[I18n.locale]}
                  </label>
                </div>
              })}
            </div>}

            {// Checkbox options select on one line
              question.type == "options_check_horizontal" && <div className="form-options q_options form-opts-horizontal">
              {question.options.map((opt, i) => {
                return <div className="form-check" key={i}>
                  <input className="form-check-input" type="radio" disabled={disabled} value={opt.id} name={key} id={key+"_"+opt.id} 
                  checked={answers[key]==opt.id} onChange={handleCheckChange}/>
                  <label className="form-check-label" htmlFor={key+"_"+opt.id}>
                    {opt[I18n.locale]}
                  </label>
                </div>
              })}
            </div>
            }

            {// Scale from 1 to X
            question.type == "scale" && <div className={"form-options q_scale_"+(question.options.length-1)}>
              {question.options.map((opt, i) => {
                return <div className="form-check" key={i}>
                  <input className="form-check-input" type="radio" value={opt.id} name={key} id={key+"_"+opt.id} 
                  onChange={handleCheckChange} checked={answers[key]==opt.id}  disabled={disabled} />
                  <label className="form-check-label" htmlFor={key+"_"+opt.id}>
                    {opt.id}
                  </label>
                </div>
              })}
            </div>}

            {// Multiple row table select
            question.type == "table" && <div className="form-options q_table">
            {question.options.map((opt, i) => {
              let f_key = key+"__"+opt.id;
              return <div className="form-table-option row"> 

                <div className="table-option-title col-12 col-md-9" key={i}>
                {opt[I18n.locale]}
                </div>
                <div className='col-12 col-md-3'>
                  <div className="form-check d-block d-md-inline-block">
                    <input className="form-check-input" type="radio" value={false} name={f_key} id={f_key+"_no"} 
                    onChange={handleCheckChange} checked={answers[f_key]=="false"}  disabled={disabled} />
                    <label className="form-check-label" htmlFor={f_key+"_no"}>
                      {I18n.t("no")}
                    </label>
                  </div>
                  <div className="form-check d-block d-md-inline-block">
                    <input className="form-check-input" type="radio" value={true} name={f_key} id={f_key+"_yes"} 
                    onChange={handleCheckChange} checked={answers[f_key]=="true"}  disabled={disabled} />
                    <label className="form-check-label" htmlFor={f_key+"_yes"}>
                      {I18n.t("yes")}
                    </label>
                  </div>
                </div>
              </div>
            })}
          </div>}

          {// User profile answers
          question.type == "user_profile" && <div className="form-options user_profile_data">
                    
            <div className="form-group row mb-2">
              <label className="col-sm-8 col-form-label">{I18n.t("age")}</label>
              <div className="col-sm-4">
                <input type="number" name={key+"__age"} className="form-control custom-input" 
                onChange={handleCheckChange} value={answers[key+"__age"] || ""} min="16" max="120" placeholder={I18n.t("years")}>
                </input>
              </div>
            </div>
            
            {/* <div className="form-group row mb-2">
              <label className="col-sm-8 col-form-label">{I18n.t("age")}</label>
              <div className="col-sm-4">
                <select name={key+"__age"} className="form-control custom-select" 
                onChange={handleCheckChange} value={answers[key+"__age"] || I18n.t("choose")+"..."}>
                  <option>{I18n.t("choose")}...</option>
                  {[...Array(101)].map((e,i) => {
                    if(i >= 18 && i <= 100)
                    return <option>{i}</option>
                  })}
                </select>
              </div>
            </div> */}


            {/* SEX */}
            <div className="form-group row mb-2">
              <label className="col-sm-8 col-form-label">{I18n.t("sex")}</label>
              <div className="col-sm-4 pt-2">
                <div className="form-check float-right ms-4">
                  <input className="form-check-input" value="m" type="radio" name={key+"__sex"} id="m" 
                  onChange={handleCheckChange} checked={answers[key+"__sex"]=="m"}/>
                  <label className="form-check-label" htmlFor="m">
                  {I18n.t("man")}
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input" value="f" type="radio" name={key+"__sex"} id="f" 
                  onChange={handleCheckChange} checked={answers[key+"__sex"]=="f"}/>
                  <label className="form-check-label" htmlFor="f">
                    {I18n.t("woman")}
                  </label>
                </div>
              </div>
            </div>

            {/* IS FARMER 
            <div className="form-group row mb-2">
              <label className="col-sm-8 col-form-label">{I18n.t("im_farmer")}</label>
              <div className="col-sm-4 pt-2">
                <div className="form-check float-right ms-4">
                  <input className="form-check-input" value='false' type="check" name={key+"__non_farmer"} id='false' 
                  onChange={handleCheckChange} checked={answers[key+"__non_farmer"]=='false'}/>
                  <label className="form-check-label" htmlFor='false'>
                    {I18n.t("no")}
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input" value='yes' type="radio" name={key+"__non_farmer"} id='yes' 
                  onChange={handleCheckChange} checked={answers[key+"__non_farmer"]=='yes'}/>
                  <label className="form-check-label" htmlFor='yes'>
                  {I18n.t("yes")}
                  </label>
                </div>
              </div>
            </div> */}

            <div className="form-group row mb-2">
              <label className="col-sm-8 col-form-label">{I18n.t("production_line")}</label>
              <div className="col-sm-4">
                <Select name={key+"__production_line"} options={production_line_range.map(v => {return {label: I18n.t("production_lines."+v), value: v}})} 
                value={answers[key+"__production_line"] ? 
                {label: I18n.t("production_lines."+answers[key+"__production_line"]), value: answers[key+"__production_line"]} : 
                {label: I18n.t("choose")+"...", value: null}
                } onChange={(v) => {handleProductionLineChange(key+"__production_line", v.value);}} styles={customStyles}></Select>
              </div>
            </div>

            {/* IS FARMER */}
            <div className="form-check mt-3">
              <input className="form-check-input" value='false' type="checkbox" name={key+"__non_farmer"} id='false' 
              onChange={handleCheckChange} checked={answers[key+"__non_farmer"]=='true'}/>
              <label className="form-check-label" htmlFor='false'>
                {I18n.t("im_not_farmer")}
              </label>
            </div>
            
          </div>}


          {//Dont want to answer button
          question.type != "user_profile" && question.form_type != "feedback" && <div className="form-options mt-4">
            <div className="form-check">
              <input className="form-check-input" type="radio" value={"-1"} name={key} id={key+"_undefined"} 
              checked={answers[key]=="-1"} onChange={handleCheckChange} disabled={disabled} />
              <label className="form-check-label" htmlFor={key+"_undefined"} >
                {I18n.t(key == "tyky_6" || key == "tyky_10" ? "form.i_dont_want_to_answer_any": "form.i_dont_want_to_answer")}
              </label>
            </div>
          </div>
          }
        </>
    }
}