import I18n from 'i18n-js'
import React, { Component } from 'react'

export default class Footer extends Component {
    render() {
        return (
            <footer className="footer">
                <div className="w-100">
                    {this.props.validIpMaintenance == true &&
                        <div className='maintenance-valid-container'>
                            <p style={{textAlign:"center"}} className="m-0">{I18n.t("footer.maintenance_valid_use")}</p>
                        </div>
                    }
                    <p style={{textAlign:"center"}} className="m-0">{I18n.t("footer.text")}<br/><br/>
                    <a href={I18n.t("statements.data_protection")} className="me-0" target="popup" onClick={this.openPopup}>
                        {I18n.t("footer.privacy_statement")}
                    </a>{" | "}  
                    <a href={I18n.t("statements.accesibility_statement")} target="popup" onClick={this.openPopup}>
                        {I18n.t("footer.accesibility_statement")}
                    </a></p>
                </div>
            </footer>
        )
    }


    openPopup(elem) {
        let url = elem.currentTarget
        let title =  "Popup"
        let w=600
        let h=800
        var left = (window.screen.width/2)-(w/2);
        var top = (window.screen.height/2)-(h/2);
        window.open(url, title, 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width='+w+', height='+h+', top='+top+', left='+left);
            
        // cancel default event action
        var event = window.event;
        event.preventDefault ? event.preventDefault() : (event.returnValue = false);
        return false;
    } 
}
