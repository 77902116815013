import { Component } from 'react';
import { Redirect } from 'react-router-dom';
import jQuery from 'jquery';
import graphic from '../images/frontpageimage.png';
import bulletIcon from '../images/icons/bullet_icon.png'
import ReCAPTCHA from "react-google-recaptcha";

import I18n from '../locales';
import MaintenanceAlert, { handleMaintenanceException } from '../components/MaintenanceAlert';
class Frontpage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      terms: false,
      recaptcha: false,
      loading: false,
      redirect: false,
      error: false,
      participation: false,
      redirectRetake: undefined,
      checkedUUID: false
    }
    this.retakeID = this.props.match && this.props.match.params.uuid;
   }

  render () { 
    let {terms, recaptcha, loading, redirect, error, redirectRetake} = this.state;
    let {language} = this.props;

    if(language){
      window.history.pushState({}, null, "/");
      if(localStorage.getItem("locale") != language)
      {
        localStorage.setItem("locale", language); 
        window.location.reload(false);
      }
    }
    
    let maintenanceEnabled = true;
    let canContinue = terms&&(recaptcha || process.env.REACT_APP_GOOGLE_RECAPTCHA_DISABLE)&&!loading;
    if( redirect ){
      return <Redirect to={`/form`}/>;
    } else if (redirectRetake){
      return <Redirect to={redirectRetake} />
    } else return (
    <div className="Frontpage">
      <div className="container content-wrapper-wide">
      <img className="img-fluid" src={graphic}></img>
      <div className="col-12 col-md-6 p-4 d-flex flex-column w-100 align-items-center">
        <h2 className="mb-0 text-center-txt" style={{color:"#333399"}}>{I18n.t("home.title")}</h2>
        <p className="mela-blue pt-2 text-center-txt">{I18n.t("home.description")}</p>
        <br/>
        <div className='row'>
          <div className='row'>
            <div className='col-md d-flex align-items-baseline'>
              <img className="float-left bullet-icon-size" src={bulletIcon}></img>
              <p className="mela-blue ps-1 sm-description">{I18n.t("home.description_1")}</p>
            </div>
            <div className='col-md d-flex align-items-baseline'>
              <img className="float-left bullet-icon-size" src={bulletIcon}></img>
              <p className="mela-blue ps-1 sm-description">{I18n.t("home.description_3")}</p>
            </div>
          </div>
          <div className='row'>
            <div className='col-md d-flex align-items-baseline'>
              <img className="float-left bullet-icon-size" src={bulletIcon}></img>
              <p className="mela-blue ps-1 sm-description">{I18n.t("home.description_2")}</p>
            </div>
            <div className='col-md d-flex align-items-baseline'>
              <img className="float-left bullet-icon-size" src={bulletIcon}></img>
              <p className="mela-blue ps-1 sm-description">{I18n.t("home.description_4")}</p>
            </div>
          </div>
        </div>
        <div className="row w-80 align-items-center">
          {
            !this.state.maintenanceEnabled &&
            <>
              <div className="col-12 col-sm-8 col-lg-7">
                <div className="form-check text-left-txt">
                  <input className="form-check-input" type="checkbox" id="termsCheckbox" value={terms} onChange={this.handleCheckbox}/>
                  <label className="form-check-label" htmlFor="termsCheckbox">
                    {I18n.t("home.terms_text")} <a href={I18n.t("statements.data_protection")} target="popup" 
                    onClick={this.openPopup}>{I18n.t("home.terms_link")}</a>
                  </label>
                </div>
                <div className="row mb-4 mb-lg-0  ">
                {!process.env.REACT_APP_GOOGLE_RECAPTCHA_DISABLE &&<ReCAPTCHA
                  sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITEKEY || "google-recaptcha-site-key"}
                  hl={I18n.locale}
                  onChange={this.recaptchaChange}
                />}
                </div>
              </div>
              <div className="col-12 col-sm-4 col-lg-5">
                <div className="col-12 mt-4 mb-4">
                    <div onClick={this.continueToApp} className={"btn btn-primary d-none d-lg-inline-block" + ((canContinue && !error)?"":" disabled")} 
                    style={{height:"60px", pointerEvents: "all", cursor: canContinue?"pointer":"not-allowed", lineHeight:"60px", width: "300px", padding:"00px 70px"}}> {I18n.t("home.continue_text")}  
                      {loading && <div className="spinner-border spinner-border-sm ms-2" role="status">
                        <span className="visually-hidden">{I18n.t("loading")}...</span>
                      </div>}
                    </div>

                    <div onClick={this.continueToApp} className={"btn btn-primary d-none d-sm-block d-lg-none" + ((canContinue && !error)?"":" disabled")}
                    style={{height:"110px", pointerEvents: "all", cursor: canContinue?"pointer":"not-allowed", lineHeight:"110px", padding:"0 40px", marginTop:"10px"}}> {I18n.t("home.continue_text")}  
                      {loading && <div className="spinner-border spinner-border-sm ms-2" role="status">
                        <span className="visually-hidden">{I18n.t("loading")}...</span>
                      </div>}
                    </div>


                    <button onClick={this.continueToApp} className={"btn btn-primary w-100 mb-4 d-sm-none" + ((canContinue && !error)?"":" disabled")}
                    style={{height:"90px", pointerEvents: "all", cursor: canContinue?"pointer":"not-allowed", lineHeight:"90px", padding:"0 40px", marginTop:"10px"}}> {I18n.t("home.continue_text")}  
                      {loading && <div className="spinner-border spinner-border-sm ms-2" role="status">
                        <span className="visually-hidden">{I18n.t("loading")}...</span>
                      </div>}
                    </button>

                </div>
              </div>
            </>
          }
          {error && <div className="alert alert-danger" role="alert">
                {I18n.t("errors.retry")}
              </div>}
          <div><input className="participation-input" type="checkbox" id="participationCheckbox" value={this.state.participation} onChange={this.handleParticipation}/></div>
          <br></br>
        </div>
      </div>
        {this.state.maintenanceEnabled ? <p>
          <MaintenanceAlert maintenanceEstimatedEnd={this.state.maintenanceEstimatedEnd}/>
        </p> : <>
        <div className="row">
        <p className="mb-4">
        {I18n.t("home.main_text_top")}
        </p>
        <p className="mb-4">
        {I18n.t("home.main_text_bottom")}
        </p>
        </div>
        <div className="row">
          <p className="mb-4">{I18n.t("home.data_used_notice")}</p>
        </div>


        </>}
      </div>
    </div>
  );}

  handleParticipation = () => {
    this.setState({
      participation: true
    })
  }

  handleCheckbox = (e) =>{
    this.setState((prevState) => {
        let terms = !prevState.terms;
        return {terms: terms};
    });
  }
  recaptchaChange = (value) =>{
    this.setState({recaptcha: value})
  }


  openPopup(elem) {
    let url = elem.currentTarget
    let title =  "Popup"
    let w=600
    let h=800
    var left = (window.screen.width/2)-(w/2);
    var top = (window.screen.height/2)-(h/2);
    window.open(url, title, 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width='+w+', height='+h+', top='+top+', left='+left);
        
    // cancel default event action
    var event = window.event;
    event.preventDefault ? event.preventDefault() : (event.returnValue = false);
    return false;
} 

warmup = (initial_call = false) =>{
  jQuery.post(process.env.REACT_APP_PROXY+"access", {
      warmup: true,
      initial_call: initial_call
  })
  .then((res) => {
    this.props.setValidIpMaintenance(res.valid_ip)
  })
  .catch((e) =>{
    handleMaintenanceException(e, this);
  })
}

  componentDidMount() {
    if (this.props.match){
      jQuery.post(process.env.REACT_APP_PROXY+"check_retake_link", {
        retake_id: this.retakeID
      }).catch( (e) =>{
        if(!handleMaintenanceException(e, this)){
          this.setState({error: true, loading: false});
          alert(I18n.t("home.not_found"))
        }
      })
    }
    this.warmup(true);
    let interval = setInterval(this.warmup, 300000);
  }

  continueToApp = () => {
    let {terms, recaptcha, participation} = this.state;
    let canContinue = terms&&(recaptcha || process.env.REACT_APP_GOOGLE_RECAPTCHA_DISABLE);
    if(canContinue && this.retakeID){
      this.setState({error:false, loading: true}, () => {
          jQuery.post(process.env.REACT_APP_PROXY+"access", {terms: terms, recaptcha: recaptcha, participate: participation})
          .then((resp) => {
            this.props.clearAnswers(() => {
              this.props.setToken(resp.token, () => {
                this.setState({redirectRetake: `/results/${this.props.match.params.uuid}/retake_form`})
              });
            })
          }).catch( (e) =>{
            if(!handleMaintenanceException(e, this)){
              this.setState({error: true, loading: false});
            }
          })
      });
    }else if (canContinue && !this.retakeID){
      this.setState({error:false, loading: true}, () => {
        jQuery.post(process.env.REACT_APP_PROXY+"access", {terms: terms, recaptcha: recaptcha, participate: participation})
        .then((resp) => {
          this.props.clearAnswers(() => {
            this.props.setToken(resp.token, () => {
              this.setState({redirect: true})
            });
          })
        }).catch( (e) =>{
          if(!handleMaintenanceException(e, this)){
            this.setState({error: true, loading: false});
          }
        })
    });
    } else {
      alert(I18n.t("home.terms_error_alert"))
    }
    
  }

}

export default Frontpage;
