import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

// Styles
import './stylesheets/application.scss';
import './stylesheets/main.css';

// Views
import Form from './views/Form.jsx';
import Frontpage from './views/Frontpage.jsx';

// Components
import Header from './components/Header'
import Footer from './components/Footer'
import PersistentComponent from './components/PersistentComponent';
import ScrollToTop from './components/ScrollToTop';
import Results from './views/Results';
import AccessibilityStatement from './views/AccessibilityStatement'


// Switch layout based on route
class Application extends PersistentComponent {
  
  constructor(props) {
    super(props);
    this.savename = "a"
    this.state = {
      accessToken: "accessToken" in this.state? this.state.accessToken : null,
      answers: "answers" in this.state? this.state.answers : {},
      evaluation: "evaluation" in this.state? this.state.evaluation : {},
      evaluation_date: "evaluation_date" in this.state? this.state.evaluation_date : null,
      previous_evaluations: "previous_evaluations" in this.state? this.state.previous_evaluations: [],
      retake_id: "retake_id" in this.state? this.state.retake_id: null,
      redirect: false,
      valid_ip_maintenance: false
    }
    this.loadState();
   }

  render() {
    let {accessToken, answers, evaluation, evaluation_date, previous_evaluations, retake_id, valid_ip_maintenance} = this.state;
    return <Router>
      <ScrollToTop />
      <Switch>
        <Route path="/form">
          <Header></Header>
          <Form accessToken={accessToken} answers={answers} setAnswers={this.setAnswers} setEvaluation={this.setEvaluation} setToken={this.setAccessToken} setPreviousEvaluations={this.setPreviousEvaluations} setRetakeID={this.setRetakeID} />
        </Route>
        <Route path='/results/:uuid/r' render={(props) => 
        <>
          <Header frontpage={true}></Header>
          <Frontpage {...props} setToken={this.setAccessToken} clearAnswers={this.clearAnswers}  setValidIpMaintenance={this.setValidIpMaintenance} />
        </>} />
        <Route path="/results/:uuid/retake_form" render={(props) => 
          <><Header></Header><Form {...props} accessToken={accessToken} answers={answers} setAnswers={this.setAnswers} setEvaluation={this.setEvaluation} setToken={this.setAccessToken} setPreviousEvaluations={this.setPreviousEvaluations} setRetakeID={this.setRetakeID} /></>} />
        <Route path="/results/:uuid" render={(props) => 
          <><Header></Header><Results {...props} accessToken={accessToken} answers={answers} evaluation={evaluation} evaluation_date={evaluation_date} setToken={this.setAccessToken} clearData={this.clearData} previous_evaluations={previous_evaluations} retake_id={retake_id} setValidIpMaintenance={this.setValidIpMaintenance}  validIpMaintenance={valid_ip_maintenance} /></>} />
        <Route path="/results">
        <Header></Header>
          <Results accessToken={accessToken} answers={answers} evaluation={evaluation} evaluation_date={evaluation_date} clearData={this.clearData} previous_evaluations={previous_evaluations} retake_id={retake_id} setValidIpMaintenance={this.setValidIpMaintenance}  validIpMaintenance={valid_ip_maintenance} />
        </Route>
        <Route path="/accessibility">
          <Header></Header>
          <AccessibilityStatement/>
        </Route>
        <Route path="/sv">
          <Header frontpage={true}></Header>
          <Frontpage setToken={this.setAccessToken} clearAnswers={this.clearAnswers}  setValidIpMaintenance={this.setValidIpMaintenance} language={"sv"}/>
        </Route>
        <Route path="/fi">
          <Header frontpage={true}></Header>
          <Frontpage setToken={this.setAccessToken} clearAnswers={this.clearAnswers}  setValidIpMaintenance={this.setValidIpMaintenance} language={"fi"}/>
        </Route>
        <Route path="/">
          <Header frontpage={true}></Header>
          <Frontpage setToken={this.setAccessToken} clearAnswers={this.clearAnswers}  setValidIpMaintenance={this.setValidIpMaintenance} />
        </Route>
      </Switch>
      <Footer validIpMaintenance={valid_ip_maintenance}></Footer>
    </Router>;
  }

  setAccessToken = (token, callback) => {
    this.setState({accessToken: token}, callback)
  }
  clearData = (callback) => {
    new Form().deleteState();
    this.deleteState();
    callback();
  }

  clearAnswers = (callback) => {
    new Form().deleteState();
    this.setState({answers: {}}, callback)
  }
  setAnswers = (answers, callback) => {
    this.setState({answers: answers}, callback)
  }
  setEvaluation = (evaluation, callback) => {
    this.setState({evaluation: evaluation, evaluation_date: new Date()}, callback)
  }
  setPreviousEvaluations = (previous_evaluations, callback) => {
    this.setState({previous_evaluations: previous_evaluations}, callback)
  }
  setRetakeID = (retake_id, callback) => {
    this.setState({retake_id: retake_id}, callback)
  }
  setValidIpMaintenance = (valid, callback) => {
    this.setState({valid_ip_maintenance: valid ? valid : false}, callback)
  }

  componentDidMount = () => {
    if(this.state.accessToken){
      // TODO redirect to form?
    }
  }
}

// Insert application to DOM
document.addEventListener("DOMContentLoaded", () => {
  document.body.innerHTML = '<div id="app"></div>';
  createRoot(document.getElementById('app')).render(<Application />);
});