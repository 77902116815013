import I18n from 'i18n-js';
import React, { Component } from 'react'
import mela_logo_fi from '../images/mela_rgb.png';
import mela_logo_sv from '../images/lpa_rgb.png';

import vv_logo_fi from '../images/vv_logo_fi.png';
import vv_logo_sv from '../images/vv_logo_sv.png';
export default class Header extends Component {
    constructor(props)
    {
        super(props);
    };

    render() {
        return (
            <div className={"site-header position-relative " + (this.props.frontpage ? "container content-wrapper-wide" : "container container-small")}>
                <div className="w-100 text-center mt-md-2" style={{top:0, left:0, right:0}}>
                <a href={I18n.t("header.href_mela")} >
                    <img src={I18n.locale === "sv" ? mela_logo_sv : mela_logo_fi} alt={I18n.t("header.logo_alt")} id="header_logo" className="d-inline-block align-top float-left"></img>
                </a>
                <a className='mela-blue' style={{cursor: "pointer", textDecoration: "none"}} onClick={() => {localStorage.setItem("locale", "fi"); window.location.reload(false);}}>Suomeksi</a>{" | "}
                <a className='mela-blue' style={{cursor: "pointer", textDecoration: "none"}} onClick={() => {localStorage.setItem("locale", "sv");window.location.reload(false);}}> På svenska</a>
                <a href={I18n.t("header.href_valita")}>
                    <img src={I18n.locale === "sv" ? vv_logo_sv : vv_logo_fi} id="vv_logo" className="d-inline-block align-top float-right" style={{padding:"0.25rem 0"}}></img>
                </a>
                </div>
            </div>
        )
    }
}
