import React, { Component } from 'react'
import I18n from 'i18n-js';
import jQuery from 'jquery';
import './ResultsBar.scss';
import { BsEmojiFrown, BsEmojiSmile } from "react-icons/bs";

const MOST_RECENT_ANSWER_COLOR = 180;
const SELECTED_POSITION_GAP = 8
const GAP_TO_BAR_END = 4
export default class ResultsBar extends Component {

    constructor(props)
    {
        super(props);
        this.state = {

        }
        this.no_overlap_previous_ans = []
        this.previous_ans_lines = []
        this.overlapSelectedPosition = false
    };

    render() {
        let {selected, groups, group_value, previous_answers, bar_key} = this.props;
        // filter out no answer
        previous_answers = previous_answers.filter((ans) => ans.evaluation != null).map((ans)=> {return {...ans, date: this.formatDate(ans.date)}})
        // Hide very_good range and merge with "good" while maintaining different feedback texts
        if(groups[groups.length-1] === "very_good"){
            // Move user evaluation to "good"
            if (selected >= groups.length-1){
                selected = groups.length-1;
            }
            previous_answers = previous_answers.map((ans) => {
                let current_eval = ans.evaluation;
                if (current_eval >= groups.length-1){
                    current_eval = groups.length-1
                }
                return { ...ans, evaluation: current_eval }
            })
            // Move user evaluation to "good"
            if (group_value >= groups.length-1){
                group_value = groups.length-1;
            }
            // Hide "very_good" range
            groups = groups.slice(0, groups.length-1)
        }
        
        let sides_style = {width: (4.99/2)+"%", display: "inline-block", border: "none"}
        //let side_l_style = {...sides_style, borderRight: "2px solid #fff"}
        //let side_r_style = {...sides_style, borderLeft: "2px solid #fff"}
        let w_style = {width: `${94.99/(groups.length-1)}%`, display: "inline-block"}
        let selected_position = ((selected-1) / (groups.length-1)) * 94.89 + 2.49;
        let previous_answers_pos = previous_answers.map((ans) => ((ans.evaluation-1) / (groups.length-1)) * 94.89 + 2.49)
        let group_position = ((group_value-1) / (groups.length-1)) * 94.89 + 2.49;
        let no_overlap_previous_ans = this.processOverlapPreviousAns(previous_answers, previous_answers_pos, selected_position)
        this.no_overlap_previous_ans = no_overlap_previous_ans;
        let previous_answers_indicator = no_overlap_previous_ans.map((ans, idx) => {

            const current_color = MOST_RECENT_ANSWER_COLOR - idx*20
            if (ans.evaluation != null){
                return (<div key = {`previous_ans_${idx}`} className={"previous_bar_step"} style={{ left: `${ans.no_pos}%`, display: "inline-block"}}>
                        <div style={{backgroundColor: `rgb(${current_color},${current_color},${current_color})`}} className='previous_date'>
                            {ans.date}
                        </div>

                    </div>)
            }
            return null;
        })

        return (
            <div className="results-bar w-100 mt-2 mb-2" aria-hidden="true" id={`result-bar-parent-${bar_key}`}>

                <div className="smile-wrapper"></div>
                <div className="resultsbar-wrapper" >    
                {// Own user tooltip
                }
                {
                    previous_answers_indicator
                }
                {selected != null ? 
                    <div className={this.overlapSelectedPosition ? "selected_bar_step" : "selected_bar_step_non_overlap"} style={{left: `${selected_position}%`, display: "inline-block"}}><div>{I18n.t("mine")}</div></div>:
                    <div className={"empty_bar_step"} style={sides_style}></div>}
                </div><div className="smile-wrapper"></div>

                {/* Center bar */}
                <div className="smile-wrapper">
                    <BsEmojiFrown />
                </div>

                <div className="resultsbar-wrapper" id={`resultsbar-${bar_key}`}>
                <div className={"step "+groups[0]} style={sides_style} data-bs-toggle="tooltip" data-bs-placement="top" title=""></div>
                {groups.slice(0, -1).map((group, i) => {
                    let gradient = `${group}_${groups[i+1]}`
                    let hasLine = group != groups[i+1];
                    return <div className={"step "+gradient+(hasLine ? " verticalLine" : "")} key={i} style={w_style} data-bs-toggle="tooltip" data-bs-placement="top" title=""></div>
                })}
                {/* {groups.map((group, i) => {
                    return <div className={"step "+group} style={w_style} data-bs-toggle="tooltip" data-bs-placement="top" title=""></div>
                })} */}
                <div className={"step "+groups[groups.length-1]} style={sides_style} data-bs-toggle="tooltip" data-bs-placement="top" title=""></div>
                </div>

                <div className="smile-wrapper">
                <BsEmojiSmile />
                </div>
                {/* Center bar */}

                <div className="smile-wrapper"></div>
                <div className="resultsbar-wrapper">
                {group_value != null ? <div className={"group_bar_step"} style={{left: `${group_position}%`, display: "inline-block"}}><div>{I18n.t("others")}</div></div>
                    : <div className={"empty_bar_step"} style={sides_style}></div>}
                </div><div className="smile-wrapper"></div>
                
            </div>
        )
    }

    componentDidMount =() =>{
        let elem = jQuery('.selected_bar_step')
        if (elem.length > 0){
            elem[0].dataset.tooltip = 'show';
        }
        window.addEventListener('resize', this.createLinesForPreviousAns);
        window.addEventListener('scroll', this.createLinesForPreviousAns);
        window.scrollTo(0,0)
    }

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.createLinesForPreviousAns);
        window.removeEventListener('scroll', this.createLinesForPreviousAns);
    }

    formatDate = (date) => {
        let curr_date = new Date(date)
        let curr_month = curr_date.getMonth() + 1
        if (curr_month < 10){
            curr_month = `0${curr_month}`
        }
        return `${curr_month}/${curr_date.getFullYear()%100}`
    }

    processOverlapPreviousAns = (previous_answers, previous_answers_pos, selected_position) => {
        let previous_ans_with_pos = previous_answers.map((ans, idx) => {return {...ans, position: previous_answers_pos[idx]}})
        let previous_ans_sorted_pos = previous_ans_with_pos.slice().sort((a,b) =>{
            if (a.position != b.position){
                return a.position - b.position
            }
            return new Date(a.created_at) - new Date(b.created_at)
        })
        let previous_ans_no_pos = []

        // Position to overflow, just go to the right for now
        for (let i = 0; i < previous_ans_sorted_pos.length; i++) {
            const currentAns = previous_ans_sorted_pos[i]
            let noPos = previous_ans_sorted_pos[i].position;
            if (i>0){
                const prevNoPos = previous_ans_no_pos[i - 1].no_pos;
                if (noPos - prevNoPos < this.props.percentage_gap){
                    noPos = prevNoPos + this.props.percentage_gap
                }
            }

            previous_ans_no_pos.push({
                ...currentAns,
                position: previous_ans_sorted_pos[i].position,
                no_pos: noPos
            });
        }

        // Push back to the left
        let final_adjusted_date_position = []
        for (let i = 0; i < previous_ans_no_pos.length; i++) {
            const correctIndexFromEndOfArr = previous_ans_no_pos.length - (i+1)
            const currentAns = previous_ans_no_pos[correctIndexFromEndOfArr]
            let noPos = currentAns.no_pos;
            if (i>0){
                const prevNoPos = final_adjusted_date_position[i-1].no_pos;
                if (Math.abs(prevNoPos - noPos) < this.props.percentage_gap || noPos > (100- GAP_TO_BAR_END)){
                    noPos = prevNoPos - this.props.percentage_gap
                }

                for (let j=0; j < previous_ans_no_pos.length; j++){
                    if ((Math.abs(previous_ans_no_pos[j].no_pos - noPos) < this.props.percentage_gap)){
                        previous_ans_no_pos[j].no_pos = noPos - this.props.percentage_gap
                    }
                }
            } else {
                if (noPos > (100- GAP_TO_BAR_END)){
                    noPos = (100 - GAP_TO_BAR_END)
                }

            }

            if (noPos - SELECTED_POSITION_GAP<selected_position && selected_position < noPos+SELECTED_POSITION_GAP){
                this.overlapSelectedPosition = true;
            }

            final_adjusted_date_position.push({
                ...previous_ans_no_pos[correctIndexFromEndOfArr],
                no_pos: noPos
            });
        }
        return final_adjusted_date_position.sort((a,b) => new Date(a.created_at) - new Date(b.created_at))
    }

    createLinesForPreviousAns = (resize = true) => {
        this.previous_ans_lines.forEach((line) => {
            line.remove();
        })
        let previousAns = this.no_overlap_previous_ans;
        let currentResultBarBound = document.querySelector(`#resultsbar-${this.props.bar_key}`).getBoundingClientRect()

        previousAns.map((ans, idx) => {
            // because the position is relative so need to add the header height in also
            let y2 = currentResultBarBound.y + window.scrollY;
            if (!resize){
                y2 += + jQuery('.site-header').height();
            }
            let y1 =  y2 - 16; // Gap between result bar and previous ans container
            let x2 = currentResultBarBound.left + currentResultBarBound.width * ans.position/100
            let x1 = currentResultBarBound.left + currentResultBarBound.width * ans.no_pos/100
            this.drawLineBetweenTwoPointsPreviousAns(x1,y1,x2,y2, idx)
        })
    }

    drawLineBetweenTwoPointsPreviousAns = (x1, y1, x2, y2, key) => {
        const line = document.createElement('div')
        line.classList.add('line-style')
        // To have certain skewness in the drawing when either to the right or left of the center
        if (x1 > x2){
            x1 += 20
        } else if (x1 < x2){
            x1 -=0
        }
        const xMid = (x1+x2)/2
        const yMid = (y1+y2)/2
        const slope = ( Math.atan2(y1-y2, x1-x2)*180 ) / Math.PI
        const distance = Math.sqrt(((x1-x2)*(x1-x2) +(y1-y2)*(y1-y2)))
        line.style.width = `${distance}px`;
        line.style.top = `${yMid}px`;
        line.style.left = `${xMid - distance/2}px`;
        line.style.transform = `rotate(${slope}deg)`
        line.style.key = key
        const current_color = MOST_RECENT_ANSWER_COLOR - key*20
        line.style.backgroundColor = `rgb(${current_color},${current_color},${current_color})`
        this.previous_ans_lines.push(line)
        document.querySelector(`#result-bar-parent-${this.props.bar_key}`).appendChild(line);
    }

}
