import I18n from 'i18n-js';
import { Component } from 'react';
import html2canvas from "html2canvas";
import domtoimage from "dom-to-image-more";
import { Redirect } from 'react-router';

import '../stylesheets/Results.scss';
import jQuery from 'jquery';
import ResultsBar from '../components/ResultsBar';
import GroupModal from '../components/GroupModal';
import jsPDF from 'jspdf';
import { BsBoxArrowInDownRight, BsDownload, BsPrinter, BsShare } from 'react-icons/bs';
import ShareButton from '../components/ShareButton';
import RetakeButton from '../components/RetakeButton';
import ReCAPTCHA from 'react-google-recaptcha';

import { questions } from "../config/questions";
import { feedback_file } from "../config/feedback";
import { feedback_questions } from '../config/service_feedback';

import mela_logo_fi from '../images/mela_rgb.png';
import mela_logo_sv from '../images/lpa_rgb.png';

import vv_logo_fi from '../images/vv_logo_fi.png';
import vv_logo_sv from '../images/vv_logo_sv.png';

import action_checked from "../images/icons/action_checked.png"
import action_unchecked from "../images/icons/action_unchecked.png"

import 'bootstrap/dist/js/bootstrap';
import AnswerAccordion from '../components/AnswerAccordion';
import MaintenanceAlert, { handleMaintenanceException } from '../components/MaintenanceAlert';

import { BsBookmark } from 'react-icons/bs';
import QuestionForm from '../components/QuestionForm';
export const NOTICE_PERIOD = 6; // months
const SHOW_PREVIOUS_ANSWERS = 6;
class Results extends Component {

  //   flattenJSON = (obj = {}, res = {}, extraKey = '') => {
  //     for(let key in obj){
  //        if(typeof obj[key] !== 'object'){
  //           res[extraKey + key] = obj[key];
  //        }else{
  //           this.flattenJSON(obj[key], res, `${extraKey}${key}.`);
  //        };
  //     };
  //     return res;
  //  }

  //  updateJSONfromFlatDict = (obj = {}, flat_dict = {}, extraKey = '', which_lang = "fi") => {
  //   for(let key in obj){
  //      if(typeof obj[key] !== 'object'){
  //        let flat_key = extraKey + key
  //         if(flat_dict[flat_key] !== undefined){

  //          if(obj[key] !== flat_dict[flat_key][which_lang]){
  //           console.log("DIFF: "+flat_key)
  //           console.log(obj[key])
  //           console.log("!=")
  //           console.log(flat_dict[flat_key][which_lang])
  //         }
  //         obj[key] = flat_dict[flat_key][which_lang];
  //       }
  //      }else{
  //         this.updateJSONfromFlatDict(obj[key], flat_dict, `${extraKey}${key}.`, which_lang);
  //      };
  //   };
  //   return obj;
  // }

  //   updateQuestionsJSONfromFlatDict = (obj = {}, flat_dict = {}, extraKey = '') => {
  //   for(let key in obj){
  //      if(typeof obj[key] !== 'object'){

  //      }else if(obj[key]["fi"] !== undefined){
  //        let flat_key = extraKey + key
  //        if(flat_dict[flat_key] !== undefined){
  //          if(obj[key]["fi"] !== flat_dict[flat_key]["fi"] || 
  //          obj[key]["sv"] !== flat_dict[flat_key]["sv"]){
  //            console.log("DIFF: "+flat_key)
  //          }
  //         obj[key]["fi"] = flat_dict[flat_key]["fi"]
  //         obj[key]["sv"] = flat_dict[flat_key]["sv"]
  //        }
  //     }else{
  //         this.flattenQuestionsJSON(obj[key], flat_dict, `${extraKey}${key}.`);
  //      };
  //   };
  //   return obj;
  // }

  //  flattenQuestionsJSON = (obj = {}, res = {}, extraKey = '') => {
  //   for(let key in obj){
  //      if(typeof obj[key] !== 'object'){

  //      }else if(obj[key]["fi"] !== undefined){
  //       res[extraKey + key] = obj[key];
  //     }else{
  //         this.flattenQuestionsJSON(obj[key], res, `${extraKey}${key}.`);
  //      };
  //   };
  //   return res;
  // }

  //   readCSV = (content, sep = '\t', headers=true, key="key") => {
  //     let lines = content.split('\n');
  //     let header = []
  //     if(headers){
  //       header = lines.shift().split(sep)
  //     }

  //     let result = {}
  //     lines.forEach(l => {
  //       let line = l.split(sep)
  //       let entry = {}
  //       line.forEach((col, i) =>{
  //         entry[header[i]] = col
  //       })
  //       result[entry[key]] = entry
  //     });
  //     return result
  //   }

  //   readLangCSV = (filepath = "/mela_tyky_fi_sv_SK.txt") => {
  //     jQuery.get(filepath,{}, (content) => {
  //       let dict = this.readCSV(content);

  //       const fi_loc = this.updateJSONfromFlatDict(fi_locale, dict, "locale.", "fi")
  //       const sv_loc =  this.updateJSONfromFlatDict(sv_locale, dict, "locale.", "sv")

  //       const fi_feedback = this.updateJSONfromFlatDict(feedback_file["fi"], dict, "feedback.", "fi")
  //       const sv_feedback =  this.updateJSONfromFlatDict(feedback_file["sv"], dict, "feedback.", "sv")

  //       const flat_questions = this.updateQuestionsJSONfromFlatDict(questions, dict, "questions.")

  //       console.log(flat_questions)


  //     });
  //   }

  //   download(filename, text) {
  //   var element = document.createElement('a');
  //   element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
  //   element.setAttribute('download', filename);

  //   element.style.display = 'none';
  //   document.body.appendChild(element);

  //   element.click();

  //   document.body.removeChild(element);
  // }

  //  generateLanguageCSV = () =>{
  //    const fi_feedback = this.flattenJSON(feedback_file["fi"], {}, "feedback.")
  //    const sv_feedback =  this.flattenJSON(feedback_file["sv"], {}, "feedback.")

  //    const sep_char = "\t"
  //    const str_char = ''
  //    let csv = ["key"+sep_char+"fi"+sep_char+"sv"];
  //    Object.keys(fi_feedback).forEach(k =>{
  //      let line = str_char+k+str_char + sep_char +
  //         str_char+fi_feedback[k].replace(sep_char, "    ").replace("\n", " ")+str_char + sep_char + 
  //         str_char+(sv_feedback[k] || "").replace(sep_char, "    ").replace("\n", " ")+str_char

  //       csv.push(line)
  //    })

  //    const fi_loc = this.flattenJSON(fi_locale, {}, "locale.")
  //    const sv_loc =  this.flattenJSON(sv_locale, {}, "locale.")
  //    Object.keys(fi_loc).forEach(k =>{
  //      let line = str_char+k+str_char + sep_char +
  //         str_char+fi_loc[k].replace(sep_char, "    ").replace("\n", " ")+str_char + sep_char + 
  //         str_char+(sv_loc[k]|| "").replace(sep_char, "    ").replace("\n", " ")+str_char

  //       csv.push(line)
  //    })

  //    const flat_questions = this.flattenQuestionsJSON(questions, {}, "questions.")
  //    Object.keys(flat_questions).forEach(k =>{
  //      let line = str_char+k+str_char + sep_char +
  //         str_char+(flat_questions[k]["fi"] || "").replace(sep_char, "    ").replace("\n", " ")+str_char + sep_char + 
  //         str_char+(flat_questions[k]["sv"]|| "").replace(sep_char, "    ").replace("\n", " ")+str_char

  //       csv.push(line)
  //    })

  //    //console.log(flat_questions)
  //    this.download("mela_lang_file.csv", csv.join("\n"))
  //  }

  constructor(props) {
    super(props);
    let answers = this.props.answers ? this.props.answers : { tyky_11__age: 40, tyky_11__sex: "m", tyky_11__production_line: "Something" }
    const screenSize = document.documentElement.clientWidth
    let percentage_gap = 16
    if (screenSize >= 990){
        percentage_gap = 8
    } else if (screenSize < 990 && screenSize >= 768){
        percentage_gap = 12
    }
    this.state = {
      group_properties: {
        age: this.getAgeGroup(answers["tyky_11__age"]),
        sex: [answers["tyky_11__sex"]],
        production_line: [answers["tyky_11__production_line"]]
      },
      default_group_properties: {
        age: this.getAgeGroup(answers["tyky_11__age"]),
        sex: [answers["tyky_11__sex"]],
        production_line: [answers["tyky_11__production_line"]]
      },
      group_evaluation: {},
      current_group_count: null,
      selection_group_count: null,
      error: false,
      accessError: false,
      printing: false,
      recaptcha: null,
      shared_profile: {},
      shared_date: null,
      shared_evaluation: {},
      shared_answers: {},
      redirect: false,
      maintenanceEnabled: false,
      redirectRetake: false,
      retakeID: this.props.retake_id,
      previous_answers: this.props.previous_evaluations || [],
      feedbackAnswered: null,
      feedback_answers: {},
      loading: false,
      percentage_gap: percentage_gap
    }
    this.isResultShared = this.props.match && this.props.match.params.uuid;
  }


  render() {
    let { submitting, error, printing, group_properties, default_group_properties, shared_date, group_evaluation, group_error, accessError, recaptcha, redirect, redirectRetake, retakeID, previous_answers, feedbackAnswered, feedback_answers, percentage_gap } = this.state;
    let { current_group_count, selection_group_count } = this.state;
    let { accessToken, answers, evaluation, evaluation_date } = this.props;
    let authorized = (accessToken && !accessError);
    const feedback = feedback_file[I18n.locale];
    const limit_previous_answers = previous_answers.slice(-SHOW_PREVIOUS_ANSWERS)
    //answers = answers || {}
    if (this.isResultShared) {
      evaluation = this.state.shared_evaluation;
      answers = this.state.shared_answers;
      //answers = this.state.shared_profile;
    }
    let evaluationLoaded = evaluation ? Object.keys(evaluation).length > 0 : false;
    let groupEvaluationLoaded = (group_evaluation && Object.keys(group_evaluation).length > 0) || group_error;

    let results_date = this.isResultShared ? shared_date : evaluation_date;
    if (redirectRetake){
      return <Redirect to={`/results/${retakeID}/r`} />
    }
    if (!authorized && !this.isResultShared && !recaptcha || redirect) {
      return <Redirect to='/' />;
    } else if (!authorized && this.isResultShared) {
      return <div className="form-wrapper container container-small">
        {/* Results Header */}
        <h3 style={{ fontWeight: 800 }} className="mela-blue">{I18n.t("results.title")}</h3>
        <h5 style={{ fontWeight: 600 }} className="mela-blue">{I18n.t("results.subtitle")}</h5>
        <p className="pt-2">{I18n.t("results.paragraph")}</p>
        <p><strong>{I18n.t("results.paragraph_1")}</strong> {I18n.t("results.paragraph_11")}</p>
        <p><strong>{I18n.t("results.paragraph_2")}</strong> {I18n.t("results.paragraph_21")}</p>
        <p><strong>{I18n.t("results.paragraph_3")}</strong> {I18n.t("results.paragraph_31")}</p>
        <br />
        <hr />

        {this.state.maintenanceEnabled ? <MaintenanceAlert maintenanceEstimatedEnd={this.state.maintenanceEstimatedEnd}/> : <div className="text-center mt-4 mb-5 w-100" style={{ minHeight: "85px" }}>
          {!process.env.REACT_APP_GOOGLE_RECAPTCHA_DISABLE ? <ReCAPTCHA
            sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITEKEY || "google-recaptcha-site-key"}
            hl={I18n.locale}
            onChange={this.recaptchaChange}
          /> : this.recaptchaChange(true)}</div>}

      </div>;
    } else return (
      <div className="form-wrapper container container-small">
        {printing && <div className="printing-message">{I18n.t("saving")}...<div className="spinner-border spinner-border-sm ms-2" role="status">
          <span className="visually-hidden">{I18n.t("saving")}...</span>
        </div></div>}
        {/* Results Header */}
        <h3 style={{ fontWeight: 800 }} className="mela-blue">{I18n.t("results.title")}</h3>
        <h5 style={{ fontWeight: 600 }} className="mela-blue">{I18n.t("results.subtitle")}</h5>
        {
          results_date && this.monthDiff(new Date(results_date), new Date()) >= NOTICE_PERIOD &&
          <div className="remind-container row">
            <div className='col-8'>
              <p className='mela-blue p-2'>{I18n.t("results.remind_description")}</p>
            </div>
            <div className='col-4'>
              <button className="btn btn-primary col-12 mb-2" onClick={(e) => this.setState({redirectRetake: true})}>{I18n.t('results.remind_btn')}</button>
            </div>
        </div>
        }
        
        <p hidden={(results_date && this.dayDiff(new Date(results_date), new Date()) < 1)  ? true : false}>{`${I18n.t("results.shared_result_paragraph", { "date": results_date ? this.formatDate(new Date(results_date)) : I18n.t("loading") + "..." })}.`}</p>
        <p className="pt-2">{I18n.t("results.paragraph")}</p>
        <p><strong>{I18n.t("results.paragraph_1")}</strong> {I18n.t("results.paragraph_11")}</p>
        <p><strong>{I18n.t("results.paragraph_2")}</strong> {I18n.t("results.paragraph_21")}</p>
        <p><strong>{I18n.t("results.paragraph_3")}</strong> {I18n.t("results.paragraph_31")}</p>
        


        {/* {this.isResultShared && <div className="alert alert-warning" role="alert">
          {I18n.t("results.is_shared_info")}
        </div>} */}

        {this.state.maintenanceEnabled ? <MaintenanceAlert maintenanceEstimatedEnd={this.state.maintenanceEstimatedEnd}/> : <>
        <br />
          {evaluationLoaded && <div className='row'>
            <div className='col-md-7 col-lg-8 pb-3'>
              <p className='mb-1' ><strong className="black">{I18n.t("my_group") + ": "}</strong>
                <br />
                {I18n.t("age") + ": " + this.ageText()} &ensp;
                {I18n.t("sex") + ": " + this.sexText()} &ensp;
                {I18n.t("production_line") + ": " + this.productionLineText()} <br />
                <GroupModal group_properties={group_properties} default_group_properties={default_group_properties} current_group_count={current_group_count} selection_group_count={selection_group_count} group_error={group_error} getGroupAnswers={this.getGroupAnswers} setGroupProperties={this.setGroupProperties} />
              </p>
              {(answers['tyky_11__non_farmer'] === true || answers['tyky_11__non_farmer'] === 'true')  && <p className='mb-4' style={{fontStyle: 'italic'}}>{I18n.t('results.not_farmer_alert')}</p>}
            </div>
          </div>}
          {group_error && <div className="alert alert-warning" role="alert">
            {I18n.t("errors.small_group")}
          </div>}
          {/* <hr/> */}

          {!evaluationLoaded && !error && <div className="mt-5 mb-5 text-center">{I18n.t("loading")}...<div className="spinner-border spinner-border-sm ms-2" role="status">
            <span className="visually-hidden">{I18n.t("loading")}...</span>
          </div></div>}

          {/* Error alert */}
          {error && <div className="alert alert-danger" role="alert">
            {I18n.t("errors.retry")}
          </div>}


          {evaluationLoaded && Object.keys(questions).map((key, i) => {
            let question = questions[key];
            if(question["results_hidden"]){
              return null;
            }
            let selected = evaluation[key];
            let isSelected = selected !== null && selected != undefined;
            if (!isSelected) {
              //return null
            }
            let group_median = group_evaluation[key];

            let current_previous_answers = limit_previous_answers.map((ans) => {return {...ans , evaluation: ans.evaluation[key]}})
            // Set type of bar depending on question type and selection
            let question_bar_groups = [];
            if (question.eval_type == "scale_11") {
              question_bar_groups = ["bad", "bad", "bad", "bad", "bad", "bad", "med", "med", "good", "good", "good"];
              selected = selected != null ? selected + 1 : null;
              current_previous_answers = current_previous_answers.map((ans) => {return {...ans, evaluation: ans.evaluation != null ? ans.evaluation + 1 : null}})
              group_median = group_median != null ? group_median + 1 : null;
            }else if (question.eval_type == "scale_6") {
              question_bar_groups = ["bad", "bad", "med", "good", "good", "very_good"];
            }else if (question.eval_type == "scale_5") {
              question_bar_groups = ["bad", "bad", "med", "good", "good"];
            } else if (question.eval_type == "scale_4") {
              question_bar_groups = ["bad", "med", "good", "good"];
            } else if (question.eval_type == "scale_3") {
              question_bar_groups = ["bad", "med", "good"];
            } 

            // Get feedback text
            let user_eval = question_bar_groups[selected - 1]
            let group_eval = group_median ? question_bar_groups[Math.round(group_median - 1)] : null
            let fb = feedback[key] ? feedback[key][user_eval || "med"] : null

            if (question.type != "user_profile")
              return <div className={"accordion mb-4 result-" + (question['id'])} key={question['id']} id="accordionPanelsStayOpenExample">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="panelsStayOpen-headingOne" >
                    <button className="accordion-button" style={{ background: "none" }} type="button" data-bs-toggle="collapse" data-bs-target={"#panel-collapse-" + (question['id'])} aria-expanded="true" aria-controls={"panel-collapse-" + (question['id'])}>
                      <strong className="mela-blue mb-0">{question.results_title[I18n.locale]}</strong>
                    </button>
                  </h2>
                  <div id={"panel-collapse-" + (question['id'])} className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
                    <div className="accordion-body form-result-content row">
                      {<div className="col-12 collapsed-show">
                        {<ResultsBar value={2} selected={selected} group_value={group_median} groups={question_bar_groups} previous_answers={current_previous_answers} bar_key={i} percentage_gap={percentage_gap} />}
                      </div>}

                      {isSelected && <><div className="col-md-7 text-block collapsed-show">
                        <h6>{fb ? fb.short_feedback : ""}</h6>
                        {!fb.group_feedback && fb.short_feedback_subtitle ? <p>
                          {fb.short_feedback_subtitle}
                        </p>
                         : 
                         fb && group_eval && fb.group_feedback && fb.group_feedback[group_eval] ? <p>
                          {fb.group_feedback[group_eval]}
                        </p> :
                          <p className='mb-0'>{I18n.t("results.comparison_data_not_available")}</p>
                        }
                        <div className="d-md-none">
                          <AnswerAccordion answers={answers} question={question} questions={questions} i={i} />
                        </div>
                      </div>
                        <div className="mt-sm-4 mt-md-0 col-md-5 result-content-links mt-4">
                          <h6>{I18n.t("results.services_links_title")}</h6>
                          <p>{I18n.t("results.services_links_subtitle")}</p>
                          {fb ? fb.additional_information.links.map((link, i) => {
                            let { label, href } = link.key ? I18n.t(`links.${link.key}`) : link
                            return <a className="w-100 d-block" href={href || "#"} key={link.key} target="_blank" rel="noopener noreferrer">{label}</a>
                          }) : ""}
                          {/* <p className='mt-2'>{I18n.t("results.services_subtitle")}<a href={I18n.t("results.shared_result_link_href")} target="_blank" rel="noopener noreferrer" style={{lineBreak: "anywhere"}}>{I18n.t("results.shared_result_link_text")}</a></p> */}
                        </div>
                        <div className="d-none d-md-block">
                          <AnswerAccordion answers={answers} question={question} questions={questions} i={i} />
                        </div></>}
                      {!isSelected && <h6 className={'collapsed-show' + (group_median != null ? " mt-4" : "")}>{I18n.t("results.question_not_answered")}</h6>}
                      {!isSelected && <p className='collapsed-show'>{I18n.t("results.question_not_answered_no_feedback")}</p>}
                      <div className="col-md-12">
                        <h6 className='mt-4'>{fb ? fb.additional_information.title : ""}</h6>
                        <p className="mb-0">
                          {fb ? fb.additional_information.text : ""}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            {/* <div className="form-result mb-4" id={"form-result-"+(question['id'])}>
            <div className="form-result-title">
              <h5 className="mela-blue">{question.results_title[I18n.locale]}</h5>
            </div>
            <div className="form-result-content row">
              <div className="col-12">
                {<ResultsBar value={2} selected={1} groups={["bad", "bad", "med", "good", "good"]}/>}
              </div>
              <div className="col-md-8 text-block">
                <h6>Subtitle</h6>
                <p>
                  Lorem ipsum odor amet, consectetuer adipiscing elit. Ac purus in massa egestas mollis varius;
                  dignissim elementum. Mollis tincidunt mattis hendrerit dolor eros enim, nisi ligula ornare.
                  Hendrerit parturient habitant pharetra rutrum gravida porttitor eros feugiat. Mollis elit
                  sodales taciti duis praesent id. Consequat urna vitae morbi nunc congue.
                </p>
              </div>
              <div className="col-md-4 result-content-links mb-4">
                <h6 className="mb-0">Subtitle</h6>
                <a className="w-100 d-block" href="#">Some link</a>
                <a className="w-100 d-block" href="#">Some link</a>
                <a className="w-100 d-block" href="#">Some link</a>
              </div>
              <div className="text-block">
              <h6>Subtitle</h6>
              <p className="mb-0">
                Lorem ipsum odor amet, consectetuer adipiscing elit. Ac purus in massa egestas mollis varius;
                dignissim elementum. Mollis tincidunt mattis hendrerit dolor eros enim, nisi ligula ornare.
                Hendrerit parturient habitant pharetra rutrum gravida porttitor eros feugiat. Mollis elit
                sodales taciti duis praesent id. Consequat urna vitae morbi nunc congue.
              </p>
              </div>
            </div>

          </div> */}
          })}

          {/* <form action="/get_results_pdf" method="post">
          <input type="hidden" name="jwt" value={this.props.accessToken}/>
          <input type="hidden" name="user_evaluation" value={this.props.evaluation}/>
          <input type="hidden" name="group_evaluation" value={this.state.group_evaluation}/>
          <input className="btn btn-primary" type="submit" value="Backend PDF" download="proposed_file_name"/>
        </form> */}


          {/* {!this.isResultShared &&  */}
          <div >
          {previous_answers.length > 0 && !feedbackAnswered && !this.props.match && Object.keys(feedback_questions).map((key, i) => {
            let question = feedback_questions[key];
            let unanswered_border = question["unanswered"]
            return question['show_only_when'] && feedback_answers[question['show_only_when']['question']] != question['show_only_when']['answer'] ? null : 
            <div className={"form-question mb-2" + (unanswered_border ? " unanswered-question" : "")} id={"form-question-" + (question['id'])}>
              <QuestionForm answers={feedback_answers} question={question} i={i} handleCheckChange={this.handleCheckChange} />
              {
                feedback_answers[key] && <p className='pt-2 mela-blue ms-3'>{I18n.t("results.feedback_saved")}</p>
              }
            </div>
          })}
          <div className='padding-left-2rem'>
            <p>{I18n.t("results.exit_subtitle")}</p>
            <RetakeButton accessToken={accessToken} preSharedUUID={this.isResultShared ? this.props.match.params.uuid : null} results_date={results_date} />
            <div className='row'>
              <div className='col-12 col-md-6 col-lg-4 col-xxl-6'>
               <p>{I18n.t("results.save_pdf_description")}</p>
              </div>
              <div className='row'>
                <div className='col-12 col-md-6 col-lg-6 col-xxl-6'>
                  <button className={"btn btn-primary text-start col-12 mb-2" + (groupEvaluationLoaded ? "" : " disabled")} onClick={this.printPDF} id="save_pdf_btn">{I18n.t("results.save_pdf")} <BsDownload className="mt-1 me-1 float-right" /></button>
                </div>
                <div className='col-12 col-md-6 col-lg-6 col-xxl-6'>
                  <ShareButton popupDisabled={false} accessToken={accessToken} preSharedUUID={this.isResultShared ? this.props.match.params.uuid : null} />
                </div>
              </div>
            </div>
            <p>{I18n.t("results.clear_data_before_exit")}</p>
            <div className='row exit-btn-container'>
                  <a className="btn btn-dark col-12 cursor-pointer mb-2" onClick={this.exit}>{I18n.t("results.exit_service")}</a>
            </div>
          </div>

          </div>
          {/* } */}
        </>}

      </div>
    );
  }

  componentDidMount = () => {
    this.loadResults();
    window.addEventListener('resize', this.reloadPercentageGap);
  }

  reloadPercentageGap = () => {
    const screenSize = document.documentElement.clientWidth
    if (screenSize >= 980 ){
        this.setState({percentage_gap: 8})
    } else if (screenSize < 980 && screenSize >= 755){
        this.setState({percentage_gap: 12})
    } else {
        this.setState({percentage_gap: 16})
    }
  }

  monthDiff = (d1, d2) => {
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
  }

  dayDiff = (d1, d2) => {
    const diffTime = Math.abs(d2 - d1);
    return diffTime / (1000 * 60 * 60 * 24);
  }

  checkFeedbackStatus = () => {
    this.setState({loading: true}, () => {
      jQuery.post(process.env.REACT_APP_PROXY + "get_feedback_status", {
        jwt: this.props.accessToken
      }).then((res)=> {
        if (res.ok){
          this.setState({loading: false, feedbackAnswered: res.feedback_answered})
        } else {
          this.setState({loading: false})
        }
      }).catch(e =>{
        if (!handleMaintenanceException(e, this)) {
          this.setState({ loading: false, error: true });
        }
      })
    })
  }

  handleCheckChange = (event) => {
    let question_id = event.target.name
    let value = event.target.value
    let answers = { ...this.state.feedback_answers };

    // Set selected value
    answers[question_id] = value;
    this.setState({feedback_answers: answers, error: false, loading: true}, () =>{
        jQuery.post(process.env.REACT_APP_PROXY + "submit_feedback", {
          answers: this.state.feedback_answers,
          uuid:  this.props.match ?  this.props.match.params.uuid : null, 
          jwt: this.props.accessToken,
        }).then((res) => {
          if (res.ok){
            this.setState({ loading: false, error: false});
          } else {
            this.setState({ loading: false, error: true});       
          }
  
        }).catch(e => {
          console.log(e.statusText)
          if (e.status == 401) {
            this.setState({ accessError: true })
          } else if (!handleMaintenanceException(e, this)) {
            this.setState({ loading: false, error: true });
          }
        })
    });
  }

  loadResults = () => {
    if (this.isResultShared && this.props.accessToken) {
      this.getSharedResult();
    } else if (!this.isResultShared) {
      this.setGroupAnswers(true);
      this.checkFeedbackStatus();
    }
  }

  exit = () => {
    if (window.confirm(I18n.t("results.exit_confirmation"))) {
      this.props.clearData(() => {
        this.setState({ redirect: true }, () => {
          window.location.reload(false);
        })
      });
    }
  }

  printText(doc, pos, text, style = "p", float = "left", canBreakPage = true, skipTextHeight = true, href = null, skipTextWidth = false) {
    pos = { ...pos }
    doc.setTextColor(64, 64, 64);
    doc.setFontSize(12);
    doc.setFont(undefined, 'normal');
    if (style == "h1") {
      doc.setTextColor(6, 3, 141);
      doc.setFontSize(24);
      doc.setFont(undefined, 'bold');
    } else if (style == "h2") {
      doc.setTextColor(6, 3, 141);
      doc.setFontSize(18);
      doc.setFont(undefined, 'bold');
    } else if (style == "h3") {
      doc.setTextColor(6, 3, 141);
      doc.setFont(undefined, 'bold');
    } else if (style == "strong") {
      doc.setFont(undefined, 'bold');
    } else if (style == "italic") {
      doc.setFont(undefined, 'italic');
    }else if (style == "warning") {
      doc.setTextColor(255, 170, 0);
    } else if (style == "small_link") {
      doc.setFontSize(10);
      doc.setTextColor(51, 51, 153);
    } else if (href != null) {
      doc.setTextColor(51, 51, 153);
    }

    let lines = doc.splitTextToSize(text, doc.internal.pageSize.getWidth() - (pos.x + 10))
    let text_x = (float == "right" ? doc.internal.pageSize.getWidth() - pos.x - doc.getTextDimensions(lines).w : pos.x)
    let text_dims = doc.getTextDimensions(lines);
    let last_line_dims = doc.getTextDimensions(lines[lines.length - 1])
    let text_h = text_dims.h;
    let text_w = last_line_dims.w;

    if (canBreakPage && pos.y + text_h > doc.internal.pageSize.getHeight() - 10) {
      doc.insertPage();
      pos.y = 15;
    }

    if (href != null) {
      doc.textWithLink(text, text_x, pos.y, { url: href });
    } else {
      doc.text(lines, text_x, pos.y)
    }
    pos.y += skipTextHeight && !skipTextWidth ? text_h : 0
    pos.y += skipTextWidth && skipTextHeight ? text_h - last_line_dims.h : 0;
    pos.x += skipTextWidth ? text_w : 0;
    return pos;
  }

  printOneAnswerToPDF = (doc, pos, question) => {
    const initial_x = pos.x
    const check_size = 4
    let { answers } = this.props;
    if (this.isResultShared) {
      answers = this.state.shared_answers;
    }
    let dependantQuestionHidden = question['show_only_when'] && answers[question['show_only_when']['question']] != question['show_only_when']['answer'];
    if (dependantQuestionHidden)
      return pos;
    if (this.isResultShared) {
      answers = this.state.shared_answers;
    }


    // print answer title
    pos.x = 15
    if(!question['form_number'])
    pos.x += 5
    pos = this.printText(doc, pos, `${question['form_number'] ? `${question['form_number']}. ` : ''}${question.title[I18n.locale]}`, "h3");
    pos.y += 3;

    if(!question['form_number'])
    pos.x -= 5
    // print answer options (checkboxes)
    if (question.type == "options_check") {
      pos.x += 15
      question.options.forEach((opt, i) => {
        const checked = answers[question.id] == opt.id;
        doc.addImage(checked ? action_checked : action_unchecked, "JPEG", pos.x - 7, pos.y - 3.5, check_size, check_size, '', 'FAST')
        pos = this.printText(doc, pos, `${opt[I18n.locale]}`);
        pos.y += 3
      })
      pos.x -= 15
    }


    // print answer options (scale)
    if (question.type == "scale") {
      const old_x = pos.x
      pos.x += 15
      pos.y += 8
      const left_x = pos.x;
      const scale_spacing = (doc.internal.pageSize.getWidth() - (pos.x * 2)) / (question.options.length - 1);
      question.options.forEach((opt, i) => {
        const checked = answers[question.id] == opt.id;
        const label_w = doc.getTextDimensions(`${opt.id}`).w;
        pos.x = left_x + scale_spacing * i;
        doc.addImage(checked ? action_checked : action_unchecked, "JPEG", pos.x - 2, pos.y - 10, check_size, check_size, '', 'FAST')
        let label_pos = pos
        label_pos.x = pos.x - label_w / 2
        this.printText(doc, label_pos, `${opt.id}`);

      })
      pos.x = old_x
      pos.y += 6
    }


    // print answer options (table)
    if (question.type == "table") {
      const old_x = pos.x
      pos.x += 10
      const left_x = pos.x;

      const no_width = doc.getTextDimensions(I18n.t("no")).w
      const yes_width = doc.getTextDimensions(I18n.t("yes")).w
      pos.y += 3
      question.options.forEach((opt, i) => {
        const f_key = question.id + "__" + opt.id;
        const true_checked = answers[f_key] == "true";
        const false_checked = answers[f_key] == "false";
        if (i % 2 == 1) {
          doc.setFillColor(246, 245, 255);
          doc.rect(pos.x - 4, pos.y - 6, doc.internal.pageSize.getWidth() - (pos.x * 2) + 8, 9.5, 'F')
        }

        pos.x = doc.internal.pageSize.getWidth() - (pos.x) - no_width;
        this.printText(doc, pos, I18n.t("no"));
        pos.x -= 4
        doc.addImage(false_checked ? action_checked : action_unchecked, "JPEG", pos.x - (check_size / 2), pos.y - 3.5, check_size, check_size, '', 'FAST')
        pos.x -= check_size + yes_width + 2;
        this.printText(doc, pos, I18n.t("yes"));
        pos.x -= 4
        doc.addImage(true_checked ? action_checked : action_unchecked, "JPEG", pos.x - (check_size / 2), pos.y - 3, check_size, check_size, '', 'FAST')
        pos.x = left_x
        pos = this.printText(doc, pos, `${opt[I18n.locale]}`);
        pos.y += 5


      })
      pos.x = old_x
    }

    pos.y += 5


    pos.x = initial_x
    return pos;
  }

  printAnswerToPDF = (doc, pos, question) => {
    pos = this.printText(doc, pos, I18n.t("results.my_answer"), "strong");
    pos.y += 5;
    if (question['show_only_when']){
      pos = this.printOneAnswerToPDF(doc, pos, questions[question['show_only_when']['question']])
    }
    return this.printOneAnswerToPDF(doc, pos, question)
  }

  printQuestionToPDF = async (doc, pos, question, idx) => {
    const feedback = feedback_file[I18n.locale];
    let { group_evaluation, shared_date } = this.state;
    let { evaluation, evaluation_date } = this.props;
    if (this.isResultShared) { evaluation = this.state.shared_evaluation }
    let print_date = new Date(this.isResultShared ? shared_date : evaluation_date);

    let barId = '.result-' + (question['id']) + ' .results-bar';
    let setBarPrinting = () => {
      jQuery(barId).toggleClass("printing");
    }

    let printNextQuestion = (insertPage = true) => {
      // Print next question
      if (idx < Object.keys(questions).length - 3) {
        this.printQuestionToPDF(doc, pos, questions["tyky_" + (idx + 2)], idx + 1)
      }
      // In last question save document
      else {
        doc.save(`${I18n.t("results.pdf_filename", { "date": this.formatDate(print_date, "-") })}.pdf`)
        jQuery('html').toggleClass("printing");
        this.setState({ printing: false })
      }
    }

    let selected = evaluation[question.id];
    let isSelected = selected !== null && selected != undefined;
    if (selected || true) {
      //doc.insertPage();
      //let pos = {x: 10, y: 15}

      let group_median = group_evaluation ? group_evaluation[question.id] : null;
      let newPageLimit = 0.01;
      if (pos.y >= doc.internal.pageSize.getHeight() * newPageLimit) {
        doc.insertPage();
        pos.y = 15;
      }

      // Print current question progress bar
      let [barWidth, barHeight] = [720, 162]
      let printScale = 2
      let printStyle = {
        transform: 'scale(' + printScale + ')',
        transformOrigin: 'top left',
        width: barWidth + "px",
        height: barHeight + "px"
      };
      setBarPrinting();
      let barHtml = jQuery(barId)[0];
      try {
        let imgData = barHtml ? await domtoimage.toJpeg(barHtml, {
          width: barWidth * printScale,
          height: barHeight * printScale,
          style: printStyle
        }) : null;
        setBarPrinting();
        let group_median = group_evaluation ? group_evaluation[question.id] : null;
        pos = this.printText(doc, pos, question.results_title[I18n.locale], "h2");
        pos.y += 3;
        // Print current question progress bar
        if (imgData != null) {
          pos.y -= 1;
          //const imgData = canvas.toDataURL("image/png");
          const finalWidth = doc.internal.pageSize.getWidth() - 20;
          const finalHeight = finalWidth * barHeight / barWidth
          //const finalHeight = barHtml.getBoundingClientRect().height * finalWidth/barHtml.getBoundingClientRect().width;
          doc.addImage(imgData, "JPEG", pos.x, pos.y - 5, finalWidth, finalHeight, '', 'FAST');
          pos.y += Math.round(finalHeight) - (group_median ? 8 : 15);
        }

        // Get feedback text
        let question_bar_groups = [];
        if (question.type == "options_check") {
          if (question.options.length == 5) {
            question_bar_groups = ["bad", "bad", "med", "good", "good"];
          } else if (question.options.length == 4) {
            question_bar_groups = ["bad", "med", "good", "good"];
          } else if (question.options.length == 3) {
            question_bar_groups = ["bad", "med", "good"];
          }
        } else if (question.type == "scale") {
          question_bar_groups = ["bad", "med", "good"];
        } else if (question.type == "table") {
          question_bar_groups = ["bad", "med", "good"];
        }

        // Get feedback text
        let user_eval = selected ? question_bar_groups[selected - 1] : null
        let group_eval = group_median ? question_bar_groups[Math.round(group_median - 1)] : null
        let fb = feedback[question.id] ? feedback[question.id][user_eval || "med"] : null

        // Print top feedback (short or no answer feedback)
        if (isSelected) {
          pos = this.printText(doc, pos, fb ? fb.short_feedback : "", "strong"); pos.y += 3;
        } else {
          pos = this.printText(doc, pos, I18n.t("results.question_not_answered"), "strong"); pos.y += 3;
          pos = this.printText(doc, pos, I18n.t("results.question_not_answered_no_feedback")); pos.y += 3;
        }
        //Print group feedback
        if (selected && group_eval) {
          pos = this.printText(doc, pos, fb && group_eval ? fb.group_feedback[group_eval] : ""); pos.y += 5;
        } else if (selected && !group_eval) {
          pos = this.printText(doc, pos, I18n.t("results.comparison_data_not_available")); pos.y += 3;
        }

        // Print own answer
        if (isSelected) {
          pos = this.printAnswerToPDF(doc, pos, question)
        }

        // Print aditional information
        pos = this.printText(doc, pos, fb ? fb.additional_information.title : "", "strong"); pos.y += 3;
        pos = this.printText(doc, pos, fb ? fb.additional_information.text : ""); pos.y += 5;

        if (isSelected) {
          pos = this.printText(doc, pos, I18n.t("results.services_links_title"), "strong"); pos.y += 1;
          pos = this.printText(doc, pos, I18n.t("results.services_links_subtitle"), "p"); pos.y += 3;
          let link_col = 0;
          if (fb) {
            fb.additional_information.links.forEach(l => {
              let { label, href } = l.key ? I18n.t(`links.${l.key}`) : l
              if (link_col == 0) {
                pos = this.printText(doc, pos, label, "p", "left", true, true, href);
                //pos = this.printText(doc, pos, href, "small_link", "left", true, true, href);
                pos.y += 1
                //pos = this.printText(doc, pos, label, "p", "left", true, false, href);
                //link_col = 1;
              } else {
                pos = this.printText(doc, { x: doc.internal.pageSize.getWidth() / 2, y: pos.y }, label, "p", "left", false, true, href); pos.y += 2;
                pos.x = 10;
                link_col = 0;
              }
            })

            pos.y += 2
            pos = this.printText(doc, pos, I18n.t("results.services_subtitle"), "p", "left", true, false, null, true);
            pos.x += 1;
            pos = this.printText(doc, pos, I18n.t("results.services_link_text"), "p", "left", true, true, I18n.t("results.services_link_href"));
            pos.x = 10;
            //pos = this.printText(doc, pos, l.href, "small_link", "left", true, true, l.href);
            pos.y += 2
            //if(fb.additional_information.links.length % 2 == 1){pos.y += 7}
          }
        }
        pos.y += 5;

        printNextQuestion();
      } catch (e) {
        console.log(e)
        // error when printing PDF
        jQuery('html').toggleClass("printing");
        this.setState({ printing: false, error: true })
      }
    } else {
      // Question not answered, skip
      printNextQuestion(false);
    }
  }

  recaptchaChange = (value) => {
    if (value) {
      jQuery.post(process.env.REACT_APP_PROXY + "access", { terms: false, recaptcha: value, shared_results: true })
        .then((resp) => {
          if (resp.token){
            this.props.setToken(resp.token, () => {
              this.setState({ accessError: false }, this.loadResults)
            });
          }
          // If just check for valid_ip and setState, it will cause infinite loop
          if (resp.valid_ip == true && this.props.validIpMaintenance != true){
            this.props.setValidIpMaintenance(true)
          }
        }).catch((e) => {
          handleMaintenanceException(e,this)
        })
    }
  }

  printPDF = async () => {
    //doc.internal.scaleFactor = 1.55;
    let { group_properties, shared_date } = this.state;
    let { evaluation_date, answers } = this.props;
    const pageOffsetBeforePrint = window.scrollY
    if (this.isResultShared) {
      answers = this.state.shared_answers;
    }
    try {
      let print_request = await jQuery.post(process.env.REACT_APP_PROXY + "get_results_pdf", {
        jwt: this.props.accessToken,
        uuid: this.isResultShared ? this.props.match.params.uuid : null
      })
      if (print_request['ok'] !== true){
        throw "Cannot print document!"
      }

      const doc = new jsPDF();
      let width = doc.internal.pageSize.getWidth();

      // PRINT FIRST PDF PAGE
      let pos = { x: 10, y: 5 }

      // Print Mela Logo
      jQuery('html').toggleClass("printing");
      this.setState({ printing: true }, () => {
        // Print Mela Logo
        const finalHeight = 20;
        const melaWidth = I18n.locale == "sv" ? 411 : 475;
        const melaHeight = 283;
        let imgData = I18n.locale == "sv" ? mela_logo_sv : mela_logo_fi;
        doc.addImage(imgData, "PNG", pos.x, pos.y, melaWidth * finalHeight / melaHeight, finalHeight, '', 'FAST');

        const vvWidth = I18n.locale == "sv" ? 1208 : 1146;
        const vvHeight = I18n.locale == "sv" ? 502 : 524;
        const finalVVHeight = 16;
        // Print VV logo
        imgData = I18n.locale == "sv" ? vv_logo_sv : vv_logo_fi;
        doc.addImage(imgData, "PNG", width - pos.x - (vvWidth * finalVVHeight / vvHeight), pos.y, vvWidth * finalVVHeight / vvHeight, finalVVHeight, '', 'FAST');
        pos.y += finalHeight + 13;

        // Print Info When Result was accessed through link
        // if(this.isResultShared){
        //   pos = this.printText(doc, pos, I18n.t("results.is_shared_info"), "warning");
        //   pos.y += 10;
        // }'

        // Print Main text
        let titlePos = { ...pos };
        pos = this.printText(doc, pos, I18n.t("results.title"), "h1", "left"); pos.y += 1;
        pos = this.printText(doc, pos, I18n.t("results.subtitle"), "h2"); pos.y += 3;
        let print_date = new Date(this.isResultShared ? shared_date : evaluation_date);
        pos = this.printText(doc, pos, `${I18n.t("results.shared_result_paragraph", { "date": this.formatDate(print_date) })}:`, "p", "left", true, true, null, false); pos.y += 1;
        pos = this.printText(doc, pos, I18n.t("results.shared_result_link_text"), "p", "float", true, true, I18n.t("results.shared_result_link_href")); pos.y += 7;
        pos.x = 10;

        pos = this.printText(doc, pos, I18n.t("results.paragraph")); pos.y += 3;
        pos = this.printText(doc, pos, I18n.t("results.paragraph_1"), "strong"); pos.y += 2;
        pos = this.printText(doc, pos, I18n.t("results.paragraph_11")); pos.y += 3;
        pos = this.printText(doc, pos, I18n.t("results.paragraph_2"), "strong"); pos.y += 2;
        pos = this.printText(doc, pos, I18n.t("results.paragraph_21")); pos.y += 3;
        pos = this.printText(doc, pos, I18n.t("results.paragraph_3"), "strong"); pos.y += 2;
        pos = this.printText(doc, pos, I18n.t("results.paragraph_31")); pos.y += 5;

        // Print group text
        if (true || Object.keys(this.state.group_evaluation).length > 0) {
          pos = this.printText(doc, pos, I18n.t("my_group") + ": ", "strong"); pos.y += 2;
          pos = this.printText(doc, pos,
            I18n.t("age") + ": " + this.ageText() + "    " +
            I18n.t("sex") + ": " + this.sexText() + "    " +
            I18n.t("production_line") + ": " + this.productionLineText());
            if(answers['tyky_11__non_farmer'] === true || answers['tyky_11__non_farmer'] === 'true'){
              pos.y += 3;
              pos = this.printText(doc, pos, I18n.t('results.not_farmer_alert'), "italic");
            }
        } else {
          pos = this.printText(doc, pos, I18n.t("errors.small_group"), "strong");
        }

        // PRINT QUESTIONS TO PDF
        pos.y += 10;
        this.printQuestionToPDF(doc, pos, questions["tyky_1"], 0)
        setTimeout(() => window.scrollTo({
          top: pageOffsetBeforePrint,
          behavior: 'instant'
        }), 2000)
      }
      )
    } catch(e){
        handleMaintenanceException(e, this);
    }
  }

  formatDate = (date, sep = ".") => {
    if (!date) return ""
    let day = '' + date.getDate();
    let year = date.getFullYear();
    let month = '' + (date.getMonth() + 1);
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [day, month, year].join(sep);
  }

  getSharedResult = () => {
    this.setState({ loading: true }, () => {
      let req_params = { uuid: this.props.match.params.uuid, jwt: this.props.accessToken }
      jQuery.post(process.env.REACT_APP_PROXY + "get_shared_result", req_params).then(resp => {
        if (resp.ok) {
          this.setState({
            shared_evaluation: resp.evaluation, shared_answers: resp.answers, shared_profile: resp.profile, shared_date: resp.date, previous_answers: resp.previous_answers, group_properties: {
              age: this.getAgeGroup(resp.profile["tyky_11__age"]),
              sex: [resp.profile["tyky_11__sex"]],
              production_line: [resp.profile["tyky_11__production_line"]]
            },
            feedbackAnswered: resp.feedback_answered,
            retakeID: resp.retake_id
          }, () => { this.setGroupAnswers(true) })
        } else {
          console.log(JSON.stringify(resp))
          this.setState({ error: true })
        }
      }).catch(e => {
        if (e.status == 401) {
          this.setState({ accessError: true })
        } else if (!handleMaintenanceException(e, this)) {
          this.setState({ error: true });
        }
      })
    })
  }

  getGroupAnswers = (auto_expand_group = false, group_properties = null) => {
    let group_props = group_properties || this.state.group_properties
    let req_params = { age_tens: group_props.age, sex: group_props.sex, production_line: group_props.production_line, auto_expand_group: auto_expand_group }
    req_params.jwt = this.props.accessToken

    var promise = new Promise((resolve, reject) => {
      jQuery.post(process.env.REACT_APP_PROXY + "group_evaluation", req_params).then(resp => {
        if (resp.ok) {
          resolve({ error: false, ok: true, group_error: false, group_properties: resp.group_properties || {}, group_evaluation: resp.group_evaluation, group_count: resp?.group_count })
        } else {
          resolve({ error: false, ok: false, group_error: true, group_count: resp?.group_count })
        }
      }).catch(e => {
        if (e.status == 401) {
          this.setState({ accessError: true })
        }else{
          handleMaintenanceException(e, this)
        }
        reject({ error: true, errorStatus: e.status, ok: false })
      })
    });

    return promise;

  }

  setGroupAnswers = (auto_expand_group = false, group_properties = null, successCallback = () => { }, errorCallback = () => { }) => {
    this.setState({ group_error: false, group_evaluation: {} }, () => {
      this.getGroupAnswers(auto_expand_group, group_properties).then(result => {
        if (result.ok) {
          if (auto_expand_group)
            this.setState({ group_evaluation: result.group_evaluation, current_group_count: result.group_count, selection_group_count: result.group_count, group_properties: result.group_properties, default_group_properties: result.group_properties }, successCallback)
          else
            this.setState({ group_evaluation: result.group_evaluation, current_group_count: result.group_count, selection_group_count: result.group_count }, successCallback)
        } else {
          this.setState({ group_error: true, selection_group_count: result?.group_count }, errorCallback)
        }
      }).catch(e => {
        handleMaintenanceException(e, this)
        errorCallback()
      });
    })
  }

  resetGroupProperties = (successCallback, errorCallback) => {
    this.setState({ group_properties: this.state.default_group_properties }, () => {
      this.setGroupAnswers(false, this.state.default_group_properties, successCallback, errorCallback);
    })
  }

  setGroupProperties = (props, successCallback, errorCallback) => {
    this.setState({ group_properties: props }, () => {
      this.setGroupAnswers(false, props, successCallback, errorCallback);
    })
  }

  getAgeGroup = (age) => {
    if (age < 30) {
      return [1, 2];
    } else if (age < 40) {
      return [3];
    } else if (age < 50) {
      return [4];
    } else if (age < 60) {
      return [5];
    } else {
      return [6, 7, 8, 9, 10, 11];
    }
  }

  ageText = () => {
    let age = [...this.state.group_properties.age]
    let text = age.length > 0 ? "" : I18n.t("all")
    age.sort()
    age.filter(v => v >= 2 && v <= 7).forEach((v, i) => {
      if (v == 2) {
        text += I18n.t("less_than") + " 30"
      } else if (v == 7) {
        text += "70+"
      } else {
        text += v + "0-" + v + "9";
      }
      text += ", "
    })
    text = age.length > 0 ? text.slice(0, text.length - 2) : text;
    return text
  }

  sexText = () => {
    let sex = [...this.state.group_properties.sex]
    let text = sex.length > 0 ? "" : I18n.t("all")
    sex.forEach((v, i) => {
      text += v == "m" ? I18n.t("man") : I18n.t("woman");
      if (i < sex.length - 1) {
        text += ", "
      }
    })
    return text
  }

  productionLineText = () => {
    let lines = [...this.state.group_properties["production_line"]]
    if (lines.length == 0 || lines[0] === undefined)
      return I18n.t("all")
    return lines.map((v, i) => {
      return I18n.t("production_lines." + v)
    }).join(", ")
  }


}

export default Results;
