import React, { Component } from 'react'
import './AnswerAccordion.scss';
import I18n from 'i18n-js';
import Select from "react-select";
import Modal from '../components/Modal';
import QuestionForm from './QuestionForm';


export default class AnswerAccordion extends Component {

    constructor(props)
    {
        super(props);
        this.state = {
            open: false
        }
    };

    render(){
        let{open} = this.state;
        let {answers, question, questions} = this.props;
        let dependantQuestionHidden = question['show_only_when'] && answers[question['show_only_when']['question']] != question['show_only_when']['answer'];

        return <>
        <a onClick={this.toggleOpen} role="button" className="link">{open ? I18n.t("results.hide_answer") : I18n.t("results.show_answer")}</a>
            <div id={"panel-collapse-acc-"+(question['id'])} className={"answer-accordion accordion-collapse collapse "+ (open ? "show" : "")} aria-labelledby="panelsStayOpen-headingOne">
              <div className="accordion-body form-question">
                    {question['show_only_when'] && <QuestionForm answers={answers} question={questions[question['show_only_when']['question']]} disabled={true}/>}
                    {!dependantQuestionHidden && <QuestionForm answers={answers} question={question} disabled={true}/>}
              </div>
            </div>

        </>
    }

    toggleOpen = () =>{
        this.setState({open: !this.state.open})
    }
}