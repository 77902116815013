export const feedback_file = {
  fi: {
    tyky_1: {
      good: {
        short_feedback: "Hyvä tulos, jatka samaan malliin!",
        additional_information: {
          title: "Opi työtyytyväisyydestä",
          text: "Maatalousyrittäjillä on työtyytyväisyys usein vähän matalammalla tasolla kuin monilla muilla ammattiryhmillä. Tämä johtuu muun muassa työn sitovuudesta, työmäärästä, taloudellisesta epävarmuudesta, hallinnollisesta taakasta ja työn ulkopuolelta tulevista paineista. Haluaisitko parantaa edelleen työtyytyväisyyttäsi? Silloin kannattaa panostaa erityisesti työn voimavaratekijöihin eli miettiä, mistä saat eniten iloa työhön tai mikä työssä motivoi eniten? Tee mahdollisimman usein asioita, jotka vastaavat vahvuuksiasi ja ovat arvojesi mukaisia. Tukisiko työtäsi esimerkiksi työnohjaus? Myös palautteen saaminen on arvokasta. Hanki aktiivisesti palautetta työstäsi ja verkostoidu esimerkiksi muiden yrittäjien kanssa.",
          links: [
            {
              key: "työturvallisuuskorttikoulutus"
            },
            {
              key: "voimaverstas-valmennus",
            },
            {
              key: "vahva_mieli-valmennus",
            },
            {
              key: "hyvinvointipäivät",
            },
            {
              key: "webinaarit",
            },
          ],
        },
        group_feedback: {
          good: "Koet työtyytyväisyytesi yhtä hyväksi kuin vertailuryhmäsi. Korkea työtyytyväisyys on yhteydessä maatilan tuottavuuteen ja menestymiseen. Yksi tärkeä tekijä työtyytyväisyydessä on osaamisen ja ammattitaidon vahvistaminen. Miten voisit kehittää niitä edelleen?",
          med: "Koet työtyytyväisyytesi paremmaksi kuin vertailuryhmäsi. Korkea työtyytyväisyys on yhteydessä maatilan tuottavuuteen ja menestymiseen. Yksi tärkeä tekijä työtyytyväisyydessä on osaamisen ja ammattitaidon vahvistaminen. Mieti, voisitko kehittää niitä edelleen?",
          bad: "Koet työtyytyväisyytesi selvästi paremmaksi kuin vertailuryhmäsi. Korkea työtyytyväisyys on yhteydessä maatilan tuottavuuteen ja menestymiseen. Yksi tärkeä tekijä työtyytyväisyydessä on osaamisen ja ammattitaidon vahvistaminen. Mieti, voisitko kehittää niitä edelleen?",
        },
      },
      med: {
        short_feedback: "Tuloksesi huolestuttaa, kiinnitä tähän huomiota.",
        additional_information: {
          title: "Opi työtyytyväisyydestä",
          text: "Maatalousyrittäjillä on työtyytyväisyys usein vähän matalammalla tasolla kuin monilla muilla ammattiryhmillä. Tämä johtuu muun muassa työn sitovuudesta, työmäärästä, taloudellisesta epävarmuudesta, hallinnollisesta taakasta ja työn ulkopuolelta tulevista paineista. Haluaisitko parantaa edelleen työtyytyväisyyttäsi? Silloin kannattaa panostaa erityisesti työn voimavaratekijöihin eli miettiä, mistä saat eniten iloa työhön tai mikä työssä motivoi eniten? Tee mahdollisimman usein asioita, jotka vastaavat vahvuuksiasi ja ovat arvojesi mukaisia. Tukisiko työtäsi esimerkiksi työnohjaus? Myös palautteen saaminen on arvokasta. Hanki aktiivisesti palautetta työstäsi ja verkostoidu esimerkiksi muiden yrittäjien kanssa.",
          links: [
            {
              key: "työturvallisuuskorttikoulutus"
            },
            {
              key: "hyvinvointipäivät",
            },
            {
              key: "välitä_viljelijästä-projekti",
            },
            {
              key: "hyvinvoinnin_abc-koulutus",
            },
            {
              key: "vahva_mieli-valmennus",
            },
          ],
        },
        group_feedback: {
          good: "Koet työtyytyväisyytesi heikommaksi kuin vertailuryhmäsi. Työtyytyväisyys tukee jaksamistasi ja maatilan menestymistä. Tärkeitä tekijöitä työtyytyväisyydessä on osaamisen vahvistaminen ja mahdollisuus hyödyntää omia vahvuuksiaan riittävästi. Miten voisit lisätä osaamistasi ja käyttää työssä enemmän vahvuuksiasi?",
          med: "Koet työtyytyväisyytesi samantasoiseksi vertailuryhmäsi kanssa. Työtyytyväisyys tukee jaksamistasi ja maatilan menestymistä. Tärkeitä tekijöitä työtyytyväisyydessä on osaamisen vahvistaminen ja mahdollisuus hyödyntää omia vahvuuksiaan riittävästi. Miten voisit lisätä osaamistasi ja käyttää työssä enemmän vahvuuksiasi?",
          bad: "Koet työtyytyväisyytesi kuitenkin paremmaksi kuin vertailuryhmäsi. Työtyytyväisyys tukee jaksamistasi ja maatilan menestymistä. Tärkeitä tekijöitä työtyytyväisyydessä on osaamisen vahvistaminen ja mahdollisuus hyödyntää omia vahvuuksiaan riittävästi. Miten voisit lisätä osaamistasi ja käyttää työssä enemmän vahvuuksiasi?",
        },
      },
      bad: {
        short_feedback:
          "Tuloksesi on huolestuttava, haethan apua tilanteeseen.",
        group_feedback: {
          good: "Koet työtyytyväisyytesi selvästi heikommaksi kuin vertailuryhmäsi. Heikko työtyytyväisyys on riskitekijä maatilayritykselle. Pohdi, mikä syö työtyytyväisyyttäsi listaamalla työn kuormitustekijöitä. Mieti myös, mistä asioista saat eniten voimavaroja työhön ja lisää niitä työpäiviin. Tee mahdollisimman usein asioita, jotka vastaavat vahvuuksiasi. Auttaisiko työnohjaus?",
          med: "Koet työtyytyväisyytesi heikommaksi kuin vertailuryhmäsi. Heikko työtyytyväisyys on riskitekijä maatilayritykselle. Pohdi, mikä syö työtyytyväisyyttäsi listaamalla työn kuormitustekijöitä. Mieti myös, mistä asioista saat eniten voimavaroja työhön ja lisää niitä työpäiviin. Tee mahdollisimman usein asioita, jotka vastaavat vahvuuksiasi. Auttaisiko työnohjaus?",
          bad: "Koet työtyytyväisyytesi samantasoiseksi vertailuryhmäsi kanssa. Heikko työtyytyväisyys on riskitekijä maatilayritykselle. Pohdi, mikä syö työtyytyväisyyttäsi listaamalla työn kuormitustekijöitä. Mieti myös  mistä asioista saat eniten voimavaroja työhön ja lisää niitä työpäivään. Tee mahdollisimman usein asioita, jotka vastaavat vahvuuksiasi. Auttaisiko työnohjaus?",
        },
        additional_information: {
          title: "Opi työtyytyväisyydestä",
          text: "Maatalousyrittäjillä on työtyytyväisyys usein vähän matalammalla tasolla kuin monilla muilla ammattiryhmillä. Tämä johtuu muun muassa työn sitovuudesta, työmäärästä, taloudellisesta epävarmuudesta, hallinnollisesta taakasta ja työn ulkopuolelta tulevista paineista. Haluaisitko parantaa edelleen työtyytyväisyyttäsi? Silloin kannattaa panostaa erityisesti työn voimavaratekijöihin eli miettiä, mistä saat eniten iloa työhön tai mikä työssä motivoi eniten? Tee mahdollisimman usein asioita, jotka vastaavat vahvuuksiasi ja ovat arvojesi mukaisia. Tukisiko työtäsi esimerkiksi työnohjaus? Myös palautteen saaminen on arvokasta. Hanki aktiivisesti palautetta työstäsi ja verkostoidu esimerkiksi muiden yrittäjien kanssa.",
          links: [
            {
              key: "välitä_viljelijästä-projekti",
            },
            {
              key: "hyvinvoinnin_abc-koulutus",
            },
            {
              key: "etävertaisryhmä",
            },
            {
              key: "tyhy-neuvoja",
            },
          ],
        },
      },
    },
    tyky_2: {
      good: {
        short_feedback: "Hyvä tulos, jatka samaan malliin!",
        group_feedback: {
          good: "Palaudut yhtä hyvin kuin vertailuryhmäsi. Virkeänä ja tarmokkaana arki luistaa mukavasti ja tapaturmariski on pieni. Mikä on sinulle mieluisin tapa viettää vapaa-aikaa?",
          med: "Palaudut paremmin kuin vertailuryhmäsi. Virkeänä ja innostuneena arki luistaa mukavasti ja tapaturmariski on pieni. Mikä on sinulle mieluisin tapa viettää vapaa-aikaa?",
          bad: "Palaudut selvästi paremmin kuin vertailuryhmäsi. Virkeänä ja innostuneena arki luistaa mukavasti ja tapaturmariski on pieni. Mikä on sinulle mieluisin tapa viettää vapaa-aikaa?",
        },
        additional_information: {
          title: "Opi palautumisesta",
          text: "Tiesitkö, että maatalousyrittäjät kokevat yleensä palautuvansa työstä heikommin kuin muut ammattiryhmät? Huono palautuminen heikentää työsuoritusta ja altistaa tapaturmariskeille sekä työuupumukselle. Maatalousyrittäjän työ on kuormittavaa, joten arjessa kannattaa huolehtia riittävästä palautumisesta. Hyödyllisin tauko on pidetty tauko! Palautumista tapahtuu silloin, kun saat ajatukset irti työasioista ja mieli sekä keho rentoutuvat. Pitkät työpäivät, uudet tilanteet, stressi ja fyysinen rasitus aiheuttavat kehossamme tarpeen levolle. Kausittaiset kiirehuiput lisäävät palautumisen tarvetta. Varaa levolle säännöllisesti aikaa, jotta jaksat jatkossakin tehdä työtä. Palautumisen merkitys korostuu silloin, kun työmäärä on suuri ja kiirehuippu on kestänyt pitkään. Älä luista hyvinvoinnin peruspilareista, sillä jokainen meistä tarvitsee säännöllisesti lepoa, ravintoa ja liikuntaa. Terveelliset elämäntavat tukevat jaksamista.",
          links: [
            {
              key: "taukojumppavideo",
            },
            {
              key: "hyvinvointipäivät",
            },
            {
              key: "hyvinvointivinkit",
            },
            {
              key: "kuntoremonttikurssi",
            },
          ],
        },
      },
      med: {
        short_feedback: "Tuloksesi huolestuttaa, kiinnitä tähän huomiota.",
        group_feedback: {
          good: "Palaudut heikommin kuin vertailuryhmäsi. Mitä muutoksia voit tehdä, jotta saavutat paremman palautumisen tason? Väsyneenä tapaturmariski kasvaa ja tarmokkuus vähenee. Lisää lepohetkiä arkeen, lyhyetkin tauot auttavat jaksamaan!",
          med: "Palaudut samantasoisesti kuin vertailuryhmäsi. Mitä muutoksia voit tehdä, jotta saavutat paremman palautumisen tason? Väsyneenä tapaturmariski kasvaa ja tarmokkuus vähenee. Lisää lepohetkiä arkeen, lyhyetkin tauot auttavat jaksamaan!",
          bad: "Palaudut kuitenkin paremmin kuin vertailuryhmäsi. Mitä muutoksia voit tehdä, jotta saavutat vielä paremman palautumisen tason? Väsyneenä tapaturmariski kasvaa ja tarmokkuus vähenee. Lisää lepohetkiä arkeen, lyhyetkin tauot auttavat jaksamaan!",
        },
        additional_information: {
          title: "Opi palautumisesta",
          text: "Tiesitkö, että maatalousyrittäjät kokevat yleensä palautuvansa työstä heikommin kuin muut ammattiryhmät? Huono palautuminen heikentää työsuoritusta ja altistaa tapaturmariskeille sekä työuupumukselle. Maatalousyrittäjän työ on kuormittavaa, joten arjessa kannattaa huolehtia riittävästä palautumisesta. Hyödyllisin tauko on pidetty tauko! Palautumista tapahtuu silloin, kun saat ajatukset irti työasioista ja mieli sekä keho rentoutuvat. Pitkät työpäivät, uudet tilanteet, stressi ja fyysinen rasitus aiheuttavat kehossamme tarpeen levolle. Kausittaiset kiirehuiput lisäävät palautumisen tarvetta. Varaa levolle säännöllisesti aikaa, jotta jaksat jatkossakin tehdä työtä. Palautumisen merkitys korostuu silloin, kun työmäärä on suuri ja kiirehuippu on kestänyt pitkään. Älä luista hyvinvoinnin peruspilareista, sillä jokainen meistä tarvitsee säännöllisesti lepoa, ravintoa ja liikuntaa. Terveelliset elämäntavat tukevat jaksamista.",
          links: [
            {
              key: "välitä_viljelijästä-projekti",
            },
            {
              key: "hyvinvointivinkit",
            },
            {
              key: "tuetut_lomat",
            },
            {
              key: "kuntoremonttikurssi",
            },
          ],
        },
      },
      bad: {
        short_feedback:
          "Tuloksesi on huolestuttava, haethan apua tilanteeseen.",
        group_feedback: {
          good: "Palaudut selvästi heikommin kuin vertailuryhmäsi. Jo pienillä muutoksilla voit saavuttaa paremman palautumisen tason. Aloita jo tänään! Väsyneenä tapaturmariski kasvaa ja tarmokkuus vähenee. Laita työt tärkeysjärjestykseen ja hyödynnä vinkit ajanhallintaan. Jos rentoutuminen on hankalaa ja kärsit univaikeuksista, ota rohkeasti yhteys terveydenhuoltoon. ",
          med: "Palaudut heikommin kuin vertailuryhmäsi. Jo pienillä muutoksilla voit saavuttaa paremman palautumisen tason. Aloita jo tänään! Väsyneenä tapaturmariski kasvaa ja tarmokkuus vähenee. Laita työt tärkeysjärjestykseen ja hyödynnä vinkit ajanhallintaan. Jos rentoutuminen on hankalaa ja kärsit univaikeuksista, ota rohkeasti yhteys terveydenhuoltoon.",
          bad: "Palaudut samantasoisesti kuin vertailuryhmäsi. Jo pienillä muutoksilla voit saavuttaa paremman palautumisen tason. Aloita jo tänään! Väsyneenä tapaturmariski kasvaa ja tarmokkuus vähenee. Laita työt tärkeysjärjestykseen ja hyödynnä vinkit ajanhallintaan. Jos rentoutuminen on hankalaa ja kärsit univaikeuksista, ota rohkeasti yhteys terveydenhuoltoon.",
        },
        additional_information: {
          title: "Opi palautumisesta",
          text: "Tiesitkö, että maatalousyrittäjät kokevat yleensä palautuvansa työstä heikommin kuin muut ammattiryhmät? Huono palautuminen heikentää työsuoritusta ja altistaa tapaturmariskeille sekä työuupumukselle. Maatalousyrittäjän työ on kuormittavaa, joten arjessa kannattaa huolehtia riittävästä palautumisesta. Hyödyllisin tauko on pidetty tauko! Palautumista tapahtuu silloin, kun saat ajatukset irti työasioista ja mieli sekä keho rentoutuvat. Pitkät työpäivät, uudet tilanteet, stressi ja fyysinen rasitus aiheuttavat kehossamme tarpeen levolle. Kausittaiset kiirehuiput lisäävät palautumisen tarvetta. Varaa levolle säännöllisesti aikaa, jotta jaksat jatkossakin tehdä työtä. Palautumisen merkitys korostuu silloin, kun työmäärä on suuri ja kiirehuippu on kestänyt pitkään. Älä luista hyvinvoinnin peruspilareista, sillä jokainen meistä tarvitsee säännöllisesti lepoa, ravintoa ja liikuntaa. Terveelliset elämäntavat tukevat jaksamista.",
          links: [
            {
              key: "välitä_viljelijästä-projekti",
            },
            {
              key: "tyhy-neuvoja",
            },
            {
              key: "terveydenhuollon_palvelut",
            },
          ],
        },
      },
    },
    tyky_3: {
      good: {
        short_feedback: "Hyvä tulos, jatka samaan malliin!",
        group_feedback: {
          good: "Pystyt irtautumaan työstä yhtä hyvin kuin vertailuryhmäsi. Hyvä työn ja vapaa-ajan tasapaino auttaa jaksamaan! Minkä asian parissa haluaisit viettää enemmän aikaa?",
          med: "Pystyt irtautumaan työstä paremmin kuin vertailuryhmäsi. Hyvä työn ja vapaa-ajan tasapaino auttaa jaksamaan! Minkä asian parissa haluaisit viettää enemmän aikaa?",
          bad: "Pystyt irtautumaan työstä selvästi paremmin kuin vertailuryhmäsi. Hyvä työn ja vapaa-ajan tasapaino auttaa jaksamaan! Minkä asian parissa haluaisit viettää enemmän aikaa?",
        },
        additional_information: {
          title: "Opi työstä irtautumisesta",
          links: [
            {
              key: "tuetut_lomat",
            },
            {
              key: "hyvinvointipäivät",
            },
            {
              key: "hyvinvoinnin_abc-koulutus",
            },
            {
              key: "hyvinvointivinkit",
            },
          ],
          text: "Maatilalla on tyypillistä, että työtä ja vapaa-aikaa on vaikea erottaa toisistaan. Työ on sitovaa ja tapahtuu usein kodin pihapiirissä, joten vapaa-ajalla työstä irtautumiseen täytyy erikseen miettiä sopivia keinoja. Kuinka usein vietät aikaa tilan ulkopuolella? Vapaa-ajalla voit puuhastella jonkin itsellesi mieluisan asian parissa tai vain oleilla rauhassa ja hengähtää. Minkä asian parissa viettäisit mielelläsi enemmänkin aikaa? Kokeile harrastusta, jonka parissa pääset toteuttamaan itseäsi tai opettelemaan uutta. Virkistävä vapaa-aika tuo elämääsi positiivista energiaa! Annathan myös läheisillesi ja muille ihmissuhteille aikaa? Perhe, ystävät ja sosiaaliset verkostot ovat tärkeitä voimavaroja elämän eri vaiheissa. ",
        },
      },
      med: {
        short_feedback: "Tuloksesi huolestuttaa, kiinnitä tähän huomiota.",
        group_feedback: {
          good: "Pystyt irtautumaan työstä heikommin kuin vertailuryhmäsi. Jaksaminen paranee, kun työ ja vapaa-aika ovat tasapainossa. Mikä muutos ajankäytössä antaisi sinulle lisää aikaa harrastuksille ja muille mieluisille asioille? Olisiko joidenkin työtehtävien uudelleenjärjestelystä tai ulkoistamisesta apua? Jo pienikin muutos voi auttaa!",
          med: "Pystyt irtautumaan työstä samantasoisesti kuin vertailuryhmäsi. Jaksaminen paranee, kun työ ja vapaa-aika ovat tasapainossa. Mikä muutos ajankäytössä antaisi sinulle lisää aikaa harrastuksille ja muille mieluisille asioille? Olisiko joidenkin työtehtävien uudelleenjärjestelystä tai ulkoistamisesta apua? Jo pienikin muutos voi auttaa! ",
          bad: "Pystyt irtautumaan työstä kuitenkin vertailuryhmääsi paremmin. Jaksaminen paranee, kun työ ja vapaa-aika ovat tasapainossa. Mikä muutos ajankäytössä  antaisi sinulle lisää aikaa harrastuksille ja muille mieluisille asioille? Olisiko joidenkin työtehtävien uudelleenjärjestelystä tai ulkoistamisesta apua? Jo pienikin muutos voi auttaa!",
        },
        additional_information: {
          title: "Opi työstä irtautumisesta",
          links: [
            {
              key: "välitä_viljelijästä-projekti",
            },
            {
              key: "muutoskirja",
            },
            {
              key: "tuetut_lomat",
            },
            {
              key: "hyvinvoinnin_abc-koulutus",
            },
          ],
          text: "Maatilalla on tyypillistä, että työtä ja vapaa-aikaa on vaikea erottaa toisistaan. Työ on sitovaa ja tapahtuu usein kodin pihapiirissä, joten vapaa-ajalla työstä irtautumiseen täytyy erikseen miettiä sopivia keinoja. Kuinka usein vietät aikaa tilan ulkopuolella? Vapaa-ajalla voit puuhastella jonkin itsellesi mieluisan asian parissa tai vain oleilla rauhassa ja hengähtää. Minkä asian parissa viettäisit mielelläsi enemmänkin aikaa? Kokeile harrastusta, jonka parissa pääset toteuttamaan itseäsi tai opettelemaan uutta. Virkistävä vapaa-aika tuo elämääsi positiivista energiaa! Annathan myös läheisillesi ja muille ihmissuhteille aikaa? Perhe, ystävät ja sosiaaliset verkostot ovat tärkeitä voimavaroja elämän eri vaiheissa.",
        },
      },
      bad: {
        short_feedback:
          "Tuloksesi on huolestuttava, haethan apua tilanteeseen.",
        group_feedback: {
          good: "Pystyt irtautumaan työstä selvästi heikommin kuin vertailuryhmäsi. Työn ja vapaa-ajan tasapaino edistävät jaksamista. Harrastuksille ja itselle mieluisille asioille olisi hyvä jäädä enemmän aikaa. Laita töitä tärkeysjärjestykseen ja mieti, voiko jotain ulkoistaa. Jo pienikin muutos ajankäytössä voi auttaa saamaan lisää vapaa-aikaa!",
          med: "Pystyt irtautumaan työstä heikommin kuin vertailuryhmäsi. Työn ja vapaa-ajan tasapaino edistävät jaksamista. Harrastuksille ja itselle mieluisille asioille olisi hyvä jäädä enemmän aikaa. Laita töitä tärkeysjärjestykseen ja mieti, voiko jotain ulkoistaa. Jo pienikin muutos ajankäytössä voi auttaa saamaan lisää vapaa-aikaa!",
          bad: "Pystyt irtautumaan työstä samantasoisesti kuin vertailuryhmäsi. Työn ja vapaa-ajan tasapaino edistävät jaksamista. Harrastuksille ja itselle mieluisille asioille olisi hyvä jäädä enemmän aikaa. Laita töitä tärkeysjärjestykseen ja mieti, voiko jotain ulkoistaa. Jo pienikin muutos ajankäytössä voi auttaa saamaan lisää vapaa-aikaa!",
        },
        additional_information: {
          title: "Opi työstä irtautumisesta",
          links: [
            {
              key: "välitä_viljelijästä-projekti",
            },
            {
              key: "terveydenhuollon_palvelut",
            },
            {
              key: "tyhy-neuvoja",
            },
            {
              key: "etävertaisryhmä",
            },
          ],
          text: "Maatilalla on tyypillistä, että työtä ja vapaa-aikaa on vaikea erottaa toisistaan. Työ on sitovaa ja tapahtuu usein kodin pihapiirissä, joten vapaa-ajalla työstä irtautumiseen täytyy erikseen miettiä sopivia keinoja. Kuinka usein vietät aikaa tilan ulkopuolella? Vapaa-ajalla voit puuhastella jonkin itsellesi mieluisan asian parissa tai vain oleilla rauhassa ja hengähtää. Minkä asian parissa viettäisit mielelläsi enemmänkin aikaa? Kokeile harrastusta, jonka parissa pääset toteuttamaan itseäsi tai opettelemaan uutta. Virkistävä vapaa-aika tuo elämääsi positiivista energiaa! Annathan myös läheisillesi ja muille ihmissuhteille aikaa? Perhe, ystävät ja sosiaaliset verkostot ovat tärkeitä voimavaroja elämän eri vaiheissa.",
        },
      },
    },
    tyky_4: {
      good: {
        short_feedback: "Hyvä tulos, jatka samaan malliin!",
        group_feedback: {
          good: "Toiveikkuus on sinulla yhtä vahva kuin vertailuryhmälläsi. Toiveikkuus on yhteydessä työmotivaatioon ja uuden oppimiseen. Miten ylläpidät toiveikkuutta? Aseta itsellesi uusia tavoitteita ja haasta itseäsi! Tee arvojesi mukaisia valintoja.",
          med: "Toiveikkuus on sinulla vahvempi kuin vertailuryhmälläsi. Toiveikkuus on yhteydessä työmotivaatioon ja uuden oppimiseen. Miten ylläpidät toiveikkuutta? Aseta itsellesi uusia tavoitteita ja haasta itseäsi! Tee arvojesi mukaisia valintoja.",
          bad: "Toiveikkuus on sinulla selvästi vahvempi kuin vertailuryhmälläsi. Toiveikkuus on yhteydessä työmotivaatioon ja uuden oppimiseen. Miten ylläpidät toiveikkuutta? Aseta itsellesi uusia tavoitteita ja haasta itseäsi! Tee arvojesi mukaisia valintoja.",
        },
        additional_information: {
          title: "Opi toiveikkuudesta",
          links: [
            {
              key: "hyvinvointipäivät",
            },
            {
              key: "vahva_mieli-valmennus",
            },
            {
              key: "voimaverstas-valmennus",
            },
            {
              key: "oivamieli-harjoitukset",
            },
          ],
          text: "Maatalousyrittäjillä toiveikkuutta vähentää yleensä erityisesti näköalattomuus nykytilanteen ja tulevaisuuden suhteen, vaihteleva tukipolitiikka sekä yrityksen kannattavuus. Yrittämiseen liittyy myös paljon epävarmuutta. Tunnista ja kehitä itsellesi sopivia keinoja hallita epävarmuutta ja riskejä. Voit käyttää tähän myös ulkopuolista apua. Tunnista omia ajattelumalleja ja huomaa niiden voima toiveikkuuden kehittämisessä. Joskus toiveikkuutta vähentää se, että emme voi elää arvojemme mukaista elämää. Mieti, millaisia asioita aidosti arvostat ja millaista elämää haluat elää. Ovatko arvosi tasapainossa tavoitteidesi kanssa? Pohdi myös, paljonko käytät aikaa sellaisista asioista huolehtimiseen, joihin et voi oikeasti vaikuttaa (kuten sää tai tukipolitiikka). Pyri keskittymään niiden asioiden kehittämiseen, joihin voit itse aidosti vaikuttaa.",
        },
      },
      med: {
        short_feedback: "Tuloksesi huolestuttaa, kiinnitä tähän huomiota.",
        group_feedback: {
          good: "Toiveikkuus on sinulla matalammalla tasolla kuin vertailuryhmälläsi. Vaikka elämästäsi puuttuisi tällä hetkellä toiveikkuutta, on hyvä tietää, että sitä voi harjoitella. Selkiytä arvojasi ja tunnista omia ajattelumalleja - voisitko oppia vahvistamaan toiveikkuutta? Sen vahvistaminen kannattaa, koska toiveikkuus on yhteydessä työmotivaatioon ja uuden oppimiseen.",
          med: "Toiveikkuus on sinulla samalla tasolla vertailuryhmäsi kanssa. Kenties haluaisit vahvistaa toiveikkuutta? Selkiytä arvojasi ja tee arvojesi mukaisia valintoja. Tunnista myös omia ajattelumalleja. Toiveikkuuden vahvistaminen kannattaa, koska toiveikkuus on yhteydessä työmotivaatioon ja uuden oppimiseen.",
          bad: "Toiveikkuus on sinulla kuitenkin vahvempi kuin vertailuryhmälläsi. Kenties haluaisit vahvistaa toiveikkuutta? Selkiytä arvojasi ja tee arvojesi mukaisia valintoja. Tunnista myös omia ajattelumalleja. Toiveikkuuden vahvistaminen kannattaa, koska toiveikkuus on yhteydessä työmotivaatioon ja uuden oppimiseen.",
        },
        additional_information: {
          title: "Opi toiveikkuudesta",
          links: [
            {
              key: "välitä_viljelijästä-projekti",
            },
            {
              key: "hyvinvoinnin_abc-koulutus",
            },
            {
              key: "vahva_mieli-valmennus",
            },
            {
              key: "oivamieli-harjoitukset",
            },
          ],
          text: "Maatalousyrittäjillä toiveikkuutta vähentää yleensä erityisesti näköalattomuus nykytilanteen ja tulevaisuuden suhteen, vaihteleva tukipolitiikka sekä yrityksen kannattavuus. Yrittämiseen liittyy myös paljon epävarmuutta. Tunnista ja kehitä itsellesi sopivia keinoja hallita epävarmuutta ja riskejä. Voit käyttää tähän myös ulkopuolista apua. Tunnista omia ajattelumalleja ja huomaa niiden voima toiveikkuuden kehittämisessä. Joskus toiveikkuutta vähentää se, että emme voi elää arvojemme mukaista elämää. Mieti, millaisia asioita aidosti arvostat ja millaista elämää haluat elää. Ovatko arvosi tasapainossa tavoitteidesi kanssa? Pohdi myös, paljonko käytät aikaa sellaisista asioista huolehtimiseen, joihin et voi oikeasti vaikuttaa (kuten sää tai tukipolitiikka). Pyri keskittymään niiden asioiden kehittämiseen, joihin voit itse aidosti vaikuttaa.",
        },
      },
      bad: {
        short_feedback:
          "Tuloksesi on huolestuttava, haethan apua tilanteeseen. ",
        group_feedback: {
          good: "Toiveikkuus on sinulla selvästi matalampi kuin vertailuryhmälläsi. Vaikka elämästäsi puuttuisi tällä hetkellä toiveikkuus, on hyvä tietää, että sitä voi harjoitella. Selkiytä arvojasi ja tunnista omia ajattelumalleja - voisitko oppia vahvistamaan toiveikkuutta? Se kannattaa, koska toiveikkuus on yhteydessä työmotivaatioon ja uuden oppimiseen.",
          med: "Toiveikkuus on sinulla matalampi kuin vertailuryhmälläsi. Vaikka elämästäsi puuttuisi tällä hetkellä toiveikkuus, on hyvä tietää, että sitä voi harjoitella. Selkiytä arvojasi ja tunnista omia ajattelumalleja - voisitko oppia vahvistamaan toiveikkuutta? Se kannattaa, koska toiveikkuus on yhteydessä työmotivaatioon ja uuden oppimiseen.",
          bad: "Toiveikkuus on sinulla samalla tasolla vertailuryhmäsi kanssa. Vaikka elämästäsi puuttuisi tällä hetkellä toiveikkuutta, on hyvä tietää, että sitä voi harjoitella. Selkiytä arvojasi ja tunnista omia ajattelumalleja - voisitko oppia vahvistamaan toiveikkuutta? Se kannattaa, koska toiveikkuus on yhteydessä työmotivaatioon ja uuden oppimiseen.",
        },
        additional_information: {
          title: "Opi toiveikkuudesta",
          links: [
            {
              key: "välitä_viljelijästä-projekti",
            },
            {
              key: "hyvinvoinnin_abc-koulutus",
            },
            {
              key: "terveydenhuollon_palvelut",
            },
            {
              key: "mielenterveystalo",
            },
          ],
          text: "Maatalousyrittäjillä toiveikkuutta vähentää yleensä erityisesti näköalattomuus nykytilanteen ja tulevaisuuden suhteen, vaihteleva tukipolitiikka sekä yrityksen kannattavuus. Yrittämiseen liittyy myös paljon epävarmuutta. Tunnista ja kehitä itsellesi sopivia keinoja hallita epävarmuutta ja riskejä. Voit käyttää tähän myös ulkopuolista apua. Tunnista omia ajattelumalleja ja huomaa niiden voima toiveikkuuden kehittämisessä. Joskus toiveikkuutta vähentää se, että emme voi elää arvojemme mukaista elämää. Mieti, millaisia asioita aidosti arvostat ja millaista elämää haluat elää. Ovatko arvosi tasapainossa tavoitteidesi kanssa? Pohdi myös, paljonko käytät aikaa sellaisista asioista huolehtimiseen, joihin et voi oikeasti vaikuttaa (kuten sää tai tukipolitiikka). Pyri keskittymään niiden asioiden kehittämiseen, joihin voit itse aidosti vaikuttaa.",
        },
      },
    },
    tyky_5: {
      good: {
        short_feedback: "Hyvä tulos, jatka samaan malliin!",
        group_feedback: {
          good: "Stressitasosi asettuu yhtä hyvälle tasolle kuin vertailuryhmälläsi. Kuormitus ja palautuminen ovat sinulla hyvässä tasapainossa. Ylläpidä ja edistä myönteistä tilannetta huolehtimalla työn suunnittelusta, osaamisen päivittämisestä, tarvittavista tukipalveluista ja riittävästä palautumisesta.",
          med: "Stressitasosi asettuu paremmalle tasolle kuin vertailuryhmälläsi. Kuormitus ja palautuminen ovat sinulla hyvässä tasapainossa. Ylläpidä ja edistä myönteistä tilannetta huolehtimalla työn suunnittelusta, osaamisen päivittämisestä, tarvittavista tukipalveluista ja riittävästä palautumisesta.",
          bad: "Stressitasosi asettuu selvästi paremmalle tasolle kuin vertailuryhmälläsi. Kuormitus ja palautuminen ovat sinulla hyvässä tasapainossa. Ylläpidä ja edistä myönteistä tilannetta huolehtimalla työn suunnittelusta, osaamisen päivittämisestä, tarvittavista tukipalveluista ja riittävästä palautumisesta.",
        },
        additional_information: {
          title: "Opi stressistä",
          links: [
            {
              key: "hyvinvointipäivät",
            },
            {
              key: "kuntoremonttikurssi",
            },
            {
              key: "hyvinvointivinkit",
            },
            {
              key: "webinaarit",
            },
          ],
          text: "Työstressiä aiheuttavat muun muassa työmäärä, työjärjestelyt, työn sitovuus, kiire ja muutokset työssä. Maatalousyrittäjillä myös toimintaan liittyvä byrokratia, hallinnolliset tehtävät, tukivalvonta, taloudellinen epävarmuus, yksintyöskentely, säätila ja työn sesonkiluonteisuus ovat omiaan lisäämään työn henkistä kuormittavuutta. Työstressissä on kysymys työn vaatimusten ja hallinnan välisestä epätasapainosta. Pitkittyessään työstressillä on monia haitallisia vaikutuksia terveyteen, työhyvinvointiin ja työturvallisuuteen. Se kasvattaa myös alttiutta työuupumukselle. Muistin häiriöt, keskittymisvaikeudet ja tunteiden hallinnan vaikeudet voivat olla oireita liiallisesta stressistä. Stressiä voi hallita tunnistamalla, mitkä tekijät stressiä aiheuttavat ja purkamalla tilanteita, mihin voi itse vaikuttaa. Itsensä johtaminen, uskallus asettaa tavoitteita, taito asettaa tehtäviä tärkeysjärjestykseen sekä ajankäytöstä ja omasta jaksamisesta huolehtiminen ovat yrittäjyyden ydintä. Kaikki stressi ei ole haitallista, vaan positiivisena voimana stressi myös edistää toimeliaisuutta ja toimii tärkeänä motivaatiotekijänä. Sopivasti haasteellisessa työssä onnistuminen on palkitsevaa.",
        },
      },
      med: {
        short_feedback: "Tuloksesi huolestuttaa, kiinnitä tähän huomiota.",
        group_feedback: {
          good: "Stressitasosi on kohonnut ja asettuu korkeammalle tasolle kuin vertailuryhmälläsi. Työstressissä on kysymys työn vaatimusten ja hallinnan välisestä epätasapainosta. Mikä sinulle aiheuttaa eniten kuormitusta? Nyt on korkea aika pysähtyä miettimään, miten sitä voisi vähentää.",
          med: "Stressitasosi asettuu samalle tasolle vertailuryhmäsi kanssa. Työstressissä on kysymys työn vaatimusten ja hallinnan välisestä epätasapainosta. Mikä sinulle aiheuttaa eniten kuormitusta? Nyt on korkea aika pysähtyä miettimään, miten sitä voisi vähentää.",
          bad: "Stressitasosi asettuu kuitenkin matalammalle tasolle kuin vertailuryhmälläsi. Työstressissä on kysymys työn vaatimusten ja hallinnan välisestä epätasapainosta. Mikä sinulle aiheuttaa eniten kuormitusta? Nyt on korkea aika pysähtyä miettimään, miten sitä voisi vähentää.",
        },
        additional_information: {
          title: "Opi stressistä",
          links: [
            {
              key: "voimaverstas-valmennus",
            },
            {
              key: "hyvinvoinnin_abc-koulutus",
            },
            {
              key: "vahva_mieli-valmennus",
            },
            {
              key: "oivamieli-harjoitukset",
            },
          ],
          text: "Työstressiä aiheuttavat muun muassa työmäärä, työjärjestelyt, työn sitovuus, kiire ja muutokset työssä. Maatalousyrittäjillä myös toimintaan liittyvä byrokratia, hallinnolliset tehtävät, tukivalvonta, taloudellinen epävarmuus, yksintyöskentely, säätila ja työn sesonkiluonteisuus ovat omiaan lisäämään työn henkistä kuormittavuutta. Työstressissä on kysymys työn vaatimusten ja hallinnan välisestä epätasapainosta. Pitkittyessään työstressillä on monia haitallisia vaikutuksia terveyteen, työhyvinvointiin ja työturvallisuuteen. Se kasvattaa myös alttiutta työuupumukselle. Muistin häiriöt, keskittymisvaikeudet ja tunteiden hallinnan vaikeudet voivat olla oireita liiallisesta stressistä. Stressiä voi hallita tunnistamalla, mitkä tekijät stressiä aiheuttavat ja purkamalla tilanteita, mihin voi itse vaikuttaa. Itsensä johtaminen, uskallus asettaa tavoitteita, taito asettaa tehtäviä tärkeysjärjestykseen sekä ajankäytöstä ja omasta jaksamisesta huolehtiminen ovat yrittäjyyden ydintä. Kaikki stressi ei ole haitallista, vaan positiivisena voimana stressi myös edistää toimeliaisuutta ja toimii tärkeänä motivaatiotekijänä. Sopivasti haasteellisessa työssä onnistuminen on palkitsevaa.",
        },
      },
      bad: {
        short_feedback:
          "Tuloksesi on huolestuttava, haethan apua tilanteeseen.",
        group_feedback: {
          good: "Stressitasosi on merkittävästi kohonnut ja asettuu selvästi korkeammalle tasolle kuin vertailuryhmälläsi. Tunnistatko, mitkä asiat aiheuttavat sinulle stressiä? Tilanteeseen tulee puuttua viipymättä. Varaa aika terveydenhuollosta tai ota yhteyttä Välitä viljelijästä -projektiin.",
          med: "Stressitasosi asettuu korkeammalle tasolle kuin vertailuryhmälläsi. Tunnistatko, mitkä asiat aiheuttavat sinulle stressiä? Tilanteeseen tulee puuttua viipymättä. Varaa aika terveydenhuollosta tai ota yhteyttä Välitä viljelijästä -projektiin.",
          bad: "Stressitasosi asettuu samalle tasolle kuin vertailuryhmälläsi. Tunnistatko, mitkä asiat aiheuttavat sinulle stressiä? Tilanteeseen tulee puuttua viipymättä. Varaa aika terveydenhuollosta tai ota yhteyttä Välitä viljelijästä -projektiin.",
        },
        additional_information: {
          title: "Opi stressistä",
          links: [
            {
              key: "terveydenhuollon_palvelut",
            },
            {
              key: "välitä_viljelijästä-projekti",
            },
            {
              key: "maaseudun_tukihenkilöverkko",
            },
            {
              key: "tyhy-neuvoja",
            },
          ],
          text: "Työstressiä aiheuttavat muun muassa työmäärä, työjärjestelyt, työn sitovuus, kiire ja muutokset työssä. Maatalousyrittäjillä myös toimintaan liittyvä byrokratia, hallinnolliset tehtävät, tukivalvonta, taloudellinen epävarmuus, yksintyöskentely, säätila ja työn sesonkiluonteisuus ovat omiaan lisäämään työn henkistä kuormittavuutta. Työstressissä on kysymys työn vaatimusten ja hallinnan välisestä epätasapainosta. Pitkittyessään työstressillä on monia haitallisia vaikutuksia terveyteen, työhyvinvointiin ja työturvallisuuteen. Se kasvattaa myös alttiutta työuupumukselle. Muistin häiriöt, keskittymisvaikeudet ja tunteiden hallinnan vaikeudet voivat olla oireita liiallisesta stressistä. Stressiä voi hallita tunnistamalla, mitkä tekijät stressiä aiheuttavat ja purkamalla tilanteita, mihin voi itse vaikuttaa. Itsensä johtaminen, uskallus asettaa tavoitteita, taito asettaa tehtäviä tärkeysjärjestykseen sekä ajankäytöstä ja omasta jaksamisesta huolehtiminen ovat yrittäjyyden ydintä. Kaikki stressi ei ole haitallista, vaan positiivisena voimana stressi myös edistää toimeliaisuutta ja toimii tärkeänä motivaatiotekijänä. Sopivasti haasteellisessa työssä onnistuminen on palkitsevaa.",
        },
      },
    },
    tyky_6: {
      good: {
        short_feedback: "Hyvä tulos, jatka samaan malliin!",
        group_feedback: {
          good: "Henkinen kuormittuneisuutesi asettuu yhtä hyvälle tasolle kuin vertailuryhmälläsi. Sinulla ei ole henkiseen kuormittumiseen viittaavia oireita. Minkä asian olet erityisesti havainnut tukevan hyvää oloa?",
          med: "Henkinen kuormittuneisuutesi asettuu matalammalle tasolle kuin vertailuryhmälläsi. Sinulla ei ole henkiseen kuormittumiseen viittavia oireita. Minkä asian olet erityisesti havainnut tukevan hyvää oloa?",
          bad: "Henkinen kuormittuneisuutesi asettuu selvästi matalammalle tasolle kuin vertailuryhmälläsi. Sinulla ei ole henkiseen kuormittumiseen viittavia oireita. Minkä asian olet erityisesti havainnut tukevan hyvää oloa?",
        },
        additional_information: {
          title: "Opi henkisestä kuormituksesta",
          links: [
            {
              key: "hyvinvointipäivät",
            },
            {
              key: "kuntoremonttikurssi",
            },
            {
              key: "hyvinvointivinkit",
            },
            {
              key: "webinaarit",
            },
          ],
          text: "Maatalousyrittäjille henkistä kuormitusta aiheuttavat erityisesti taloudellinen epävarmuus, toimintaan liittyvä byrokratia, hallinnolliset tehtävät sekä tukivalvonta. Suuri työmäärä lisää kiirettä ja vähentää työn hallinnan tunnetta. Haasteet ihmissuhteissa voivat myös osaltaan vaikeuttaa tilannetta. Henkisen kuormittumisen aiheuttamat oireet voivat pitkittyessään johtaa uupumistasoiseen häiriöön. Huolehdi riittävästä palautumisesta, unesta ja levosta, terveellisestä ravinnosta, liikunnasta ja ihmissuhteista. Pyri vaikuttamaan asioihin, joihin voit vaikuttaa ja vältä murehtimista niistä, joihin et voi vaikuttaa.",
        },
      },
      med: {
        short_feedback: "Tuloksesi huolestuttaa, kiinnitä tähän huomiota.",
        group_feedback: {
          good: "Henkinen kuormittuneisuutesi asettuu korkeammalle tasolle kuin vertailuryhmälläsi. Sinulla on henkiseen kuormittumiseen viittaavia oireita. Tunnistatko, mikä henkistä kuormitusta aiheuttaa. Avun pyytäminen jo tässä vaiheessa on hyödyllistä ja mahdollistaa ratkaisuvaihtoehtojen löytämisen.",
          med: "Henkinen kuormittuneisuutesi asettuu samalle tasolle vertailuryhmäsi kanssa. Sinulla on henkiseen kuormittumiseen viittaavia oireita. Tunnistatko, mikä henkistä kuormitusta aiheuttaa? Avun pyytäminen jo tässä vaiheessa on hyödyllistä ja mahdollistaa ratkaisuvaihtoehtojen löytämisen.",
          bad: "Henkinen kuormittuneisuutesi asettuu kuitenkin matalammalle tasolle kuin vertailuryhmälläsi. Sinulla on henkiseen kuormittumiseen viittaavia oireita.  Tunnistatko, mikä henkistä kuormitusta aiheuttaa? Avun pyytäminen jo tässä vaiheessa on hyödyllistä ja mahdollistaa ratkaisuvaihtoehtojen löytämisen.",
        },
        additional_information: {
          title: "Opi henkisestä kuormituksesta",
          links: [
            {
              key: "välitä_viljelijästä-projekti",
            },
            {
              key: "tyhy-neuvoja",
            },
            {
              key: "hyvinvoinnin_abc-koulutus",
            },
            {
              key: "mielenterveystalo",
            },
          ],
          text: "Maatalousyrittäjille henkistä kuormitusta aiheuttavat erityisesti taloudellinen epävarmuus, toimintaan liittyvä byrokratia, hallinnolliset tehtävät sekä tukivalvonta. Suuri työmäärä lisää kiirettä ja vähentää työn hallinnan tunnetta. Haasteet ihmissuhteissa voivat myös osaltaan vaikeuttaa tilannetta. Henkisen kuormittumisen aiheuttamat oireet voivat pitkittyessään johtaa uupumistasoiseen häiriöön. Huolehdi riittävästä palautumisesta, unesta ja levosta, terveellisestä ravinnosta, liikunnasta ja ihmissuhteista. Pyri vaikuttamaan asioihin, joihin voit vaikuttaa ja vältä murehtimista niistä, joihin et voi vaikuttaa.",
        },
      },
      bad: {
        short_feedback:
          "Tuloksesi on huolestuttava, haethan apua tilanteeseen.",
        group_feedback: {
          good: "Henkinen kuormittuneisuutesi asettuu selvästi korkeammalle tasolle kuin vertailuryhmälläsi. Sinulla on merkittävästi henkiseen kuormittumiseen viittaavia oireita. Tilanteeseen tulee puuttua viipymättä ja pureutua kuormituksen juurisyihin. Mikä sinua kuormittaa? Kerro asiasta läheisellesi ja ota yhteyttä matalalla kynnyksellä Välitä viljelijästä -projektiin tai terveydenhuoltoon.",
          med: "Henkinen kuormittuneisuutesi asettuu korkeammalle tasolle kuin vertailuryhmälläsi. Sinulla on merkittävästi henkiseen kuormittumiseen viittaavia oireita. Tilanteeseen tulee puuttua viipymättä ja pureutua kuormituksen juurisyihin. Mikä sinua kuormittaa? Kerro asiasta läheisellesi ja ota yhteyttä matalalla kynnyksellä Välitä viljelijästä -projektiin tai terveydenhuoltoon.",
          bad: "Henkinen kuormittuneisuutesi asettuu samalle tasolle kuin vertailuryhmälläsi. Sinulla on merkittävästi henkiseen kuormittumiseen viittaavia oireita. Tilanteeseen tulee puuttua viipymättä ja pureutua kuormituksen juurisyihin. Mikä sinua kuormittaa? Kerro asiasta läheisellesi ja ota yhteyttä matalalla kynnyksellä Välitä viljelijästä -projektiin tai terveydenhuoltoon.",
        },
        additional_information: {
          title: "Opi henkisestä kuormituksesta",
          links: [
            {
              key: "terveydenhuollon_palvelut",
            },
            {
              key: "välitä_viljelijästä-projekti",
            },
            {
              key: "mielenterveystalo",
            },
            {
              key: "tyhy-neuvoja",
            },
          ],
          text: "Maatalousyrittäjille henkistä kuormitusta aiheuttavat erityisesti taloudellinen epävarmuus, toimintaan liittyvä byrokratia, hallinnolliset tehtävät sekä tukivalvonta. Suuri työmäärä lisää kiirettä ja vähentää työn hallinnan tunnetta. Haasteet ihmissuhteissa voivat myös osaltaan vaikeuttaa tilannetta. Henkisen kuormittumisen aiheuttamat oireet voivat pitkittyessään johtaa uupumistasoiseen häiriöön. Huolehdi riittävästä palautumisesta, unesta ja levosta, terveellisestä ravinnosta, liikunnasta ja ihmissuhteista. Pyri vaikuttamaan asioihin, joihin voit vaikuttaa ja vältä murehtimista niistä, joihin et voi vaikuttaa.",
        },
      },
    },
    tyky_7: {
      good: {
        short_feedback: "Hyvä tulos, jatka samaan malliin!",
        group_feedback: {
          good: "Työkykyarviosi on yhtä hyvällä tasolla vertailuryhmäsi kanssa. Kun huolehdit itsestäsi, luot myönteistä perustaa kestävälle työkyvylle ja hyvälle työhyvinvoinnille.",
          med: "Työkykyarviosi on parempi kuin vertailuryhmälläsi. Kun huolehdit itsestäsi, luot myönteistä perustaa kestävälle työkyvylle ja hyvälle työhyvinvoinnille.",
          bad: "Työkykyarviosi on merkittävästi parempi kuin vertailuryhmälläsi. Kun huolehdit itsestäsi, luot myönteistä perustaa kestävälle työkyvylle ja hyvälle työhyvinvoinnille.",
        },
        additional_information: {
          title: "Opi työkyvystä",
          links: [
            {
              key: "työturvallisuuskorttikoulutus"
            },
            {
              key: "tuetut_lomat",
            },
            {
              key: "kuntoremonttikurssi",
            },
            {
              key: "hyvinvointipäivät",
            },
            {
              key: "webinaarit",
            },
          ],
          text: "Maatalousyrittäjien koettu työkyvyn keskiarvo on matalampi kuin palkansaajilla keskimäärin. Fyysisesti kuormittava ja tapaturma-altis työ vaikuttaa etenkin koettuun terveydentilaan ja sitä kautta työkykyyn. Lisäksi taloudelliset seikat ja koettu työn mielekkyys ovat yhteydessä positiivisena koettuun työkykyyn sekä haluun jatkaa nykyisissä tehtävissä. Työkyky voi alentua hiljalleen lähes huomaamatta ja tilanteeseen totutaan. Työn vaatimusten ja työkyvyn aiheuttama epäsuhdan voi aiheuttaa esimerkiksi muuttunut terveydentila, muuttuneet olosuhteet, henkisen kuormituksen tai vaikkapa osaamisvaatimusten kasvu. Korjaavia toimenpiteitä tulisi käynnistää mahdollisimman varhain, jolloin tilannetta voidaan vielä monin keinoin parantaa esimerkiksi kuntoutuksella.",
        },
      },
      med: {
        short_feedback: "Tuloksesi huolestuttaa, kiinnitä tähän huomiota.",
        group_feedback: {
          good: "Työkykyarviosi on alhaisempi kuin vertailuryhmälläsi ja sinulla on ilmeistä epäsuhtaa työn vaatimusten ja omien toimintaedellytystesi välillä. Miten työkyvyn alenema näkyy työssäsi ja mihin se vaikuttaa? Pohdi mistä se voisi johtua ja mitkä asiat sitä alentavat. Työkyvyn tukitoimet tulisi aloittaa jo tässä vaiheessa. Ota rohkeasti yhteyttä Välitä viljelijästä -projektiin tai terveydenhuoltoon asian selvittelemiseksi.",
          med: "Työkykyarviosi on samantasoinen kuin vertailuryhmälläsi ja sinulla on ilmeistä epäsuhtaa työn vaatimusten ja omien toimintaedellytystesi välillä. Miten työkyvyn alenema näkyy työssäsi ja mihin se vaikuttaa? Pohdi mistä se voisi johtua ja mitkä asiat sitä alentavat. Työkyvyn tukitoimet tulisi aloittaa jo tässä vaiheessa. Ota rohkeasti yhteyttä Välitä viljelijästä -projektiin tai terveydenhuoltoon asian selvittelemiseksi.",
          bad: "Työkykyarviosi on kuitenkin parempi kuin vertailuryhmälläsi, mutta sinulla on ilmeistä epäsuhtaa työn vaatimusten ja omien toimintaedellytystesi välillä. Miten työkyvyn alenema näkyy työssäsi ja mihin se vaikuttaa? Pohdi mistä se voisi johtua ja mitkä asiat sitä alentavat. Työkyvyn tukitoimet tulisi aloittaa jo tässä vaiheessa. Ota rohkeasti yhteyttä Välitä viljelijästä -projektiin tai terveydenhuoltoon asian selvittelemiseksi.",
        },
        additional_information: {
          title: "Opi työkyvystä",
          links: [
            {
              key: "työturvallisuuskorttikoulutus"
            },
            {
              key: "terveydenhuollon_palvelut",
            },
            {
              key: "välitä_viljelijästä-projekti",
            },
            {
              key: "hyvinvointipäivät",
            },
            {
              key: "tyhy-neuvoja",
            },
          ],
          text: "Maatalousyrittäjien koettu työkyvyn keskiarvo on matalampi kuin palkansaajilla keskimäärin. Fyysisesti kuormittava ja tapaturma-altis työ vaikuttaa etenkin koettuun terveydentilaan ja sitä kautta työkykyyn. Lisäksi taloudelliset seikat ja koettu työn mielekkyys ovat yhteydessä positiivisena koettuun työkykyyn sekä haluun jatkaa nykyisissä tehtävissä. Työkyky voi alentua hiljalleen lähes huomaamatta ja tilanteeseen totutaan. Työn vaatimusten ja työkyvyn aiheuttama epäsuhdan voi aiheuttaa esimerkiksi muuttunut terveydentila, muuttuneet olosuhteet, henkisen kuormituksen tai vaikkapa osaamisvaatimusten kasvu. Korjaavia toimenpiteitä tulisi käynnistää mahdollisimman varhain, jolloin tilannetta voidaan vielä monin keinoin parantaa esimerkiksi kuntoutuksella.",
        },
      },
      bad: {
        short_feedback:
          "Tuloksesi on huolestuttava, haethan apua tilanteeseen.",
        group_feedback: {
          good: "Työkykyarviosi on selvästi alhaisempi kuin vertailuryhmälläsi. Työkykysi on merkittävästi alentunut ja työsi näyttää vaativan enemmän kuin mihin sinulla on kohtuudella edellytyksiä. Ilman korjaavia toimenpiteitä tilanne saattaa entisestään vaikeutua. Saatat tarvita tähän tukea ja sitä on saatavissa. Ota rohkeasti yhteyttä Välitä viljelijästä -projektiin tai terveydenhuoltoon. Tilanteeseen on puututtava viipymättä.",
          med: "Työkykyarviosi on alhaisempi kuin vertailuryhmälläsi. Työkykysi on merkittävästi alentunut ja työsi näyttää vaativan enemmän kuin mihin sinulla on kohtuudella edellytyksiä. Ilman korjaavia toimenpiteitä tilanne saattaa entisestään vaikeutua. Saatat tarvita tähän tukea ja sitä on saatavissa. Ota rohkeasti yhteyttä Välitä viljelijästä -projektiin tai terveydenhuoltoon. Tilanteeseen on puututtava viipymättä.",
          bad: "Työkykyarviosi on samantasoinen kuin vertailuryhmälläsi. Työkykysi on merkittävästi alentunut ja työsi näyttää vaativan enemmän kuin mihin sinulla on kohtuudella edellytyksiä. Ilman korjaavia toimenpiteitä tilanne saattaa entisestään vaikeutua. Saatat tarvita tähän tukea ja sitä on saatavissa. Ota rohkeasti yhteyttä Välitä viljelijästä -projektiin tai terveydenhuoltoon. Tilanteeseen on puututtava viipymättä.",
        },
        additional_information: {
          title: "Opi työkyvystä",
          links: [
            {
              key: "terveydenhuollon_palvelut",
            },
            {
              key: "välitä_viljelijästä-projekti",
            },
            {
              key: "tyhy-neuvoja",
            },
          ],
          text: "Maatalousyrittäjien koettu työkyvyn keskiarvo on matalampi kuin palkansaajilla keskimäärin. Fyysisesti kuormittava ja tapaturma-altis työ vaikuttaa etenkin koettuun terveydentilaan ja sitä kautta työkykyyn. Lisäksi taloudelliset seikat ja koettu työn mielekkyys ovat yhteydessä positiivisena koettuun työkykyyn sekä haluun jatkaa nykyisissä tehtävissä. Työkyky voi alentua hiljalleen lähes huomaamatta ja tilanteeseen totutaan. Työn vaatimusten ja työkyvyn aiheuttama epäsuhdan voi aiheuttaa esimerkiksi muuttunut terveydentila, muuttuneet olosuhteet, henkisen kuormituksen tai vaikkapa osaamisvaatimusten kasvu. Korjaavia toimenpiteitä tulisi käynnistää mahdollisimman varhain, jolloin tilannetta voidaan vielä monin keinoin parantaa esimerkiksi kuntoutuksella.",
        },
      },
    },
    tyky_8: {
      good: {
        short_feedback: "Hyvä tulos, jatka samaan malliin!",
        group_feedback: {
          good: "Työkykyarviosi työn ruumiillisten vaatimusten kannalta on samalla tasolla kuin vertailuryhmälläsi. Työkykysi vastaa hyvin työsi ruumiillisia vaatimuksia ja vaikuttaa siltä, että selviydyt hyvin töistäsi. Vuosien kertyessä muista kuitenkin aktiivisesti huolehtia lihaskunnon ylläpitämisen ohella kestävyyskunnosta, liikehallinnasta sekä painonhallinnasta.",
          med: "Työkykyarviosi työn ruumiillisten vaatimusten kannalta on parempi kuin vertailuryhmälläsi. Työkykysi vastaa hyvin työsi ruumiillisia vaatimuksia ja vaikuttaa siltä, että selviydyt hyvin töistäsi. Vuosien kertyessä muista kuitenkin aktiivisesti huolehtia lihaskunnon ylläpitämisen ohella kestävyyskunnosta, liikehallinnasta sekä painonhallinnasta.",
          bad: "Työkykyarviosi työn ruumiillisten vaatimusten kannalta on merkittävästi parempi kuin vertailuryhmälläsi. Työkykysi vastaa hyvin työsi ruumiillisia vaatimuksia ja vaikuttaa siltä, että selviydyt hyvin töistäsi. Vuosien kertyessä muista kuitenkin aktiivisesti huolehtia lihaskunnon ylläpitämisen ohella kestävyyskunnosta, liikehallinnasta sekä painonhallinnasta.",
        },
        additional_information: {
          title: "Opi fyysisestä työkyvystä",
          links: [
            {
              key: "työturvallisuuskorttikoulutus"
            },
            {
              key: "tuetut_lomat",
            },
            {
              key: "kuntoremonttikurssi",
            },
            {
              key: "hyvinvointipäivät",
            },
            {
              key: "hyvinvointivinkit",
            },
          ],
          text: "Maataloustyön ruumiillinen kuormittavuus on erityisen suurta karjatiloilla ja metsätöissä. Tuki- ja liikuntaelimistöä kuormittavat erityisesti hankalat työasennot, nostaminen ja kantaminen sekä niihin liittyvä vähäinen apuvälineiden käyttö. Töiden koneellistuminen on osaltaan vähentänyt fyysistä työtä vaativia työvaiheita, mutta samalla istumatyön osuus on kasvanut, joka pitkäkestoisena myös kuormittaa tuki- ja liikuntaelimistöä. Tilannetta voidaan arvioida työterveydessä tai terveyskeskuksessa ja esimerkiksi etsiä tilanteeseesi soveltuvia kuntoutusvaihtoehtoja. Työtä ja työoloja voidaan usein pienillä ja edullisilla toimenpiteillä kehittää vähemmän kuormittaviksi.  Työ- ja toimintakyvyn ylläpitäminen edellyttää jatkuvaa toimintaa Muistathan huolehtia riittävästä unesta ja levosta, terveyttä tukevasta ravinnosta, palauttavasta liikunnasta ja ihmissuhteista.",
        },
      },
      med: {
        short_feedback: "Tuloksesi huolestuttaa, kiinnitä tähän huomiota.",
        group_feedback: {
          good: "Työkykyarviosi työn ruumiillisten vaatimusten kannalta on alhaisempi kuin vertailuryhmälläsi. Vaikuttaa siltä, että fyysinen työkykysi on jossain määrin alentunut suhteessa työsi ruumiillisiin vaatimuksiin. Oletko joutunut keventämään joitain työtehtäviä tai huomaatko, että palautuminen raskaista töistä voi kestää pidempään? Asialle voi ja pitää tehdä jotakin! Huolehdi lihaskunnon ylläpitämisestä, kestävyyskunnosta ja painonhallinnasta. ",
          med: "Työkykyarviosi työn ruumiillisten vaatimusten kannalta on samantasoinen kuin vertailuryhmälläsi. Vaikuttaa siltä, että fyysinen työkykysi on jossain määrin alentunut suhteessa työsi ruumiillisiin vaatimuksiin. Oletko joutunut keventämään joitain työtehtäviä tai huomaatko, että palautuminen raskaista töistä voi kestää pidempään? Asialle voi ja pitää tehdä jotakin!  Huolehdi lihaskunnon ylläpitämisestä, kestävyyskunnosta ja painonhallinnasta.",
          bad: "Työkykyarviosi työn ruumiillisten vaatimusten kannalta on kuitenkin parempi kuin vertailuryhmälläsi. Vaikuttaa siltä, että fyysinen työkykysi on jossain määrin alentunut suhteessa työsi ruumiillisiin vaatimuksiin. Oletko joutunut keventämään joitain työtehtäviä tai huomaatko, että palautuminen raskaista töistä voi kestää pidempään? Asialle voi ja pitää tehdä jotakin! Huolehdi lihaskunnon ylläpitämisestä, kestävyyskunnosta ja painonhallinnasta.",
        },
        additional_information: {
          title: "Opi fyysisestä työkyvystä",
          links: [
            {
              key: "työturvallisuuskorttikoulutus"
            },
            {
              key: "terveydenhuollon_palvelut",
            },
            {
              key: "välitä_viljelijästä-projekti",
            },
            {
              key: "tyhy-neuvoja",
            },
          ],
          text: "Maataloustyön ruumiillinen kuormittavuus on erityisen suurta karjatiloilla ja metsätöissä. Tuki- ja liikuntaelimistöä kuormittavat erityisesti hankalat työasennot, nostaminen ja kantaminen sekä niihin liittyvä vähäinen apuvälineiden käyttö. Töiden koneellistuminen on osaltaan vähentänyt fyysistä työtä vaativia työvaiheita, mutta samalla istumatyön osuus on kasvanut, joka pitkäkestoisena myös kuormittaa tuki- ja liikuntaelimistöä. Tilannetta voidaan arvioida työterveydessä tai terveyskeskuksessa ja esimerkiksi etsiä tilanteeseesi soveltuvia kuntoutusvaihtoehtoja. Työtä ja työoloja voidaan usein pienillä ja edullisilla toimenpiteillä kehittää vähemmän kuormittaviksi.  Työ- ja toimintakyvyn ylläpitäminen edellyttää jatkuvaa toimintaa. Muistathan huolehtia riittävästä unesta ja levosta, terveyttä tukevasta ravinnosta, palauttavasta liikunnasta ja ihmissuhteista.",
        },
      },
      bad: {
        short_feedback:
          "Tuloksesi on huolestuttava, haethan apua tilanteeseen.",
        group_feedback: {
          good: "Työkykyarviosi työn ruumiillisten vaatimusten kannalta on selvästi alhaisempi kuin vertailuryhmälläsi. Vaikuttaa siltä, että fyysinen työkykysi ei vastaa työsi ruumiillisia vaatimuksia. Oletko joutunut jättämään joitain työtehtäviä kokonaan tekemättä tai huomaatko, että et enää palaudu työn rasituksista? Tilanteeseen on puututtava viipymättä! Älä jää odottamaan tilanteen hankaloitumista, vaan pyydä apua työn ja työolojen kehittämismahdollisuuksien kartoittamiseen.",
          med: "Työkykyarviosi työn ruumiillisten vaatimusten kannalta on alhaisempi kuin vertailuryhmälläsi. Vaikuttaa siltä, että fyysinen työkykysi ei vastaa työsi ruumiillisia vaatimuksia. Oletko joutunut jättämään joitain työtehtäviä kokonaan tekemättä tai huomaatko, että et enää palaudu työn rasituksista? Tilanteeseen on puututtava viipymättä! Älä jää odottamaan tilanteen hankaloitumista, vaan pyydä apua työn ja työolojen kehittämismahdollisuuksien kartoittamiseen.",
          bad: "Työkykyarviosi työn ruumiillisten vaatimusten kannalta on samantasoinen kuin vertailuryhmälläsi. Vaikuttaa siltä, että fyysinen työkykysi ei vastaa työsi ruumiillisia vaatimuksia. Oletko joutunut jättämään joitain työtehtäviä kokonaan tekemättä tai huomaatko, että et enää palaudu työn rasituksista? Tilanteeseen on puututtava viipymättä! Älä jää odottamaan tilanteen hankaloitumista, vaan pyydä apua työn ja työolojen kehittämismahdollisuuksien kartoittamiseen.",
        },
        additional_information: {
          title: "Opi fyysisestä työkyvystä",
          links: [
            {
              key: "terveydenhuollon_palvelut",
            },
            {
              key: "välitä_viljelijästä-projekti",
            },
            {
              key: "tyhy-neuvoja",
            },
          ],
          text: "Maataloustyön ruumiillinen kuormittavuus on erityisen suurta karjatiloilla ja metsätöissä. Tuki- ja liikuntaelimistöä kuormittavat erityisesti hankalat työasennot, nostaminen ja kantaminen sekä niihin liittyvä vähäinen apuvälineiden käyttö. Töiden koneellistuminen on osaltaan vähentänyt fyysistä työtä vaativia työvaiheita, mutta samalla istumatyön osuus on kasvanut, joka pitkäkestoisena myös kuormittaa tuki- ja liikuntaelimistöä. Tilannetta voidaan arvioida työterveydessä tai terveyskeskuksessa ja esimerkiksi etsiä tilanteeseesi soveltuvia kuntoutusvaihtoehtoja. Työtä ja työoloja voidaan usein pienillä ja edullisilla toimenpiteillä kehittää vähemmän kuormittaviksi.  Työ- ja toimintakyvyn ylläpitäminen edellyttää jatkuvaa toimintaa . Muistathan huolehtia riittävästä unesta ja levosta, terveyttä tukevasta ravinnosta, palauttavasta liikunnasta ja ihmissuhteista.",
        },
      },
    },
    tyky_9: {
      very_good: {
        short_feedback: "Hyvä, jatka samaan malliin!",
        short_feedback_subtitle:
          "Sinulle ei ole sattunut maataloustyössä työtapaturmaa tai läheltä piti -tilannetta. Vältä riskit myös jatkossa, väsymys ja kiire altistavat vahingoille. Varautuminen kannattaa – miten voisit panostaa tilanteiden ennakointiin edelleen?",
        additional_information: {
          title: "Opi työturvallisuudesta",
          links: [
            {
              key: "työturvallisuuskorttikoulutus"
            },
            {
              key: "työturvallisuusneuvoja",
            },
            {
              key: "farmarin_suojaintutkinto",
            },
            {
              key: "työturvallisuusvideot",
            },
            {
              key: "maatilojen_varautumisen_työkalut",
            },
          ],
          text: "Maatalousyrittäjille sattuu selvästi enemmän tapaturmia kuin monelle muulle ammattiryhmälle. Tapaturmista ja läheltä piti -tilanteista kannattaa oppia ja korjata niihin johtaneet asiat. Oman asenteen merkitys työturvallisuudessa on ratkaiseva. Tapaturma on monen asian summa. Siihen johtaneita tekijöitä on hyvä tarkastella aina laajemmin ja tarkemmin. Tunnetko toisten maatalousyrittäjien ja maatilojen toimivia käytäntöjä? Keskusteletko työturvallisuudesta muiden kanssa? Oletko hankkinut itsellesi työturvallisuuteen liittyvää koulutusta? Kirjaa aina läheltä piti -tilanteen tai tapaturman satuttua ylös tilanteeseen johtaneet asiat: mitä tapahtui, milloin, kenelle ja miksi. Mieti, millä tavoin voit jatkossakin ehkäistä vahinkoja. Vahingosta voi aina viisastua!",
        },
      },
      good: {
        short_feedback: "Hyvä, jatka samaan malliin!",
        group_feedback: {
          good: "Olet tehnyt muutoksia tai asenteesi on muuttunut tapaturman tai läheltä piti -tilanteen seurauksena yhtä usein vertailuryhmäsi kanssa. Varautuminen kannattaa - miten voisit panostaa tilanteiden ennakointiin vielä paremmin?",
          med: "Olet tehnyt vertailuryhmääsi useammin muutoksia tai asenteesi on muuttunut tapaturman tai läheltä piti -tilanteen seurauksena. Varautuminen kannattaa - miten voisit panostaa tilanteiden ennakointiin vielä paremmin?",
          bad: "Olet tehnyt selvästi vertailuryhmääsi useammin muutoksia tai asenteesi on muuttunut tapaturman tai läheltä piti -tilanteen seurauksena. Varautuminen kannattaa - miten voisit panostaa tilanteiden ennakointiin vielä paremmin?",
        },
        additional_information: {
          title: "Opi työturvallisuudesta",
          links: [
            {
              key: "työturvallisuuskorttikoulutus"
            },
            {
              key: "työturvallisuusneuvoja",
            },
            {
              key: "farmarin_suojaintutkinto",
            },
            {
              key: "työturvallisuusvideot",
            },
            {
              key: "maatilojen_varautumisen_työkalut",
            },
          ],
          text: "Maatalousyrittäjille sattuu selvästi enemmän tapaturmia kuin monelle muulle ammattiryhmälle. Tapaturmista ja läheltä piti -tilanteista kannattaa oppia ja korjata niihin johtaneet asiat. Oman asenteen merkitys työturvallisuudessa on ratkaiseva. Tapaturma on monen asian summa. Siihen johtaneita tekijöitä on hyvä tarkastella aina laajemmin ja tarkemmin. Tunnetko toisten maatalousyrittäjien ja maatilojen toimivia käytäntöjä? Keskusteletko työturvallisuudesta muiden kanssa? Oletko hankkinut itsellesi työturvallisuuteen liittyvää koulutusta? Kirjaa aina läheltä piti -tilanteen tai tapaturman satuttua ylös tilanteeseen johtaneet asiat: mitä tapahtui, milloin, kenelle ja miksi. Mieti, millä tavoin voit jatkossakin ehkäistä vahinkoja. Vahingosta voi aina viisastua!",
        },
      },
      med: {
        short_feedback: "Tuloksesi huolestuttaa, kiinnitä tähän huomiota.",
        group_feedback: {
          good: "Olet tehnyt muutoksia tai asenteesi on muuttunut tapaturman tai läheltä piti -tilanteen seurauksena vertailuryhmääsi harvemmin. Oman asenteen merkitys työturvallisuudessa on ratkaiseva. Älä odota, että jotain vakavaa tapahtuu! Mikä olisi ensimmäinen työturvallisuutta parantava toimi tilallasi?",
          med: "Olet tehnyt muutoksia tai asenteesi on muuttunut tapaturman tai läheltä piti -tilanteen seurauksena yhtä usein vertailuryhmäsi kanssa. Oman asenteen merkitys työturvallisuudessa on ratkaiseva. Älä odota, että jotain vakavaa tapahtuu! Mikä olisi ensimmäinen työturvallisuutta parantava toimi tilallasi?",
          bad: "Olet tehnyt muutoksia tai asenteesi on muuttunut tapaturman tai läheltä piti -tilanteen seurauksena kuitenkin vertailuryhmääsi useammin. Oman asenteen merkitys työturvallisuudessa on ratkaiseva. Älä odota, että jotain vakavaa tapahtuu! Mikä olisi ensimmäinen työturvallisuutta parantava toimi tilallasi?",
        },
        additional_information: {
          title: "Opi työturvallisuudesta",
          links: [
            {
              key: "työturvallisuuskorttikoulutus"
            },
            {
              key: "työturvallisuusneuvoja",
            },
            {
              key: "farmarin_suojaintutkinto",
            },
            {
              key: "työterveyshuolto",
            },
            {
              key: "maatilojen_varautumisen_työkalut",
            },
          ],
          text: "Maatalousyrittäjille sattuu selvästi enemmän tapaturmia kuin monelle muulle ammattiryhmälle. Tapaturmista ja läheltä piti -tilanteista kannattaa oppia ja korjata niihin johtaneet asiat. Oman asenteen merkitys työturvallisuudessa on ratkaiseva. Tapaturma on monen asian summa. Siihen johtaneita tekijöitä on hyvä tarkastella aina laajemmin ja tarkemmin. Tunnetko toisten maatalousyrittäjien ja maatilojen toimivia käytäntöjä? Keskusteletko työturvallisuudesta muiden kanssa? Oletko hankkinut itsellesi työturvallisuuteen liittyvää koulutusta? Kirjaa aina läheltä piti -tilanteen tai tapaturman satuttua ylös tilanteeseen johtaneet asiat: mitä tapahtui, milloin, kenelle ja miksi. Mieti, millä tavoin voit jatkossakin ehkäistä vahinkoja. Vahingosta voi aina viisastua!",
        },
      },
      bad: {
        short_feedback:
          "Tuloksesi on huolestuttava, haethan apua tilanteeseen.",
        group_feedback: {
          good: "Olet tehnyt muutoksia tai asenteesi on muuttunut tapaturman tai läheltä piti -tilanteen seurauksena selvästi vertailuryhmääsi harvemmin. Oman asenteen merkitys työturvallisuudessa on ratkaiseva. Älä odota, että jotain vakavaa tapahtuu! Mikä olisi ensimmäinen työturvallisuutta parantava toimi tilallasi? Kaipaatko työturvallisuudesta lisätietoa?",
          med: "Olet tehnyt muutoksia tai asenteesi on muuttunut tapaturman tai läheltä piti -tilanteen seurauksena vertailuryhmääsi harvemmin. Oman asenteen merkitys työturvallisuudessa on ratkaiseva. Älä odota, että jotain vakavaa tapahtuu! Mikä olisi ensimmäinen työturvallisuutta parantava toimi tilallasi? Kaipaatko työturvallisuudesta lisätietoa?",
          bad: "Olet tehnyt muutoksia tai asenteesi on muuttunut tapaturman tai läheltä piti -tilanteen seurauksena yhtä harvoin vertailuryhmäsi kanssa. Oman asenteen merkitys työturvallisuudessa on ratkaiseva. Älä odota, että jotain vakavaa tapahtuu! Mikä olisi ensimmäinen työturvallisuutta parantava toimi tilallasi? Kaipaatko työturvallisuudesta lisätietoa?",
        },
        additional_information: {
          title: "Opi työturvallisuudesta",
          links: [
            {
              key: "työturvallisuuskorttikoulutus"
            },
            {
              key: "maatilojen_varautumisen_työkalut",
            },
            {
              key: "työterveyshuolto",
            },
            {
              key: "työturvallisuusneuvoja",
            },
            {
              key: "farmarin_suojaintutkinto",
            },
          ],
          text: "Maatalousyrittäjille sattuu selvästi enemmän tapaturmia kuin monelle muulle ammattiryhmälle. Tapaturmista ja läheltä piti -tilanteista kannattaa oppia ja korjata niihin johtaneet asiat. Oman asenteen merkitys työturvallisuudessa on ratkaiseva. Tapaturma on monen asian summa. Siihen johtaneita tekijöitä on hyvä tarkastella aina laajemmin ja tarkemmin. Tunnetko toisten maatalousyrittäjien ja maatilojen toimivia käytäntöjä? Keskusteletko työturvallisuudesta muiden kanssa? Oletko hankkinut itsellesi työturvallisuuteen liittyvää koulutusta? Kirjaa aina läheltä piti -tilanteen tai tapaturman satuttua ylös tilanteeseen johtaneet asiat: mitä tapahtui, milloin, kenelle ja miksi. Mieti, millä tavoin voit jatkossakin ehkäistä vahinkoja. Vahingosta voi aina viisastua!",
        },
      },
    },
    tyky_10: {
      good: {
        short_feedback: "Hyvä tulos, jatka samaan malliin!",
        group_feedback: {
          good: "Maatilan toimintaan liittyvä kuormitus on sinulla tällä hetkellä yhtä hyvällä tasolla kuin vertailuryhmälläsi. Hienoa! Jos tilanne muuttuu ja kuormitus lisääntyy, ota yhteyttä Välitä viljelijästä -projektiin matalalla kynnyksellä.",
          med: "Maatilan toimintaan liittyvä kuormitus on sinulla tällä hetkellä vähäisempää kuin vertailuryhmälläsi. Hienoa! Jos tilanne muuttuu ja kuormitus lisääntyy, ota yhteyttä Välitä viljelijästä -projektiin matalalla kynnyksellä.",
          bad: "Maatilan toimintaan liittyvä kuormitus on sinulla tällä hetkellä selvästi vähäisempää kuin vertailuryhmälläsi. Hienoa! Jos tilanne muuttuu ja kuormitus lisääntyy, ota yhteyttä Välitä viljelijästä -projektiin matalalla kynnyksellä.",
        },
        additional_information: {
          title: "Opi maatilan toimintaan liittyvästä kuormituksesta",
          links: [
            {
              key: "välitä_viljelijästä-projekti",
            },
          ],
          text: "Maatalousyrittäjän työ on monipuolista ja vaatii paljon erilaista osaamista, suunnittelua, uuden opettelua ja asioiden selvittämistä. Työtä tehdään paljon yksin ja päätösten tekemiseen ei aina löydy toista henkilöä, kenen kanssa asioita voisi yhdessä miettiä. Yksin ei kuitenkaan tarvitse pärjätä; ulkopuolisen asiantuntijan kanssa haastavammaltakin tuntuva tilanne selkiytyy. Jos jokin työhön liittyvä asia aiheuttaa huolta, heijastuu se helposti jaksamiseen ja ihmissuhteisiin. Välitä viljelijästä -projektityöntekijä auttaa etsimään yhdessä kanssasi keinoja haastavaltakin tuntuvaan tilanteeseen. Yhteyttä kannattaa ottaa jo varhaisessa vaiheessa, niin tilanne selkiytyy helpommin. Apua on saatavilla!",
        },
      },
      med: {
        short_feedback: "Tuloksesi huolestuttaa, kiinnitä tähän huomiota.",
        group_feedback: {
          good: "Maatilan toimintaan liittyvä kuormitus on sinulla tällä hetkellä suurempaa kuin vertailuryhmälläsi. Miten kuormitusta voisi vähentää? Apua kannattaa pyytää jo varhaisessa vaiheessa. Ota yhteyttä Välitä viljelijästä -projektiin, jonka kautta saat maksutonta ja luottamuksellista apua matalalla kynnyksellä.",
          med: "Maatilan toimintaan liittyvä kuormitus on sinulla tällä hetkellä samantasoista kuin vertailuryhmälläsi. Miten kuormitusta voisi vähentää? Apua kannattaa pyytää jo varhaisessa vaiheessa. Ota yhteyttä Välitä viljelijästä -projektiin, jonka kautta saat maksutonta ja luottamuksellista apua matalalla kynnyksellä.",
          bad: "Maatilan toimintaan liittyvä kuormitus on sinulla tällä hetkellä kuitenkin vähäisempää kuin vertailuryhmälläsi. Miten kuormitusta voisi vähentää? Apua kannattaa pyytää jo varhaisessa vaiheessa. Ota yhteyttä Välitä viljelijästä -projektiin, jonka kautta saat maksutonta ja luottamuksellista apua matalalla kynnyksellä.",
        },
        additional_information: {
          title: "Opi maatilan toimintaan liittyvästä kuormituksesta",
          links: [
            {
              key: "välitä_viljelijästä-projekti",
            },
          ],
          text: "Maatalousyrittäjän työ on monipuolista ja vaatii paljon erilaista osaamista, suunnittelua, uuden opettelua ja asioiden selvittämistä. Työtä tehdään paljon yksin ja päätösten tekemiseen ei aina löydy toista henkilöä, kenen kanssa asioita voisi yhdessä miettiä. Yksin ei kuitenkaan tarvitse pärjätä; ulkopuolisen asiantuntijan kanssa haastavammaltakin tuntuva tilanne selkiytyy. Jos jokin työhön liittyvä asia aiheuttaa huolta, heijastuu se helposti jaksamiseen ja ihmissuhteisiin. Välitä viljelijästä -projektityöntekijä auttaa etsimään yhdessä kanssasi keinoja haastavaltakin tuntuvaan tilanteeseen. Yhteyttä kannattaa ottaa jo varhaisessa vaiheessa, niin tilanne selkiytyy helpommin. Apua on saatavilla!",
        },
      },
      bad: {
        short_feedback:
          "Tuloksesi on huolestuttava, haethan apua tilanteeseen.",
        group_feedback: {
          good: "Maatilan toimintaan liittyvä kuormitus on sinulla tällä hetkellä selvästi suurempaa kuin vertailuryhmälläsi. Miten kuormitusta voisi vähentää? Pyydä tilanteeseen apua ja ota yhteyttä Välitä viljelijästä -projektiin. Sen kautta saat luottamuksellista ja maksutonta apua matalalla kynnyksellä. ",
          med: "Maatilan toimintaan liittyvä kuormitus on sinulla tällä hetkellä suurempaa kuin vertailuryhmälläsi. Miten kuormitusta voisi vähentää? Pyydä tilanteeseen apua ja ota yhteyttä Välitä viljelijästä -projektiin. Sen kautta saat luottamuksellista ja maksutonta apua matalalla kynnyksellä.",
          bad: "Maatilan toimintaan liittyvä kuormitus on sinulla tällä hetkellä samantasoista kuin vertailuryhmälläsi. Miten kuormitusta voisi vähentää? Pyydä tilanteeseen apua ja ota yhteyttä Välitä viljelijästä -projektiin. Sen kautta saat luottamuksellista ja maksutonta apua matalalla kynnyksellä.",
        },
        additional_information: {
          title: "Opi maatilan toimintaan liittyvästä kuormituksesta",
          links: [
            {
              key: "välitä_viljelijästä-projekti",
            },
          ],
          text: "Maatalousyrittäjän työ on monipuolista ja vaatii paljon erilaista osaamista, suunnittelua, uuden opettelua ja asioiden selvittämistä. Työtä tehdään paljon yksin ja päätösten tekemiseen ei aina löydy toista henkilöä, kenen kanssa asioita voisi yhdessä miettiä. Yksin ei kuitenkaan tarvitse pärjätä; ulkopuolisen asiantuntijan kanssa haastavammaltakin tuntuva tilanne selkiytyy. Jos jokin työhön liittyvä asia aiheuttaa huolta, heijastuu se helposti jaksamiseen ja ihmissuhteisiin. Välitä viljelijästä -projektityöntekijä auttaa etsimään yhdessä kanssasi keinoja haastavaltakin tuntuvaan tilanteeseen. Yhteyttä kannattaa ottaa jo varhaisessa vaiheessa, niin tilanne selkiytyy helpommin. Apua on saatavilla!",
        },
      },
    },
  },
  sv: {
    tyky_1: {
      good: {
        short_feedback: "Bra, fortsätt i samma stil! ",
        group_feedback: {
          good: "Du känner lika bra arbetstillfredsställelse som din jämförelsegrupp. Hög arbetstillfredsställelse har samband med gårdens produktivitet och framgång. En viktig faktor i arbetstillfredsställelsen är att stärka kompetensen och yrkeskunskapen. Hur skulle du kunna utveckla dem ytterligare?",
          med: "Du känner bättre arbetstillfredsställelse än din jämförelsegrupp. Hög arbetstillfredsställelse har samband med gårdens produktivitet och framgång. En viktig faktor i arbetstillfredsställelsen är att stärka kompetensen och yrkeskunskapen. Fundera på hur du skulle kunna utveckla dem ytterligare?",
          bad: "Du känner klart bättre arbetstillfredsställelse än din jämförelsegrupp. Hög arbetstillfredsställelse har samband med gårdens produktivitet och framgång. En viktig faktor i arbetstillfredsställelsen är att stärka kompetensen och yrkeskunskapen.  Fundera på hur du skulle kunna utveckla dem ytterligare?",
        },
        additional_information: {
          links: [
            {
              key: "työturvallisuuskorttikoulutus"
            },
            {
              key: "hyvinvointipäivät",
            },
            {
              key: "webinaarit",
            },
          ],
          title: "Om arbetstillfredsställelse ",
          text: "Lantbruksföretagare känner ofta lite lägre arbetstillfredsställelse än många andra yrkesgrupper. Det här beror bland annat på det bindande arbetet, arbetsbördan, den ekonomiska osäkerheten, den administrativa bördan och press som kommer utifrån. Skulle du vilja förbättra din arbetstillfredsställelse ytterligare? Då lönar det sig att särskilt satsa på sådant som ger dig kraft i arbetet och fundera på vad som ger mest arbetsglädje eller vad som är den största drivkraften för dig i arbetet? Gör så ofta som möjligt sådant som motsvarar dina starka sidor och stämmer överens med dina värderingar. Skulle till exempel arbetshandledning kunna stödja dig i arbetet? Det är också värdefullt att få respons. Försök aktivt få respons på ditt arbete och bygg nätverk till exempel med andra företagare.",
        },
      },
      med: {
        short_feedback:
          "Ditt resultat är oroväckande och något som du behöver vara uppmärksam på.",
        group_feedback: {
          good: "Du känner sämre arbetstillfredsställelse än din jämförelsegrupp. Arbetstillfredsställelse hjälper dig att orka och stöder gårdens framgång. Viktiga faktorer i arbetstillfredsställelsen är att stärka kompetensen och att ha möjlighet att utnyttja sina starka sidor tillräckligt mycket. Hur skulle du kunna öka din kompetens och utnyttja dina starka sidor i högre grad i arbetet?",
          med: "Du känner arbetstillfredsställelse i motsvarande grad som din jämförelsegrupp. Arbetstillfredsställelse hjälper dig att orka och stöder gårdens framgång. Viktiga faktorer i arbetstillfredsställelsen är att stärka kompetensen och att ha möjlighet att utnyttja sina starka sidor tillräckligt mycket. Hur skulle du kunna öka din kompetens och utnyttja dina starka sidor i högre grad i arbetet?",
          bad: "Du känner ändå bättre arbetstillfredsställelse än din jämförelsegrupp. Arbetstillfredsställelse hjälper dig att orka och stöder gårdens framgång. Viktiga faktorer i arbetstillfredsställelsen är att stärka kompetensen och att ha möjlighet att utnyttja sina starka sidor tillräckligt mycket. Hur skulle du kunna öka din kompetens och utnyttja dina starka sidor i högre grad i arbetet?",
        },
        additional_information: {
          links: [
            {
              key: "työturvallisuuskorttikoulutus"
            },
            {
              key: "hyvinvointipäivät",
            },
            {
              key: "välitä_viljelijästä-projekti",
            },
            {
              key: "hyvinvoinnin_abc-koulutus",
            },
          ],
          title: "Om arbetstillfredsställelse",
          text: "Lantbruksföretagare känner ofta lite lägre arbetstillfredsställelse än många andra yrkesgrupper. Det här beror bland annat på det bindande arbetet, arbetsbördan, den ekonomiska osäkerheten, den administrativa bördan och press som kommer utifrån. Skulle du vilja förbättra din arbetstillfredsställelse ytterligare? Då lönar det sig att särskilt satsa på sådant som ger dig kraft i arbetet och fundera på vad som ger mest arbetsglädje eller vad som är den största drivkraften för dig i arbetet? Gör så ofta som möjligt sådant som motsvarar dina starka sidor och stämmer överens med dina värderingar. Skulle till exempel arbetshandledning kunna stödja dig i arbetet? Det är också värdefullt att få respons. Försök aktivt få respons på ditt arbete och bygg nätverk till exempel med andra företagare.",
        },
      },
      bad: {
        short_feedback: "Ditt resultat väcker oro – du söker väl hjälp?",
        group_feedback: {
          good: "Du känner klart sämre arbetstillfredsställelse än din jämförelsegrupp. Svag arbetstillfredsställelse är en riskfaktor för lantbruksföretaget. Gör en lista över faktorer som utgör en belastning i arbetet och fundera på vad som naggar din arbetstillfredsställelse i kanten. Fundera också på vad som ger dig mest kraft att orka med arbetet och se till att få in mer av den varan i arbetsdagarna. Gör så ofta som möjligt sådant som motsvarar dina starka sidor. Skulle arbetshandledning kunna vara till hjälp?",
          med: "Du känner sämre arbetstillfredsställelse än din jämförelsegrupp. Svag arbetstillfredsställelse är en riskfaktor för lantbruksföretaget. Gör en lista över faktorer som utgör en belastning i arbetet och fundera på vad som naggar din arbetstillfredsställelse i kanten. Fundera också på vad som ger dig mest kraft att orka med arbetet och se till att få in mer av den varan i arbetsdagarna. Gör så ofta som möjligt sådant som motsvarar dina starka sidor. Skulle arbetshandledning kunna vara till hjälp?",
          bad: "Du känner arbetstillfredsställelse i motsvarande grad som din jämförelsegrupp. Svag arbetstillfredsställelse är en riskfaktor för lantbruksföretaget. Gör en lista över faktorer som utgör en belastning i arbetet och fundera på vad som naggar din arbetstillfredsställelse i kanten. Fundera också på vad som ger dig mest kraft att orka med arbetet och se till att få in mer av den varan i arbetsdagarna. Gör så ofta som möjligt sådant som motsvarar dina starka sidor. Skulle arbetshandledning kunna vara till hjälp?",
        },
        additional_information: {
          links: [
            {
              key: "välitä_viljelijästä-projekti",
            },
            {
              key: "hyvinvoinnin_abc-koulutus",
            },
            {
              key: "etävertaisryhmä",
            },
            {
              key: "tyhy-neuvoja",
            },
          ],
          title: "Om arbetstillfredsställelse",
          text: "Lantbruksföretagare känner ofta lite lägre arbetstillfredsställelse än många andra yrkesgrupper. Det här beror bland annat på det bindande arbetet, arbetsbördan, den ekonomiska osäkerheten, den administrativa bördan och press som kommer utifrån. Skulle du vilja förbättra din arbetstillfredsställelse ytterligare? Då lönar det sig att särskilt satsa på sådant som ger dig kraft i arbetet och fundera på vad som ger mest arbetsglädje eller vad som är den största drivkraften för dig i arbetet? Gör så ofta som möjligt sådant som motsvarar dina starka sidor och stämmer överens med dina värderingar. Skulle till exempel arbetshandledning kunna stödja dig i arbetet? Det är också värdefullt att få respons. Försök aktivt få respons på ditt arbete och bygg nätverk till exempel med andra företagare.",
        },
      },
    },
    tyky_2: {
      good: {
        short_feedback: "Bra, fortsätt i samma stil! ",
        group_feedback: {
          good: "Du återhämtar dig lika bra som din jämförelsegrupp. När du är pigg och full av energi löper vardagen smidigt och risken för olyckor är liten. Hur tillbringar du helst fritiden?",
          med: "Du återhämtar dig bättre än din jämförelsegrupp. När du är pigg och entusiastisk löper vardagen smidigt och risken för olyckor är liten. Hur tillbringar du helst fritiden?",
          bad: "Du återhämtar dig klart bättre än din jämförelsegrupp. När du är pigg och entusiastisk löper vardagen smidigt och risken för olyckor är liten. Hur tillbringar du helst fritiden?",
        },
        additional_information: {
          links: [
            {
              key: "taukojumppavideo",
            },
            {
              key: "hyvinvointipäivät",
            },
            {
              key: "kuntoremonttikurssi",
            },
          ],
          title: "Om återhämtning ",
          text: "Visste du att lantbruksföretagare ofta tycker att de återhämtar sig sämre efter arbetet än andra yrkesgrupper? Dålig återhämtning försämrar arbetsprestationen och ökar risken för olyckor och arbetsutmattning. Lantbruksföretagarens arbete är belastande och därför är det viktigt att se till att få tillräcklig återhämtning i vardagen. Den effektivaste pausen är en paus som verkligen tagits! Återhämtning sker när du kan släppa tankarna på arbetet och både kropp och själ kopplar av. Långa arbetsdagar, nya situationer, stress och fysisk belastning gör att vår kropp behöver vila. Intensiva arbetsperioder ökar behovet av återhämtning. Reservera regelbundet tid för vila, så att du orkar arbeta i fortsättningen också. Betydelsen av återhämtning accentueras när arbetsbördan är stor och den intensiva arbetsperioden har pågått länge. Slarva inte med grundpelarna för välmåendet, för var och och en av oss behöver vila, mat och motion regelbundet. Sunda levnadsvanor hjälper oss att orka.",
        },
      },
      med: {
        short_feedback:
          "Ditt resultat är oroväckande och något som du behöver vara uppmärksam på.",
        group_feedback: {
          good: "Du återhämtar dig sämre än din jämförelsegrupp. Vilka förändringar kan du göra för att återhämta dig bättre? Olycksrisken ökar och energin minskar när man är trött. Lägg in fler vilopauser i vardagen, också korta pauser hjälper dig att orka!",
          med: "Du återhämtar dig i motsvarande grad som din jämförelsegrupp. Vilka förändringar kan du göra för att återhämta dig bättre? Olycksrisken ökar och energin minskar när man är trött. Lägg in fler vilopauser i vardagen, också korta pauser hjälper dig att orka!",
          bad: "Du återhämtar dig ändå bättre än din jämförelsegrupp. Vilka förändringar kan du göra för att återhämta dig ännu bättre? Olycksrisken ökar och energin minskar när man är trött. Lägg in fler vilopauser i vardagen, också korta pauser hjälper dig att orka!",
        },
        additional_information: {
          links: [
            {
              key: "välitä_viljelijästä-projekti",
            },
            {
              key: "tuetut_lomat",
            },
            {
              key: "kuntoremonttikurssi",
            },
          ],
          title: "Om återhämtning",
          text: "Visste du att lantbruksföretagare ofta tycker att de återhämtar sig sämre efter arbetet än andra yrkesgrupper? Dålig återhämtning försämrar arbetsprestationen och ökar risken för olyckor och arbetsutmattning. Lantbruksföretagarens arbete är belastande och därför är det viktigt att se till att få tillräcklig återhämtning i vardagen. Den effektivaste pausen är en paus som verkligen tagits! Återhämtning sker när du kan släppa tankarna på arbetet och både kropp och själ kopplar av. Långa arbetsdagar, nya situationer, stress och fysisk belastning gör att vår kropp behöver vila. Intensiva arbetsperioder ökar behovet av återhämtning. Reservera regelbundet tid för vila, så att du orkar arbeta i fortsättningen också. Betydelsen av återhämtning accentueras när arbetsbördan är stor och den intensiva arbetsperioden har pågått länge. Slarva inte med grundpelarna för välmåendet, för var och en av oss behöver vila, mat och motion regelbundet. Sunda levnadsvanor hjälper oss att orka.",
        },
      },
      bad: {
        short_feedback: "Ditt resultat väcker oro – du söker väl hjälp?",
        group_feedback: {
          good: "Du återhämtar dig klart sämre än din jämförelsegrupp. Redan små förändringar kan göra att du återhämtar dig bättre. Börja redan i dag! Olycksrisken ökar och energin minskar när man är trött. Prioritera arbetena efter viktighet och utnyttja tipsen för tidshantering. Om du har svårt att koppla av och lider av sömnsvårigheter ska du inte dra dig för att kontakta hälsovården. ",
          med: "Du återhämtar dig sämre än din jämförelsegrupp. Redan små förändringar kan göra att du återhämtar dig bättre. Börja redan i dag! Olycksrisken ökar och energin minskar när man är trött. Prioritera arbetena efter viktighet och utnyttja tipsen för tidshantering. Om du har svårt att koppla av och lider av sömnsvårigheter ska du inte dra dig för att kontakta hälsovården.",
          bad: "Du återhämtar dig i motsvarande grad som din jämförelsegrupp. Redan små förändringar kan göra att du återhämtar dig bättre. Börja redan i dag! Olycksrisken ökar och energin minskar när man är trött. Prioritera arbetena efter viktighet och utnyttja tipsen för tidshantering. Om du har svårt att koppla av och lider av sömnsvårigheter ska du inte dra dig för att kontakta hälsovården.",
        },
        additional_information: {
          links: [
            {
              key: "välitä_viljelijästä-projekti",
            },
            {
              key: "tyhy-neuvoja",
            },
            {
              key: "terveydenhuollon_palvelut",
            },
          ],
          title: "Om återhämtning",
          text: "Visste du att lantbruksföretagare ofta tycker att de återhämtar sig sämre efter arbetet än andra yrkesgrupper? Dålig återhämtning försämrar arbetsprestationen och ökar risken för olyckor och arbetsutmattning. Lantbruksföretagarens arbete är belastande och därför är det viktigt att se till att få tillräcklig återhämtning i vardagen. Den effektivaste pausen är en paus som verkligen tagits! Återhämtning sker när du kan släppa tankarna på arbetet och både kropp och själ kopplar av. Långa arbetsdagar, nya situationer, stress och fysisk belastning gör att vår kropp behöver vila. Intensiva arbetsperioder ökar behovet av återhämtning. Reservera regelbundet tid för vila, så att du orkar arbeta i fortsättningen också. Betydelsen av återhämtning accentueras när arbetsbördan är stor och den intensiva arbetsperioden har pågått länge. Slarva inte med grundpelarna för välmåendet, för var och och en av oss behöver vila, mat och motion regelbundet. Sunda levnadsvanor hjälper oss att orka.",
        },
      },
    },
    tyky_3: {
      good: {
        short_feedback: "Bra, fortsätt i samma stil! ",
        group_feedback: {
          good: "Du kan koppla bort från arbetet lika bra som din jämförelsegrupp. Bra balans mellan arbete och fritid hjälper dig att orka! Vad skulle du vilja ägna mer tid åt?",
          med: "Du kan koppla bort från arbetet bättre än din jämförelsegrupp. Bra balans mellan arbete och fritid hjälper dig att orka! Vad skulle du vilja ägna mer tid åt?",
          bad: "Du kan koppla bort från arbetet klart bättre än din jämförelsegrupp. Bra balans mellan arbete och fritid hjälper dig att orka! Vad skulle du vilja ägna mer tid åt?",
        },
        additional_information: {
          links: [
            {
              key: "tuetut_lomat",
            },
            {
              key: "hyvinvointipäivät",
            },
            {
              key: "hyvinvoinnin_abc-koulutus",
            },
          ],
          title: "Om att koppla bort från arbetet",
          text: "På ett lantbruk är det vanligt att det är svårt att skilja mellan arbete och fritid. Arbetet är bindande och sker ofta i hemmiljö, och därför måste man särskilt fundera ut lämpliga sätt att koppla bort från arbetet under fritiden. Hur ofta tillbringar du tid utanför gården? Under fritiden kan du syssla med något som du trivs med, eller bara ta det lugnt och slappa. Vad skulle du gärna vilja ägna mer tid åt? Pröva på en hobby där du kan förverkliga dig själv eller lära dig nytt. Uppiggande fritid ger positiv energi i ditt liv! Du tänker väl också på att ta dig tid för att umgås med dina närmaste och vårda andra sociala relationer? Familj, vänner och sociala nätverk är viktiga kraftresurser i olika skeden av livet. ",
        },
      },
      med: {
        short_feedback:
          "Ditt resultat är oroväckande och något som du behöver vara uppmärksam på.",
        group_feedback: {
          good: "Du kan koppla bort från arbetet sämre än din jämförelsegrupp. Det är lättare att orka när det råder balans mellan arbete och fritid. Vilken förändring i hur du disponerar din tid skulle ge dig mer tid för hobbyer och annat som du gillar? Skulle det vara till hjälp att omorganisera eller utlokalisera vissa arbetsuppgifter? Redan en liten förändring kan vara till hjälp!",
          med: "Du kan koppla bort från arbetet i motsvarande grad som din jämförelsegrupp. Det är lättare att orka när det råder balans mellan arbete och fritid. Vilken förändring i hur du disponerar din tid skulle ge dig mer tid för hobbyer och annat som du gillar? Skulle det vara till hjälp att omorganisera eller utlokalisera vissa arbetsuppgifter? Redan en liten förändring kan vara till hjälp! ",
          bad: "Du kan ändå koppla bort från arbetet bättre än din jämförelsegrupp. Det är lättare att orka när det råder balans mellan arbete och fritid. Vilken förändring i hur du disponerar din tid skulle ge dig mer tid för hobbyer och annat som du gillar? Skulle det vara till hjälp att omorganisera eller utlokalisera vissa arbetsuppgifter? Redan en liten förändring kan vara till hjälp!",
        },
        additional_information: {
          links: [
            {
              key: "välitä_viljelijästä-projekti",
            },
            {
              key: "tuetut_lomat",
            },
            {
              key: "hyvinvoinnin_abc-koulutus",
            },
          ],
          title: "Om att koppla bort från arbetet:",
          text: "På ett lantbruk är det vanligt att det är svårt att skilja mellan arbete och fritid. Arbetet är bindande och sker ofta i hemmiljö, och därför måste man särskilt fundera ut lämpliga sätt att koppla bort från arbetet under fritiden. Hur ofta tillbringar du tid utanför gården? Under fritiden kan du syssla med något som du trivs med, eller bara ta det lugnt och slappa. Vad skulle du gärna vilja ägna mer tid åt? Pröva på en hobby där du kan förverkliga dig själv eller lära dig nytt. Uppiggande fritid ger positiv energi i ditt liv! Du tänker väl också på att ta dig tid för att umgås med dina närmaste och vårda andra sociala relationer? Familj, vänner och sociala nätverk är viktiga kraftresurser i olika skeden av livet.",
        },
      },
      bad: {
        short_feedback: "Ditt resultat väcker oro – du söker väl hjälp?",
        group_feedback: {
          good: "Du kan koppla bort från arbetet klart sämre än din jämförelsegrupp. Balans mellan arbete och fritid hjälper dig att orka. Det skulle vara bra om du fick mer tid över för hobbyer och annat som du gillar. Prioritera arbetena efter viktighet och fundera på om det finns något som du kan utlokalisera. Redan en liten förändring i hur du disponerar din tid kan vara till hjälp!",
          med: "Du kan koppla bort från arbetet sämre än din jämförelsegrupp. Balans mellan arbete och fritid hjälper dig att orka. Det skulle vara bra om du fick mer tid över för hobbyer och annat som du gillar. Prioritera arbetena efter viktighet och fundera på om det finns något som du kan utlokalisera. Redan en liten förändring i hur du disponerar din tid kan vara till hjälp!",
          bad: "Du kan koppla bort från arbetet i motsvarande grad som din jämförelsegrupp. Balans mellan arbete och fritid hjälper dig att orka. Det skulle vara bra om du fick mer tid över för hobbyer och annat som du gillar. Prioritera arbetena efter viktighet och fundera på om det finns något som du kan utlokalisera. Redan en liten förändring i hur du disponerar din tid kan vara till hjälp!",
        },
        additional_information: {
          links: [
            {
              key: "välitä_viljelijästä-projekti",
            },
            {
              key: "terveydenhuollon_palvelut",
            },
            {
              key: "tyhy-neuvoja",
            },
            {
              key: "etävertaisryhmä",
            },
          ],
          title: "Om att koppla bort från arbetet",
          text: "På ett lantbruk är det vanligt att det är svårt att skilja mellan arbete och fritid. Arbetet är bindande och sker ofta i hemmiljö, och därför måste man särskilt fundera ut lämpliga sätt att koppla bort från arbetet under fritiden. Hur ofta tillbringar du tid utanför gården? Under fritiden kan du syssla med något som du trivs med, eller bara ta det lugnt och slappa. Vad skulle du gärna vilja ägna mer tid åt? Pröva på en hobby där du kan förverkliga dig själv eller lära dig nytt. Uppiggande fritid ger positiv energi i ditt liv! Du tänker väl också på att ta dig tid för att umgås med dina närmaste och vårda andra sociala relationer? Familj, vänner och sociala nätverk är viktiga kraftresurser i olika skeden av livet.",
        },
      },
    },
    tyky_4: {
      good: {
        short_feedback: "Bra, fortsätt i samma stil! ",
        group_feedback: {
          good: "Du känner lika stark hoppfullhet som din jämförelsegrupp. Hoppfullhet har samband med arbetsmotivation och vilja att lära sig nytt. Hur håller du hoppfullheten uppe? Sätt upp nya mål för dig och utmana dig själv! Gör sådana val som stämmer överens med dina värderingar.",
          med: "Du känner starkare hoppfullhet än din jämförelsegrupp. Hoppfullhet har samband med arbetsmotivation och vilja att lära sig nytt. Hur håller du hoppfullheten uppe? Sätt upp nya mål för dig och utmana dig själv! Gör sådana val som stämmer överens med dina värderingar.",
          bad: "Du känner klart starkare hoppfullhet än din jämförelsegrupp. Hoppfullhet har samband med arbetsmotivation och vilja att lära sig nytt. Hur håller du hoppfullheten uppe? Sätt upp nya mål för dig och utmana dig själv! Gör sådana val som stämmer överens med dina värderingar.",
        },
        additional_information: {
          links: [
            {
              key: "hyvinvointipäivät",
            },
            {
              key: "oivamieli-harjoitukset",
            },
          ],
          title: "Om hoppfullhet ",
          text: "Faktorer som ofta minskar hoppfullheten hos lantbruksföretagare är framför allt osäkra utsikter i nuläget och inför framtiden, den ständigt växlande stödpolitiken samt företagets lönsamhet. Företagsverksamhet är också förknippad med en hel del osäkerhet. Identifiera osäkerhet och risker och tänk ut hur du kan hantera dem. Du kan också anlita utomstående hjälp för det här. Bli medveten om dina egna tankemodeller och lägg märke till hur de kan hjälpa dig att känna större hoppfullhet. Ibland minskar hoppfullheten av att vi inte kan leva ett liv som stämmer överens med våra värderingar. Tänk igenom vad som du verkligen värdesätter och ett hurdant liv du vill leva. Är dina värderingar i överensstämmelse med dina mål? Fundera också på hur mycket tid du lägger på att grubbla på saker som du faktiskt inte kan påverka (så som vädret eller stödpolitiken). Försök att fokusera på att förbättra de saker som du verkligen själv kan påverka.",
        },
      },
      med: {
        short_feedback:
          "Ditt resultat är oroväckande och något som du behöver vara uppmärksam på.",
        group_feedback: {
          good: "Du känner mindre hoppfullhet än din jämförelsegrupp. Även om du inte känner hoppfullhet just nu, är det bra att veta att det är något som går att träna upp. Försök förtydliga dina värderingar och bli medveten om dina tankemodeller - skulle du kunna lära dig att stärka känslan av hoppfullhet? Det lönar sig att försöka bli mer hoppfull, eftersom hoppfullhet har samband med arbetsmotivation och vilja att lära sig nytt.",
          med: "Du känner hoppfullhet i motsvarande grad som din jämförelsegrupp. Kanske du skulle vilja stärka känslan av hoppfullhet? Försök förtydliga dina värderingar och gör sådana val som stämmer överens med dina värderingar. Bli också medveten om dina tankemodeller. Det lönar sig att stärka känslan av hoppfullhet, eftersom hoppfullhet har samband med arbetsmotivation och vilja att lära sig nytt.",
          bad: "Du känner ändå starkare hoppfullhet än din jämförelsegrupp. Kanske du skulle vilja stärka känslan av hoppfullhet? Försök förtydliga dina värderingar och gör sådana val som stämmer överens med dina värderingar. Bli också medveten om dina tankemodeller. Det lönar sig att stärka känslan av hoppfullhet, eftersom hoppfullhet har samband med arbetsmotivation och vilja att lära sig nytt.",
        },
        additional_information: {
          links: [
            {
              key: "välitä_viljelijästä-projekti",
            },
            {
              key: "hyvinvoinnin_abc-koulutus",
            },
            {
              key: "oivamieli-harjoitukset",
            },
          ],
          title: "Om hoppfullhet ",
          text: "Faktorer som ofta minskar hoppfullheten hos lantbruksföretagare är framför allt osäkra utsikter i nuläget och inför framtiden, den ständigt växlande stödpolitiken samt företagets lönsamhet. Företagsverksamhet är också förknippad med en hel del osäkerhet. Identifiera osäkerhet och risker och tänk ut hur du kan hantera dem. Du kan också anlita utomstående hjälp för det här. Bli medveten om dina egna tankemodeller och lägg märke till hur de kan hjälpa dig att känna större hoppfullhet. Ibland minskar hoppfullheten av att vi inte kan leva ett liv som stämmer överens med våra värderingar. Tänk igenom vad som du verkligen värdesätter och ett hurdant liv du vill leva. Är dina värderingar i överensstämmelse med dina mål? Fundera också på hur mycket tid du lägger på att grubbla på saker som du faktiskt inte kan påverka (så som vädret eller stödpolitiken). Försök att fokusera på att förbättra de saker som du verkligen själv kan påverka.",
        },
      },
      bad: {
        short_feedback: "Ditt resultat väcker oro – du söker väl hjälp? ",
        group_feedback: {
          good: "Du känner klart mindre hoppfullhet än din jämförelsegrupp. Även om du inte känner hoppfullhet just nu, är det bra att veta att det är något som går att träna upp. Försök förtydliga dina värderingar och bli medveten om dina tankemodeller - skulle du kunna lära dig att stärka känslan av hoppfullhet? Det lönar sig, eftersom hoppfullhet har samband med arbetsmotivation och vilja att lära sig nytt.",
          med: "Du känner mindre hoppfullhet än din jämförelsegrupp. Även om du inte känner hoppfullhet just nu, är det bra att veta att det är något som går att träna upp. Försök förtydliga dina värderingar och bli medveten om dina tankemodeller - skulle du kunna lära dig att stärka känslan av hoppfullhet? Det lönar sig, eftersom hoppfullhet har samband med arbetsmotivation och vilja att lära sig nytt.",
          bad: "Du känner hoppfullhet i motsvarande grad som din jämförelsegrupp. Även om du inte känner hoppfullhet just nu, är det bra att veta att det är något som går att träna upp. Försök förtydliga dina värderingar och bli medveten om dina tankemodeller - skulle du kunna lära dig att stärka känslan av hoppfullhet? Det lönar sig, eftersom hoppfullhet har samband med arbetsmotivation och vilja att lära sig nytt.",
        },
        additional_information: {
          links: [
            {
              key: "välitä_viljelijästä-projekti",
            },
            {
              key: "hyvinvoinnin_abc-koulutus",
            },
            {
              key: "terveydenhuollon_palvelut",
            },
            {
              key: "mielenterveystalo",
            },
          ],
          title: "Om hoppfullhet ",
          text: "Faktorer som ofta minskar hoppfullheten hos lantbruksföretagare är framför allt osäkra utsikter i nuläget och inför framtiden, den ständigt växlande stödpolitiken samt företagets lönsamhet. Företagsverksamhet är också förknippad med en hel del osäkerhet. Identifiera osäkerhet och risker och tänk ut hur du kan hantera dem. Du kan också anlita utomstående hjälp för det här. Bli medveten om dina egna tankemodeller och lägg märke till hur de kan hjälpa dig att känna större hoppfullhet. Ibland minskar hoppfullheten av att vi inte kan leva ett liv som stämmer överens med våra värderingar. Tänk igenom vad som du verkligen värdesätter och ett hurdant liv du vill leva. Är dina värderingar i överensstämmelse med dina mål? Fundera också på hur mycket tid du lägger på att grubbla på saker som du faktiskt inte kan påverka (så som vädret eller stödpolitiken). Försök att fokusera på att förbättra de saker som du verkligen själv kan påverka.",
        },
      },
    },
    tyky_5: {
      good: {
        short_feedback: "Bra, fortsätt i samma stil!",
        group_feedback: {
          good: "Din stressnivå ligger på lika god nivå som i din jämförelsegrupp. Du har bra balans mellan belastning och återhämtning. Bevara och förbättra den gynnsamma situationen genom att planera ditt arbete, uppdatera din kompetens, utnyttja de stödtjänster som behövs och se till att du får tillräcklig återhämtning.",
          med: "Din stressnivå ligger på bättre nivå än i din jämförelsegrupp. Du har bra balans mellan belastning och återhämtning. Bevara och förbättra den gynnsamma situationen genom att planera ditt arbete, uppdatera din kompetens, utnyttja de stödtjänster som behövs och se till att du får tillräcklig återhämtning.",
          bad: "Din stressnivå ligger på klart bättre nivå än i din jämförelsegrupp. Du har bra balans mellan belastning och återhämtning. Bevara och förbättra den gynnsamma situationen genom att planera ditt arbete, uppdatera din kompetens, utnyttja de stödtjänster som behövs och se till att du får tillräcklig återhämtning.",
        },
        additional_information: {
          links: [
            {
              key: "hyvinvointipäivät",
            },
            {
              key: "kuntoremonttikurssi",
            },
            {
              key: "webinaarit",
            },
          ],
          title: "Om stress ",
          text: "Arbetsbördan, arbetsarrangemangen, hur bindande arbetet är, brådska och förändringar i arbetet är exempel på faktorer som orsakar stress i arbetet. Andra faktorer som ökar arbetets psykiska belastning hos lantbruksföretagare är byråkratin i samband med verksamheten, administrativa uppgifter, ekonomisk osäkerhet, att man arbetar ensam, vädret och det säsongsbetonade arbetet. Vid stress i arbetet handlar det om att det råder obalans mellan arbetets krav och känslan av kontroll. Långvarig stress i arbetet har negativ inverkan på hälsan, välbefinnandet i arbetet och arbetssäkerheten på många olika sätt. Den ökar också risken för arbetsutmattning. Minnesstörningar, koncentrationssvårigheter och svårigheter att hantera sina känslor kan vara tecken på för mycket stress. Man kan kontrollera stressen genom att identifiera vilka faktorer som orsakar stress och göra något åt situationer som man själv kan påverka. Att vara företagare handlar ytterst om att leda sig själv, våga sätta mål, prioritera arbetsuppgifter samt om att disponera sin tid och ta hand om sig själv så att man orkar med arbetet. All stress är inte skadlig, utan stress kan också vara en positiv kraft som gör att man får mer till stånd och fungerar som en viktig motivationsfaktor. Det ger tillfredsställelse att lyckas i ett arbete som är lagom krävande.",
        },
      },
      med: {
        short_feedback:
          "Ditt resultat är oroväckande och något som du behöver vara uppmärksam på.",
        group_feedback: {
          good: "Din stressnivå är förhöjd och högre än i din jämförelsegrupp. Vid stress i arbetet handlar det om obalans mellan arbetets krav och känslan av kontroll. Vad är det som är den största belastningen för dig? Nu är det hög tid att stanna upp och fundera på hur du skulle kunna minska den.",
          med: "Din stressnivå ligger på samma nivå som i din jämförelsegrupp. Vid stress i arbetet handlar det om obalans mellan arbetets krav och känslan av kontroll. Vad är det som är den största belastningen för dig? Nu är det hög tid att stanna upp och fundera på hur du skulle kunna minska den.",
          bad: "Din stressnivå ligger dock på lägre nivå än i din jämförelsegrupp. Vid stress i arbetet handlar det om obalans mellan arbetets krav och känslan av kontroll. Vad är det som är den största belastningen för dig? Nu är det hög tid att stanna upp och fundera på hur du skulle kunna minska den.",
        },
        additional_information: {
          links: [
            {
              key: "hyvinvoinnin_abc-koulutus",
            },
            {
              key: "oivamieli-harjoitukset",
            },
          ],
          title: "Om stress ",
          text: "Arbetsbördan, arbetsarrangemangen, hur bindande arbetet är, brådska och förändringar i arbetet är exempel på faktorer som orsakar stress i arbetet. Andra faktorer som ökar arbetets psykiska belastning hos lantbruksföretagare är byråkratin i samband med verksamheten, administrativa uppgifter, ekonomisk osäkerhet, att man arbetar ensam, vädret och det säsongsbetonade arbetet. Vid stress i arbetet handlar det om obalans mellan arbetets krav och känslan av kontroll. Långvarig stress i arbetet har negativ inverkan på hälsan, välbefinnandet i arbetet och arbetssäkerheten på många olika sätt. Den ökar också risken för arbetsutmattning. Minnesstörningar, koncentrationssvårigheter och svårigheter att hantera sina känslor kan vara tecken på för mycket stress. Man kan kontrollera stressen genom att identifiera vilka faktorer som orsakar stress och göra något åt situationer som man själv kan påverka. Att vara företagare handlar ytterst om att leda sig själv, våga sätta mål, prioritera arbetsuppgifter samt om att disponera sin tid och ta hand om sig själv så att man orkar med arbetet. All stress är inte skadlig, utan stress kan också vara en positiv kraft som gör att man får mer till stånd och fungerar som en viktig motivationsfaktor. Det ger tillfredsställelse att lyckas i ett arbete som är lagom krävande.",
        },
      },
      bad: {
        short_feedback: "Ditt resultat väcker oro – du söker väl hjälp?",
        group_feedback: {
          good: "Din stressnivå är påtagligt förhöjd och klart högre än i din jämförelsegrupp. Känner du igen vilka faktorer som gör att du känner stress? Det är viktigt att genast ta tag i saken. Boka tid hos hälsovården eller ta kontakt med projektet Ta hand om bonden.",
          med: "Din stressnivå ligger på högre nivå än i din jämförelsegrupp. Känner du igen vilka faktorer som gör att du känner stress? Det är viktigt att genast ta tag i saken. Boka tid hos hälsovården eller ta kontakt med projektet Ta hand om bonden.",
          bad: "Din stressnivå ligger på samma nivå som i din jämförelsegrupp. Känner du igen vilka faktorer som gör att du känner stress? Det är viktigt att genast ta tag i saken. Boka tid hos hälsovården eller ta kontakt med projektet Ta hand om bonden.",
        },
        additional_information: {
          links: [
            {
              key: "terveydenhuollon_palvelut",
            },
            {
              key: "välitä_viljelijästä-projekti",
            },
            {
              key: "maaseudun_tukihenkilöverkko",
            },
            {
              key: "tyhy-neuvoja",
            },
          ],
          title: "Om stress",
          text: "Arbetsbördan, arbetsarrangemangen, hur bindande arbetet är, brådska och förändringar i arbetet är exempel på faktorer som orsakar stress i arbetet. Andra faktorer som ökar arbetets psykiska belastning hos lantbruksföretagare är byråkratin i samband med verksamheten, administrativa uppgifter, ekonomisk osäkerhet, att man arbetar ensam, vädret och det säsongsbetonade arbetet. Vid stress i arbetet handlar det om obalans mellan arbetets krav och känslan av kontroll. Långvarig stress i arbetet har negativ inverkan på hälsan, välbefinnandet i arbetet och arbetssäkerheten på många olika sätt. Den ökar också risken för arbetsutmattning. Minnesstörningar, koncentrationssvårigheter och svårigheter att hantera sina känslor kan vara tecken på för mycket stress. Man kan kontrollera stressen genom att identifiera vilka faktorer som orsakar stress och göra något åt situationer som man själv kan påverka. Att vara företagare handlar ytterst om att leda sig själv, våga sätta mål, prioritera arbetsuppgifter samt om att disponera sin tid och ta hand om sig själv så att man orkar med arbetet. All stress är inte skadlig, utan stress kan också vara en positiv kraft som gör att man får mer till stånd och fungerar som en viktig motivationsfaktor. Det ger tillfredsställelse att lyckas i ett arbete som är lagom krävande.",
        },
      },
    },
    tyky_6: {
      good: {
        short_feedback: "Bra, fortsätt i samma stil! ",
        group_feedback: {
          good: "Din psykiska belastning ligger på lika god nivå som i din jämförelsegrupp. Du har inga symtom som tyder på psykisk belastning. Vad finns det som särskilt hjälper dig att må bra?",
          med: "Din psykiska belastning ligger på lägre nivå än i din jämförelsegrupp. Du har inga symtom som tyder på psykisk belastning. Vad finns det som särskilt hjälper dig att må bra?",
          bad: "Din psykiska belastning ligger på klart lägre nivå än i din jämförelsegrupp. Du har inga symtom som tyder på psykisk belastning. Vad finns det som särskilt hjälper dig att må bra?",
        },
        additional_information: {
          links: [
            {
              key: "hyvinvointipäivät",
            },
            {
              key: "kuntoremonttikurssi",
            },
            {
              key: "hyvinvointivinkit",
            },
            {
              key: "webinaarit",
            },
          ],
          title: "Om psykisk belastning",
          text: "Ekonomisk osäkerhet, byråkratin i samband med arbetet, administrativa uppgifter samt stödövervakningen är exempel på faktorer som särskilt orsakar psykisk belastning hos lantbruksföretagare. En stor arbetsbörda ökar brådskan och minskar känslan av att ha arbetet under kontroll. Svåra relationer kan också bidra till att förvärra situationen. När symtomen som beror på psykisk belastning blir långvariga kan de leda till en störning på utmattningsnivå. Se till att du får tillräcklig återhämtning, sömn och vila, hälsosam mat, motion samt sociala relationer. Försök påverka det du kan och undvik att grubbla på sådant som du inte kan påverka.",
        },
      },
      med: {
        short_feedback:
          "Ditt resultat är oroväckande och något som du behöver vara uppmärksam på.",
        group_feedback: {
          good: "Din psykiska belastning ligger på högre nivå än i din jämförelsegrupp. Du har symtom som tyder på psykisk belastning. Kan du sätta namn på vad det är som orsakar psykisk belastning hos dig? Att söka hjälp redan i det här skedet är till nytta och ger möjlighet att hitta lösningsalternativ.",
          med: "Din psykiska belastning ligger på samma nivå som i din jämförelsegrupp. Du har symtom som tyder på psykisk belastning. Kan du sätta namn på vad det är som orsakar psykisk belastning hos dig? Att söka hjälp redan i det här skedet är till nytta och ger möjlighet att hitta lösningsalternativ.",
          bad: "Din psykiska belastning ligger ändå på lägre nivå än i din jämförelsegrupp. Du har symtom som tyder på psykisk belastning.  Kan du sätta namn på vad det är som orsakar psykisk belastning hos dig? Att söka hjälp redan i det här skedet är till nytta och ger möjlighet att hitta lösningsalternativ.",
        },
        additional_information: {
          links: [
            {
              key: "välitä_viljelijästä-projekti",
            },
            {
              key: "tyhy-neuvoja",
            },
            {
              key: "hyvinvoinnin_abc-koulutus",
            },
            {
              key: "mielenterveystalo",
            },
          ],
          title: "Om psykisk belastning",
          text: "Ekonomisk osäkerhet, byråkratin i samband med arbetet, administrativa uppgifter samt stödövervakningen är exempel på faktorer som särskilt orsakar psykisk belastning hos lantbruksföretagare. En stor arbetsbörda ökar brådskan och minskar känslan av att ha arbetet under kontroll. Svåra relationer kan också bidra till att förvärra situationen. När symtomen som beror på psykisk belastning blir långvariga kan de leda till en störning på utmattningsnivå. Se till att du får tillräcklig återhämtning, sömn och vila, hälsosam mat, motion samt sociala relationer. Försök påverka det du kan och undvik att grubbla på sådant som du inte kan påverka.",
        },
      },
      bad: {
        short_feedback: "Ditt resultat väcker oro – du söker väl hjälp?",
        group_feedback: {
          good: "Din psykiska belastning ligger på klart högre nivå än i din jämförelsegrupp. Du har många symtom som tyder på psykisk belastning. Det är viktigt att genast ta tag i saken och gå till botten med orsakerna till belastningen. Vad är det som belastar dig? Berätta om saken för dina närmaste och dra dig inte för att ta kontakt med projektet Ta hand om bonden eller med hälsovården.",
          med: "Din psykiska belastning ligger på högre nivå än i din jämförelsegrupp. Du har många symtom som tyder på psykisk belastning. Det är viktigt att genast ta tag i saken och gå till botten med orsakerna till belastningen. Vad är det som belastar dig? Berätta om saken för dina närmaste och dra dig inte för att ta kontakt med projektet Ta hand om bonden eller med hälsovården.",
          bad: "Din psykiska belastning ligger på samma nivå som i din jämförelsegrupp. Du har många symtom som tyder på psykisk belastning. Det är viktigt att genast ta tag i saken och gå till botten med orsakerna till belastningen. Vad är det som belastar dig? Berätta om saken för dina närmaste och dra dig inte för att ta kontakt med projektet Ta hand om bonden eller med hälsovården.",
        },
        additional_information: {
          links: [
            {
              key: "terveydenhuollon_palvelut",
            },
            {
              key: "välitä_viljelijästä-projekti",
            },
            {
              key: "mielenterveystalo",
            },
            {
              key: "tyhy-neuvoja",
            },
          ],
          title: "Om psykisk belastning",
          text: "Ekonomisk osäkerhet, byråkratin i samband med arbetet, administrativa uppgifter samt stödövervakningen är exempel på faktorer som särskilt orsakar psykisk belastning hos lantbruksföretagare. En stor arbetsbörda ökar brådskan och minskar känslan av att ha arbetet under kontroll. Svåra relationer kan också bidra till att förvärra situationen. När symtomen som beror på psykisk belastning blir långvariga kan de leda till en störning på utmattningsnivå. Se till att du får tillräcklig återhämtning, sömn och vila, hälsosam mat, motion samt sociala relationer. Försök påverka det du kan och undvik att grubbla på sådant som du inte kan påverka.",
        },
      },
    },
    tyky_7: {
      good: {
        short_feedback: "Bra, fortsätt i samma stil! ",
        group_feedback: {
          good: "Din bedömning av arbetsförmågan är på lika god nivå som i din jämförelsegrupp. Genom att ta hand om dig själv skapar du en positiv grund för hållbar arbetsförmåga och bra välbefinnande i arbetet.",
          med: "Din bedömning av arbetsförmågan är bättre än i din jämförelsegrupp. Genom att ta hand om dig själv skapar du en positiv grund för hållbar arbetsförmåga och bra välbefinnande i arbetet.",
          bad: "Din bedömning av arbetsförmågan är märkbart bättre än i din jämförelsegrupp. Genom att ta hand om dig själv skapar du en positiv grund för hållbar arbetsförmåga och bra välbefinnande i arbetet.",
        },
        additional_information: {
          links: [
            {
              key: "työturvallisuuskorttikoulutus"
            },
            {
              key: "tuetut_lomat",
            },
            {
              key: "kuntoremonttikurssi",
            },
            {
              key: "hyvinvointipäivät",
            },
            {
              key: "webinaarit",
            },
          ],
          title: "Om arbetsförmåga",
          text: "Hos lantbruksföretagaren är den upplevda arbetsförmågan i genomsnitt sämre än hos löntagare. Det fysiskt tunga arbetet och risken för olyckor påverkar framför allt det upplevda hälsotillståndet och därmed arbetsförmågan. Också ekonomiska faktorer och känslan av meningsfullhet i arbetet har samband med om man upplever sin arbetsförmåga positivt och har vilja att fortsätta med sitt nuvarande arbete. Arbetsförmågan kan försämras långsamt och nästan omärkligt, så att man vänjer sig vid situationen. Obalans mellan arbetets krav och arbetsförmågan kan till exempel uppstå på grund av förändringar i hälsotillståndet, förändringar i förhållandena, ökad psykisk belastning eller ökade kompetenskrav. Det är viktigt att sätta in korrigerande åtgärder i ett så tidigt skede som möjligt medan det fortfarande går att förbättra situationen på många sätt, till exempel genom rehabilitering. ",
        },
      },
      med: {
        short_feedback:
          "Ditt resultat är oroväckande och något som du behöver vara uppmärksam på.",
        group_feedback: {
          good: "Din bedömning av arbetsförmågan är lägre än i din jämförelsegrupp och det råder uppenbar obalans mellan arbetets krav och dina egna verksamhetsförutsättningar. Hur märks din nedsatta arbetsförmåga i ditt arbete och på vad inverkar den? Fundera på vad den nedsatta arbetsförmågan kan bero på och vad som försämrar den. Åtgärder som stöder arbetsförmågan borde sättas in redan i det här skedet. Dra dig inte för att ta kontakt med projektet Ta hand om bonden eller med hälsovården för att reda ut saken.",
          med: "Din bedömning av arbetsförmågan ligger på samma nivå som i din jämförelsegrupp och det råder uppenbar obalans mellan arbetets krav och dina egna verksamhetsförutsättningar. Hur märks din nedsatta arbetsförmåga i ditt arbete och på vad inverkar den? Fundera på vad den nedsatta arbetsförmågan kan bero på och vad som försämrar den. Åtgärder som stöder arbetsförmågan borde sättas in redan i det här skedet. Dra dig inte för att ta kontakt med projektet Ta hand om bonden eller med hälsovården för att reda ut saken.",
          bad: "Din bedömning av arbetsförmågan är ändå bättre än i din jämförelsegrupp, men det råder uppenbar obalans mellan arbetets krav och dina egna verksamhetsförutsättningar. Hur märks din nedsatta arbetsförmåga i ditt arbete och på vad inverkar den? Fundera på vad den nedsatta arbetsförmågan kan bero på och vad som försämrar den. Åtgärder som stöder arbetsförmågan borde sättas in redan i det här skedet. Dra dig inte för att ta kontakt med projektet Ta hand om bonden eller med hälsovården för att reda ut saken.",
        },
        additional_information: {
          links: [
            {
              key: "työturvallisuuskorttikoulutus"
            },
            {
              key: "terveydenhuollon_palvelut",
            },
            {
              key: "välitä_viljelijästä-projekti",
            },
            {
              key: "hyvinvointipäivät",
            },
            {
              key: "tyhy-neuvoja",
            },
          ],
          title: "Om arbetsförmåga",
          text: "Hos lantbruksföretagaren är den upplevda arbetsförmågan i genomsnitt sämre än hos löntagare. Det fysiskt tunga arbetet och risken för olyckor påverkar framför allt det upplevda hälsotillståndet och därmed arbetsförmågan. Också ekonomiska faktorer och känslan av meningsfullhet i arbetet har samband med om man upplever sin arbetsförmåga positivt och har vilja att fortsätta med sitt nuvarande arbete. Arbetsförmågan kan försämras långsamt och nästan omärkligt, så att man vänjer sig vid situationen. Obalans mellan arbetets krav och arbetsförmågan kan till exempel uppstå på grund av förändringar i hälsotillståndet, förändringar i förhållandena, ökad psykisk belastning eller ökade kompetenskrav. Det är viktigt att sätta in korrigerande åtgärder i ett så tidigt skede som möjligt medan det fortfarande går att förbättra situationen på många sätt, till exempel genom rehabilitering.",
        },
      },
      bad: {
        short_feedback: "Ditt resultat väcker oro – du söker väl hjälp?",
        group_feedback: {
          good: "Din bedömning av arbetsförmågan är klart lägre än i din jämförelsegrupp. Din arbetsförmåga är märkbart nedsatt och det verkar som om ditt arbete kräver mer än vad du rimligen har förutsättningar för. Utan korrigerande åtgärder kan situationen försvåras ytterligare. Du kan behöva stöd i detta, och stöd finns att få. Dra dig inte för att ta kontakt med projektet Ta hand om bonden eller med hälsovården. Något måste göras åt saken omedelbart.",
          med: "Din bedömning av arbetsförmågan är lägre än i din jämförelsegrupp. Din arbetsförmåga är märkbart nedsatt och det verkar som om ditt arbete kräver mer än vad du rimligen har förutsättningar för. Utan korrigerande åtgärder kan situationen försvåras ytterligare. Du kan behöva stöd i detta, och stöd finns att få. Dra dig inte för att ta kontakt med projektet Ta hand om bonden eller med hälsovården. Något måste göras åt saken omedelbart.",
          bad: "Din bedömning av arbetsförmågan ligger på samma nivå som i din jämförelsegrupp. Din arbetsförmåga är märkbart nedsatt och det verkar som om ditt arbete kräver mer än vad du rimligen har förutsättningar för. Utan korrigerande åtgärder kan situationen försvåras ytterligare. Du kan behöva stöd i detta, och stöd finns att få. Dra dig inte för att ta kontakt med projektet Ta hand om bonden eller med hälsovården. Något måste göras åt saken omedelbart.",
        },
        additional_information: {
          links: [
            {
              key: "terveydenhuollon_palvelut",
            },
            {
              key: "välitä_viljelijästä-projekti",
            },
            {
              key: "tyhy-neuvoja",
            },
          ],
          title: "Om arbetsförmåga",
          text: "Hos lantbruksföretagaren är den upplevda arbetsförmågan i genomsnitt sämre än hos löntagare. Det fysiskt tunga arbetet och risken för olyckor påverkar framför allt det upplevda hälsotillståndet och därmed arbetsförmågan. Också ekonomiska faktorer och känslan av meningsfullhet i arbetet har samband med om man upplever sin arbetsförmåga positivt och har vilja att fortsätta med sitt nuvarande arbete. Arbetsförmågan kan försämras långsamt och nästan omärkligt, så att man vänjer sig vid situationen. Obalans mellan arbetets krav och arbetsförmågan kan till exempel uppstå på grund av förändringar i hälsotillståndet, förändringar i förhållandena, ökad psykisk belastning eller ökade kompetenskrav. Det är viktigt att sätta in korrigerande åtgärder i ett så tidigt skede som möjligt medan det fortfarande går att förbättra situationen på många sätt, till exempel genom rehabilitering.",
        },
      },
    },
    tyky_8: {
      good: {
        short_feedback: "Bra, fortsätt i samma stil!",
        group_feedback: {
          good: "Din bedömning av arbetsförmågan med tanke på de fysiska krav som arbetet ställer ligger på samma nivå som i din jämförelsegrupp. Din arbetsförmåga motsvarar bra de fysiska krav som ditt arbete ställer och bidrar till att du klarar dina arbeten bra. Med stigande ålder är det ändå viktigt att tänka på att aktivt gå in för att bevara muskelstyrkan, uthålligheten, koordinationen och balansen samt hålla koll på vikten.",
          med: "Din bedömning av arbetsförmågan med tanke på de fysiska krav som arbetet ställer är bättre än i din jämförelsegrupp. Din arbetsförmåga motsvarar bra de fysiska krav som ditt arbete ställer och bidrar till att du klarar dina arbeten bra. Med stigande ålder är det ändå viktigt att tänka på att aktivt gå in för att bevara muskelstyrkan, uthålligheten, koordinationen och balansen samt hålla koll på vikten.",
          bad: "Din bedömning av arbetsförmågan med tanke på de fysiska krav som arbetet ställer är märkbart bättre än i din jämförelsegrupp. Din arbetsförmåga motsvarar bra de fysiska krav som ditt arbete ställer och bidrar till att du klarar dina arbeten bra. Med stigande ålder är det ändå viktigt att tänka på att aktivt gå in för att bevara muskelstyrkan, uthålligheten, koordinationen och balansen samt hålla koll på vikten.",
        },
        additional_information: {
          links: [
            {
              key: "työturvallisuuskorttikoulutus"
            },
            {
              key: "tuetut_lomat",
            },
            {
              key: "kuntoremonttikurssi",
            },
            {
              key: "hyvinvointipäivät",
            },
          ],
          title: "Om fysisk arbetsförmåga",
          text: "Inom lantbruket är den fysiska arbetsbelastningen särskilt stor på kreatursgårdar och i skogsarbete. Stöd- och rörelseorganen belastas i synnerhet av obekväma arbetsställningar och av lyftande och bärande, om hjälpmedel endast används i liten utsträckning. Mekaniseringen av arbetet har bidragit till att minska arbetsmomenten som kräver fysiskt arbete, men samtidigt har det sittande arbetet ökat, vilket på sikt också belastar stöd- och rörelseorganen. Situationen kan bedömas inom företagshälsovården eller på hälsovårdscentralen, så att man kan hitta rehabiliteringsalternativ som passar just dig. Ofta går det att med små medel förbättra arbetet och arbetsförhållandena så att de blir mindre belastande.  Att upprätthålla arbets- och funktionsförmågan kräver kontinuerliga insatser. Se till att du får tillräcklig återhämtning, sömn och vila, hälsosam mat, återställande motion samt relationer till andra människor.",
        },
      },
      med: {
        short_feedback:
          "Ditt resultat är oroväckande och något som du behöver vara uppmärksam på.",
        group_feedback: {
          good: "Din bedömning av arbetsförmågan med tanke på de fysiska krav som arbetet ställer är lägre än i din jämförelsegrupp. Det verkar som om din fysiska arbetsförmåga i viss mån är nedsatt i förhållande till de fysiska krav som ditt arbete ställer. Har du blivit tvungen att göra vissa arbetsuppgifter lättare eller märker du att det kan ta längre tid att återhämta sig efter tunga arbeten? Du både kan och ska göra något åt saken! Se till att bevara din muskelstyrka och uthållighet och håll koll på vikten. ",
          med: "Din bedömning av arbetsförmågan med tanke på de fysiska krav som arbetet ställer ligger på samma nivå som i din jämförelsegrupp. Det verkar som om din fysiska arbetsförmåga i viss mån är nedsatt i förhållande till de fysiska krav som ditt arbete ställer. Har du blivit tvungen att göra vissa arbetsuppgifter lättare eller märker du att det kan ta längre tid att återhämta sig efter tunga arbeten? Du både kan och ska göra något åt saken!  Se till att bevara din muskelstyrka och uthållighet och håll koll på vikten.",
          bad: "Din bedömning av arbetsförmågan med tanke på de fysiska krav som arbetet ställer är ändå bättre än i din jämförelsegrupp. Det verkar som om din fysiska arbetsförmåga i viss mån är nedsatt i förhållande till de fysiska krav som ditt arbete ställer. Har du blivit tvungen att göra vissa arbetsuppgifter lättare eller märker du att det kan ta längre tid att återhämta sig efter tunga arbeten? Du både kan och ska göra något åt saken! Se till att bevara din muskelstyrka och uthållighet och håll koll på vikten.",
        },
        additional_information: {
          links: [
            {
              key: "työturvallisuuskorttikoulutus"
            },
            {
              key: "terveydenhuollon_palvelut",
            },
            {
              key: "välitä_viljelijästä-projekti",
            },
            {
              key: "tyhy-neuvoja",
            },
          ],
          title: "Om fysisk arbetsförmåga",
          text: "Inom lantbruket är den fysiska arbetsbelastningen särskilt stor på kreatursgårdar och i skogsarbete. Stöd- och rörelseorganen belastas i synnerhet av obekväma arbetsställningar och av lyftande och bärande, om hjälpmedel endast används i liten utsträckning. Mekaniseringen av arbetet har bidragit till att minska arbetsmomenten som kräver fysiskt arbete, men samtidigt har det sittande arbetet ökat, vilket på sikt också belastar stöd- och rörelseorganen. Situationen kan bedömas inom företagshälsovården eller på hälsovårdscentralen, så att man kan hitta rehabiliteringsalternativ som passar just dig. Ofta går det att med små medel förbättra arbetet och arbetsförhållandena så att de blir mindre belastande.  Att upprätthålla arbets- och funktionsförmågan kräver kontinuerliga insatser. Se till att du får tillräcklig återhämtning, sömn och vila, hälsosam mat, återställande motion samt relationer till andra människor.",
        },
      },
      bad: {
        short_feedback: "Ditt resultat väcker oro – du söker väl hjälp?",
        group_feedback: {
          good: "Din bedömning av arbetsförmågan med tanke på de fysiska krav som arbetet ställer är klart lägre än i din jämförelsegrupp. Det verkar som om din fysiska arbetsförmåga inte motsvarar de fysiska krav som ditt arbete ställer. Har du blivit tvungen att helt lämna vissa arbetsuppgifter ogjorda, eller märker du att du inte längre återhämtar dig från belastningen i arbetet? Något måste göras åt saken omedelbart! Vänta inte på att situationen förvärras ytterligare, utan be om hjälp med att kartlägga möjligheterna att utveckla arbetet och arbetsförhållandena.",
          med: "Din bedömning av arbetsförmågan med tanke på de fysiska krav som arbetet ställer är lägre än i din jämförelsegrupp. Det verkar som om din fysiska arbetsförmåga inte motsvarar de fysiska krav som ditt arbete ställer. Har du blivit tvungen att helt lämna vissa arbetsuppgifter ogjorda, eller märker du att du inte längre återhämtar dig från belastningen i arbetet? Något måste göras åt saken omedelbart! Vänta inte på att situationen förvärras ytterligare, utan be om hjälp med att kartlägga möjligheterna att utveckla arbetet och arbetsförhållandena.",
          bad: "Din bedömning av arbetsförmågan med tanke på de fysiska krav som arbetet ställer ligger på samma nivå som i din jämförelsegrupp. Det verkar som om din fysiska arbetsförmåga inte motsvarar de fysiska krav som ditt arbete ställer. Har du blivit tvungen att helt lämna vissa arbetsuppgifter ogjorda, eller märker du att du inte längre återhämtar dig från belastningen i arbetet? Något måste göras åt saken omedelbart! Vänta inte på att situationen förvärras ytterligare, utan be om hjälp med att kartlägga möjligheterna att utveckla arbetet och arbetsförhållandena.",
        },
        additional_information: {
          links: [
            {
              key: "terveydenhuollon_palvelut",
            },
            {
              key: "välitä_viljelijästä-projekti",
            },
            {
              key: "tyhy-neuvoja",
            },
          ],
          title: "Om fysisk arbetsförmåga",
          text: "Inom lantbruket är den fysiska arbetsbelastningen särskilt stor på kreatursgårdar och i skogsarbete. Stöd- och rörelseorganen belastas i synnerhet av obekväma arbetsställningar och av lyftande och bärande, om hjälpmedel endast används i liten utsträckning. Mekaniseringen av arbetet har bidragit till att minska arbetsmomenten som kräver fysiskt arbete, men samtidigt har det sittande arbetet ökat, vilket på sikt också belastar stöd- och rörelseorganen. Situationen kan bedömas inom företagshälsovården eller på hälsovårdscentralen, så att man kan hitta rehabiliteringsalternativ som passar just dig. Ofta går det att med små medel förbättra arbetet och arbetsförhållandena så att de blir mindre belastande.  Att upprätthålla arbets- och funktionsförmågan kräver kontinuerliga insatser. Se till att du får tillräcklig återhämtning, sömn och vila, hälsosam mat, återställande motion samt relationer till andra människor.",
        },
      },
    },
    tyky_9: {
      very_good: {
        short_feedback: "Bra, fortsätt i samma stil! ",
        short_feedback_subtitle:
          "Du har inte råkat ut för olycksfall eller olyckstillbud i lantbruksarbetet. Undvik risker även i fortsättningen, du riskerar skador när du är trött och har bråttom. Det lönar sig att vara väl förbredd – hur kan du ytterligare satsa på att förutsäga situationer?",
        additional_information: {
          links: [
            {
              key: "työturvallisuuskorttikoulutus"
            },
            {
              key: "työturvallisuusneuvoja",
            },
            {
              key: "farmarin_suojaintutkinto",
            },
            {
              key: "työturvallisuusvideot",
            },
            {
              key: "maatilojen_varautumisen_työkalut",
            },
          ],
          title: "Om arbetssäkerhet",
          text: "Lantbruksföretagare råkar ut för klart fler olyckor än många andra yrkesgrupper. Det lönar sig att ta lärdom av olyckor och olyckstillbud och göra något åt de omständigheter som ledde till dem. Din egen inställning har avgörande betydelse för arbetssäkerheten. En olycka är summan av många delfaktorer. Det är bra att alltid göra en bredare och noggrannare genomgång av de faktorer som ledde till olyckan. Känner du till rutiner som fungerar bra hos andra lantbruksföretagare och på andra gårdar? Diskuterar du arbetssäkerhet med andra? Har du deltagit i utbildning om arbetssäkerhet? När en olycka eller ett olyckstillbud inträffat ska du alltid anteckna vad som ledde till situationen: vad som hände, när, vem som råkade ut för olyckan och varför. Fundera på hur du kan förhindra skador också i fortsättningen. Man kan alltid bli vis av skadan!",
        },
      },
      good: {
        short_feedback: "Bra, fortsätt i samma stil! ",
        group_feedback: {
          good: "Du har gjort ändringar eller din inställning har förändrats efter en olycka eller ett olyckstillbud lika ofta som din jämförelsegrupp. Det lönar sig att vara förberedd - skulle du kunna göra ännu mer för att förebygga olyckor?",
          med: "Du har gjort ändringar eller din inställning har förändrats efter en olycka eller ett olyckstillbud oftare än din jämförelsegrupp. Det lönar sig att vara förberedd - skulle du kunna göra ännu mer för att förebygga olyckor?",
          bad: "Du har gjort ändringar eller din inställning har förändrats efter en olycka eller ett olyckstillbud klart oftare än din jämförelsegrupp. Det lönar sig att vara förberedd - skulle du kunna göra ännu mer för att förebygga olyckor?",
        },
        additional_information: {
          links: [
            {
              key: "työturvallisuuskorttikoulutus"
            },
            {
              key: "työturvallisuusneuvoja",
            },
            {
              key: "farmarin_suojaintutkinto",
            },
            {
              key: "työturvallisuusvideot",
            },
            {
              key: "maatilojen_varautumisen_työkalut",
            },
          ],
          title: "Om arbetssäkerhet",
          text: "Lantbruksföretagare råkar ut för klart fler olyckor än många andra yrkesgrupper. Det lönar sig att ta lärdom av olyckor och olyckstillbud och göra något åt de omständigheter som ledde till dem. Din egen inställning har avgörande betydelse för arbetssäkerheten. En olycka är summan av många delfaktorer. Det är bra att alltid göra en bredare och noggrannare genomgång av de faktorer som ledde till olyckan. Känner du till rutiner som fungerar bra hos andra lantbruksföretagare och på andra gårdar? Diskuterar du arbetssäkerhet med andra? Har du deltagit i utbildning om arbetssäkerhet? När en olycka eller ett olyckstillbud inträffat ska du alltid anteckna vad som ledde till situationen: vad som hände, när, vem som råkade ut för olyckan och varför. Fundera på hur du kan förhindra skador också i fortsättningen. Man kan alltid bli vis av skadan!",
        },
      },
      med: {
        short_feedback:
          "Ditt resultat är oroväckande och något som du behöver vara uppmärksam på.",
        group_feedback: {
          good: "Du har gjort ändringar eller din inställning har förändrats efter en olycka eller ett olyckstillbud mer sällan än din jämförelsegrupp. Din egen inställning har avgörande betydelse för arbetssäkerheten. Vänta inte tills det händer något allvarligt! Vad skulle vara din första åtgärd för att förbättra arbetssäkerheten på din gård?",
          med: "Du har gjort ändringar eller din inställning har förändrats efter en olycka eller ett olyckstillbud lika ofta som din jämförelsegrupp. Din egen inställning har avgörande betydelse för arbetssäkerheten. Vänta inte tills det händer något allvarligt! Vad skulle vara din första åtgärd för att förbättra arbetssäkerheten på din gård?",
          bad: "Du har ändå gjort ändringar eller din inställning har förändrats efter en olycka eller ett olyckstillbud oftare än din jämförelsegrupp. Din egen inställning har avgörande betydelse för arbetssäkerheten. Vänta inte tills det händer något allvarligt! Vad skulle vara din första åtgärd för att förbättra arbetssäkerheten på din gård?",
        },
        additional_information: {
          links: [
            {
              key: "työturvallisuuskorttikoulutus"
            },
            {
              key: "työturvallisuusneuvoja",
            },
            {
              key: "farmarin_suojaintutkinto",
            },
            {
              key: "työterveyshuolto",
            },
            {
              key: "maatilojen_varautumisen_työkalut",
            },
          ],
          title: "Om arbetssäkerhet",
          text: "Lantbruksföretagare råkar ut för klart fler olyckor än många andra yrkesgrupper. Det lönar sig att ta lärdom av olyckor och olyckstillbud och göra något åt de omständigheter som ledde till dem. Din egen inställning har avgörande betydelse för arbetssäkerheten. En olycka är summan av många delfaktorer. Det är bra att alltid göra en bredare och noggrannare genomgång av de faktorer som ledde till olyckan. Känner du till rutiner som fungerar bra hos andra lantbruksföretagare och på andra gårdar? Diskuterar du arbetssäkerhet med andra? Har du deltagit i utbildning om arbetssäkerhet? När en olycka eller ett olyckstillbud inträffat ska du alltid anteckna vad som ledde till situationen: vad som hände, när, vem som råkade ut för olyckan och varför. Fundera på hur du kan förhindra skador också i fortsättningen. Man kan alltid bli vis av skadan!",
        },
      },
      bad: {
        short_feedback: "Ditt resultat väcker oro – du söker väl hjälp?",
        group_feedback: {
          good: "Du har gjort ändringar eller din inställning har förändrats efter en olycka eller ett olyckstillbud klart mer sällan än din jämförelsegrupp. Din egen inställning har avgörande betydelse för arbetssäkerheten. Vänta inte tills det händer något allvarligt! Vad skulle vara din första åtgärd för att förbättra arbetssäkerheten på din gård? Vill du veta mer om arbetssäkerhet?",
          med: "Du har gjort ändringar eller din inställning har förändrats efter en olycka eller ett olyckstillbud mer sällan än din jämförelsegrupp. Din egen inställning har avgörande betydelse för arbetssäkerheten. Vänta inte tills det händer något allvarligt! Vad skulle vara din första åtgärd för att förbättra arbetssäkerheten på din gård? Vill du veta mer om arbetssäkerhet?",
          bad: "Du har gjort ändringar eller din inställning har förändrats efter en olycka eller ett olyckstillbud lika sällan som din jämförelsegrupp. Din egen inställning har avgörande betydelse för arbetssäkerheten. Vänta inte tills det händer något allvarligt! Vad skulle vara din första åtgärd för att förbättra arbetssäkerheten på din gård? Vill du veta mer om arbetssäkerhet?",
        },
        additional_information: {
          links: [
            {
              key: "työturvallisuuskorttikoulutus"
            },
            {
              key: "maatilojen_varautumisen_työkalut",
            },
            {
              key: "työterveyshuolto",
            },
            {
              key: "työturvallisuusneuvoja",
            },
            {
              key: "farmarin_suojaintutkinto",
            },
          ],
          title: "Om arbetssäkerhet",
          text: "Lantbruksföretagare råkar ut för klart fler olyckor än många andra yrkesgrupper. Det lönar sig att ta lärdom av olyckor och olyckstillbud och göra något åt de omständigheter som ledde till dem. Din egen inställning har avgörande betydelse för arbetssäkerheten. En olycka är summan av många delfaktorer. Det är bra att alltid göra en bredare och noggrannare genomgång av de faktorer som ledde till olyckan. Känner du till rutiner som fungerar bra hos andra lantbruksföretagare och på andra gårdar? Diskuterar du arbetssäkerhet med andra? Har du deltagit i utbildning om arbetssäkerhet? När en olycka eller ett olyckstillbud inträffat ska du alltid anteckna vad som ledde till situationen: vad som hände, när, vem som råkade ut för olyckan och varför. Fundera på hur du kan förhindra skador också i fortsättningen. Man kan alltid bli vis av skadan!",
        },
      },
    },
    tyky_10: {
      good: {
        short_feedback: "Bra, fortsätt i samma stil! ",
        group_feedback: {
          good: "Din belastning som hänför sig till lantbruksverksamheten är för närvarande på lika god nivå som i din jämförelsegrupp. Fint! Om situationen förändras och belastningen ökar ska du inte dra dig för att ta kontakt med projektet Ta hand om bonden.",
          med: "Din belastning som hänför sig till lantbruksverksamheten är för närvarande mindre än i din jämförelsegrupp. Fint! Om situationen förändras och belastningen ökar ska du inte dra dig för att ta kontakt med projektet Ta hand om bonden.",
          bad: "Din belastning som hänför sig till lantbruksverksamheten är för närvarande klart mindre än i din jämförelsegrupp. Fint! Om situationen förändras och belastningen ökar ska du inte dra dig för att ta kontakt med projektet Ta hand om bonden.",
        },
        additional_information: {
          links: [
            {
              key: "välitä_viljelijästä-projekti",
            },
          ],
          title: "Om belastning som hänför sig till lantbruksverksamheten",
          text: "En lantbruksföretagares arbete är mångsidigt och kräver en hel del kunskap, planering, inlärning av nya saker och utredning av både ett och annat. Man jobbar ofta ensam och det finns inte alltid någon annan person som man kan ha som bollplank när man ska fatta beslut. Man behöver ändå inte klara sig ensam; genom att prata med en utomstående sakkunnig går det att skapa klarhet också i lite knepigare situationer.  Om man oroar sig för något som har med arbetet att göra återspeglar det sig lätt på orken och de sociala relationerna. Den anställda i projektet Ta hand om bonden hjälper dig att fundera ut lösningar också på svårare situationer. Det lönar sig att ta kontakt redan i ett tidigt skede, då är det lättare att få klarhet i situationen. Det finns hjälp att få!",
        },
      },
      med: {
        short_feedback:
          "Ditt resultat är oroväckande och något som du behöver vara uppmärksam på.",
        group_feedback: {
          good: "Din belastning som hänför sig till lantbruksverksamheten är för närvarande större än i din jämförelsegrupp. Hur skulle du kunna minska belastningen? Det lönar sig att be om hjälp redan i ett tidigt skede. Ta kontakt med projektet Ta hand om bonden. Via det får du kostnadsfri och konfidentiell hjälp med låg tröskel.",
          med: "Din belastning som hänför sig till lantbruksverksamheten är för närvarande på samma nivå som i din jämförelsegrupp. Hur skulle du kunna minska belastningen? Det lönar sig att be om hjälp redan i ett tidigt skede. Ta kontakt med projektet Ta hand om bonden. Via det får du kostnadsfri och konfidentiell hjälp med låg tröskel.",
          bad: "Din belastning som hänför sig till lantbruksverksamheten är för närvarande ändå mindre än i din jämförelsegrupp. Hur skulle du kunna minska belastningen? Det lönar sig att be om hjälp redan i ett tidigt skede. Ta kontakt med projektet Ta hand om bonden. Via det får du kostnadsfri och konfidentiell hjälp med låg tröskel.",
        },
        additional_information: {
          links: [
            {
              key: "välitä_viljelijästä-projekti",
            },
          ],
          title: "Om belastning som hänför sig till lantbruksverksamheten",
          text: "En lantbruksföretagares arbete är mångsidigt och kräver en hel del kunskap, planering, inlärning av nya saker och utredning av både ett och annat. Man jobbar ofta ensam och det finns inte alltid någon annan person som man kan ha som bollplank när man ska fatta beslut. Man behöver ändå inte klara sig ensam; genom att prata med en utomstående sakkunnig går det att skapa klarhet också i lite knepigare situationer.  Om man oroar sig för något som har med arbetet att göra återspeglar det sig lätt på orken och de sociala relationerna. Den anställda i projektet Ta hand om bonden hjälper dig att fundera ut lösningar också på svårare situationer. Det lönar sig att ta kontakt redan i ett tidigt skede, då är det lättare att få klarhet i situationen. Det finns hjälp att få!",
        },
      },
      bad: {
        short_feedback: "Ditt resultat väcker oro – du söker väl hjälp?",
        group_feedback: {
          good: "Din belastning som hänför sig till lantbruksverksamheten är för närvarande klart större än i din jämförelsegrupp. Hur skulle du kunna minska belastningen? Be om hjälp och ta kontakt med projektet Ta hand om bonden. Via det får du konfidentiell och kostnadsfri hjälp med låg tröskel. ",
          med: "Din belastning som hänför sig till lantbruksverksamheten är för närvarande större än i din jämförelsegrupp. Hur skulle du kunna minska belastningen? Be om hjälp och ta kontakt med projektet Ta hand om bonden. Via det får du konfidentiell och kostnadsfri hjälp med låg tröskel.",
          bad: "Din belastning som hänför sig till lantbruksverksamheten är för närvarande på samma nivå som i din jämförelsegrupp. Hur skulle du kunna minska belastningen? Be om hjälp och ta kontakt med projektet Ta hand om bonden. Via det får du konfidentiell och kostnadsfri hjälp med låg tröskel.",
        },
        additional_information: {
          links: [
            {
              key: "välitä_viljelijästä-projekti",
            },
          ],
          title: "Om belastning som hänför sig till lantbruksverksamheten",
          text: "En lantbruksföretagares arbete är mångsidigt och kräver en hel del kunskap, planering, inlärning av nya saker och utredning av både ett och annat. Man jobbar ofta ensam och det finns inte alltid någon annan person som man kan ha som bollplank när man ska fatta beslut. Man behöver ändå inte klara sig ensam; genom att prata med en utomstående sakkunnig går det att skapa klarhet också i lite knepigare situationer.  Om man oroar sig för något som har med arbetet att göra återspeglar det sig lätt på orken och de sociala relationerna. Den anställda i projektet Ta hand om bonden hjälper dig att fundera ut lösningar också på svårare situationer. Det lönar sig att ta kontakt redan i ett tidigt skede, då är det lättare att få klarhet i situationen. Det finns hjälp att få!",
        },
      },
    },
  },
};
