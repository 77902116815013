import React, { Component } from 'react'
import I18n from 'i18n-js';
import jQuery from 'jquery';
import './Modal.scss';
import Select from 'react-select';
export default class Modal extends Component {

    constructor(props)
    {
        super(props);
        this.state = {
            show: false
        }
    };

    renderModal(content = null, closeButton = true) {
        let {show} = this.state;
        return (<div className="dont-print d-inline-block ms-2">
            {<div className={"modal modal_group"+ (show?" show":"")}>
                { closeButton && <div className="float-right" style={{cursor: "pointer", textDecoration:"none", marginTop: "-6px"}} onClick={this.toggleShow}>x</div>}
                {content}
            </div>}
        </div>)
    }

    render(){
        return <>
        <a onClick={this.toggleShow} className="" style={{cursor: "pointer", textDecoration:"none"}}>{this.props.toggleButton}</a>
        {this.renderModal(this.props.children)}
            </>
    }

    toggleShow =() =>{
        this.setState(prevState => {
            return {show: !prevState.show, error: false, loading: false, selected: this.props.group_properties}
        })
    }

}