import I18n from 'i18n-js';
import { Redirect } from 'react-router';

import '../stylesheets/Form.scss';
import jQuery from 'jquery';
import PersistentComponent from '../components/PersistentComponent';

import ProgressIndicator from '../components/ProgressIndicator';

import { questions } from "../config/questions";
import QuestionForm from '../components/QuestionForm';
import MaintenanceAlert, { handleMaintenanceException } from '../components/MaintenanceAlert';

class Form extends PersistentComponent {


  constructor(props) {
    super(props);
    this.savename = "f"
    this.state = {
      submitting: false,
      cleared: false,
      error: false,
      accessError: false,
      submitted: "submitted" in this.state ? this.state.submitted : false,
      approval: false,
      redirect: undefined,
    }
    this.loadState();
  }



  render() {
    let { submitting, cleared, redirect, unanswered, error, submitted, accessError } = this.state;
    let { accessToken, answers } = this.props;
    if (!accessToken || accessError) {
      return <Redirect to='/' />;
    } else if (submitted) {
      return <Redirect to='/results' />;
    } else if (redirect) {
      return <Redirect to={`${redirect}`}/>
    } return (
      <div className="form-wrapper container container-small pe-5 pe-md-0">
        {!this.state.maintenanceEnabled && <ProgressIndicator questions={questions} answers={answers} isQuestionAnswered={this.isQuestionAnswered} />}
        <h3 className="mb-3 mela-blue" style={{ fontWeight: "800" }}>{I18n.t("form.title")}</h3>
        <p className="mb-4">{I18n.t("form.description")}</p>

        {this.state.maintenanceEnabled ? <MaintenanceAlert maintenanceEstimatedEnd={this.state.maintenanceEstimatedEnd}/> : <>
          {/* Cleared alert */}
          <div className="alert alert-success" style={{ "visibility": cleared ? "visible" : "hidden", "height": cleared ? "" : "0", "padding": cleared ? "" : "0", "overflow": "hidden" }} role="alert" id="cleared-alert-top">
            {cleared ? I18n.t("form.cleared_alert") : ""}
          </div>
          {/* Unanswered alert */}
          <div className="alert alert-danger" style={{ "visibility": unanswered ? "visible" : "hidden", "height": unanswered ? "" : "0", "padding": unanswered ? "" : "0", "overflow": "hidden" }} role="alert" id="unanswered-alert-top">
            {unanswered ? I18n.t("errors.unanswered") : ""}
          </div>
          {Object.keys(questions).map((key, i) => {
            let question = questions[key];
            let unanswered_border = question["unanswered"]
            return question['show_only_when'] && answers[question['show_only_when']['question']] != question['show_only_when']['answer'] ? null : 
            <div className={"form-question mb-4" + (unanswered_border ? " unanswered-question" : "")} id={"form-question-" + (question['id'])}>
              <QuestionForm answers={answers} question={question} i={i} handleCheckChange={this.handleCheckChange} handleProductionLineChange={this.handleProductionLineChange} />
            </div>
          })}

          <div><input className="approval-input" type="checkbox" id="approvalCheckbox" value={this.state.approval} onChange={this.handleApproval}/></div>

          {/* Unanswered alert */}
          {unanswered && <div className="alert alert-danger" role="alert">
            {I18n.t("errors.unanswered")}
          </div>}

          {/* Error alert */}
          {error && <div className="alert alert-danger" role="alert">
            {I18n.t("errors.retry")}
          </div>}

          <div className="row">
            <div className="col-sm-12 mb-4 p-4">
              <div className="col-xs-12">
                {/* Form submission */}
                <a className={"btn btn-primary float-sm-end ms-sm-4 w-xs-only-100 mb-2" + ((!submitting && !error) ? "" : " disabled")} onClick={this.submitAnswers}>
                  {I18n.t("form.answer")}
                  {submitting && <div className="spinner-border spinner-border-sm ms-2" role="status">
                    <span className="visually-hidden">{I18n.t("loading")}...</span>
                  </div>}
                </a>
              </div>
              <div className="col-xs-12">
                {/* Clear Answers */}
                <a className="btn mela-blue float-sm-end w-xs-only-100" onClick={this.clearAnswers}>{I18n.t("form.clear")}</a>
              </div>
            </div>
        </div>
        </>}

      </div>
    );
  }

  toggleTooltip = () => {

  }

  handleApproval = () => {
    this.setState({
      approval: true
    })
  }

  warmup = (initial_call = false) => {
    jQuery.post(process.env.REACT_APP_PROXY + "submit_form", {
      warmup: true,
      initial_call: initial_call
    }).catch((e) => {
      handleMaintenanceException(e, this);
    })
  }

  componentDidMount() {
    if (this.props.match){
      jQuery.post(process.env.REACT_APP_PROXY+"check_retake_link", {
        retake_id: this.props.match.params.uuid
      }).catch( (e) =>{
        if(!handleMaintenanceException(e, this)){
          this.setState({error: true, loading: false});
          alert(I18n.t("home.not_found"))
        }
      })
    }
    this.warmup(true);
    let interval = setInterval(this.warmup, 300000);
    jQuery(this.root).on('click', '.form-check', this.handleCheckChange);
  }

  handleCheckChange = (event) => {
    let question_id = event.target.name
    let value = event.target.value

    if (event.target.type == 'checkbox')
    value = event.target.checked ? "true" : "false"

    questions[question_id.split("__")[0]]["unanswered"] = false;
    // Special case for tyky_9_0
    if(question_id === 'tyky_9_0'){
      questions['tyky_9_0']["unanswered"] = false;
    }
    this.setState((prevState) => {
      let answers = { ...this.props.answers };
      // Set non_farmer to false by default
      if (answers["tyky_11__non_farmer"] === undefined){
        answers["tyky_11__non_farmer"] = false
      }

      // Table option selected
      if (question_id.includes("__")) {
        // Unselect I-dont-want-to-answer
        delete answers[question_id.split("__")[0]];
      }

      // Dont want to answer chosen
      if (value == -1 || value == "-1") {
        // Unselect all table options if available
        let re = new RegExp(question_id + ':*');
        Object.keys(answers).forEach((k, i) => {
          if (re.test(k)) {
            delete answers[k];
          }
        })
      }

      // Set selected value
      answers[question_id] = value;
      // Special case for tyky_9_0
      if(question_id === 'tyky_9_0'){
        delete answers['tyky_9'];
      }
      this.props.setAnswers(answers);
      return { unanswered: false, cleared: false }
    })
    // Use setState for:
    // answers[question_id] = value;
  }

  handleProductionLineChange = (key, value) => {
    this.setState((prevState) => {
      questions[key.split("__")[0]]["unanswered"] = false;
      let answers = { ...this.props.answers };
      answers[key] = value;
      this.props.setAnswers(answers);
      return { unanswered: false }
    })
  }

  clearAnswers = () => {
    if (window.confirm(I18n.t("form.confirm_clear"))) {
      this.props.setAnswers({})
      Object.keys(questions).forEach(q => {
        questions[q.split("__")[0]]["unanswered"] = false;
      })
      this.setState({ cleared: true, unanswered: false })
      //document.getElementById("cleared-alert-top").scrollIntoView();
      document.getElementsByClassName("site-header")[0].scrollIntoView();
    }
  }

  isQuestionAnswered = (question_key) => {
    let { answers } = this.props;

    let q = questions[question_key];
    let result = true;
    // Check normal-type (scale, checkbox...) question
    if (q.type != "table" && q.type != "user_profile") {
      if (!answers[q.id]) result = false;
    }
    // Check Table question
    else if (q.type == "table") {
      // Check all options have selection
      let all_selected = true;
      q.options.forEach((opt, k) => {
        if (!answers[q.id + "__" + opt.id]) all_selected = false;
      })
      // Dont answer selected counts as selection
      if (!all_selected && answers[q.id] != -1 && answers[q.id] != "-1") result = false;
    }
    // Check User profile question
    else if (q.type == "user_profile") {
      if (!answers[q.id + "__age"] || !answers[q.id + "__sex"] || (answers[q.id + "__non_farmer"] !== "true" && !answers[q.id + "__production_line"])) result = false;
    }
    return result
  }

  allQuestionsAnswered = () => {
    let { answers } = this.props;

    let result = true;
    let scrollTo = null;
    Object.keys(questions).forEach((k, i) => {
      let dependantQuestionHidden = questions[k]['show_only_when'] && answers[questions[k]['show_only_when']['question']] != questions[k]['show_only_when']['answer'];
      if (!this.isQuestionAnswered(k) && !dependantQuestionHidden) {
        result = false;
        questions[k]["unanswered"] = true;
        if (scrollTo == null) {
          scrollTo = "form-question-" + (questions[k]['id']).toString()
        }
      } else {
        questions[k]["unanswered"] = false;
      }
    })

    return result;
  }

  submitAnswers = () => {
    if (!this.allQuestionsAnswered()) {
      document.getElementsByClassName("site-header")[0].scrollIntoView();
      this.setState({ unanswered: true, cleared: false })
      return;
    }


    this.setState({ submitting: true, unanswered: false, error: false }, () => {
      let answers = this.state.answers
      jQuery.post(process.env.REACT_APP_PROXY + "submit_form", {
        answers: this.props.answers, 
        jwt: this.props.accessToken,
        approval: this.state.approval,
        language: I18n.currentLocale(),
        retake_id: this.props.match && this.props.match.params.uuid
      }).then((res) => {
        if (res.redirect_to){
          this.setState({ submitting: false, error: false, submitted: false, redirect: res.redirect_to});
        } else {
          this.props.setEvaluation(res.evaluation, () => {
            this.props.setRetakeID(res.retake_id,()=>{})
            this.props.setPreviousEvaluations(res.previous_evaluations, ()=>{
              this.props.setToken(res.jwt, () => {
                this.setState({ submitting: false, error: false, submitted: true });
              });
            })
          });          
        }

      }).catch(e => {
        console.log(e.statusText)
        if (e.status == 401) {
          this.setState({ accessError: true })
        } else if (!handleMaintenanceException(e, this)) {
          this.setState({ submitting: false, error: true });
        }
      })
    })

  }
}

export default Form;
