import React, { Component } from "react";
import "./ProgressIndicator.scss";
export default class ProgressIndicator extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let { questions, answers, isQuestionAnswered } = this.props;

    let b_height = 92 / Object.keys(questions).length + "vh";

    let h_style = { height: b_height, lineHeight: b_height, display: "block" };
    return (
      <div className="progress_indicator_wrapper">
        {
          // Own user tooltip
          Object.keys(questions).map((id, i) => {
            let question = questions[id];
            if (!question["form_number"]) return null;
            let question_h_id = "#form-question-" + question["id"];
            let dependantQuestionHidden = question["show_only_when"] && answers[question["show_only_when"]["question"]] != question["show_only_when"]["answer"];
            if (dependantQuestionHidden) return null;
            let questionsDependingOnThis = Object.values(questions).filter(q => q["show_only_when"] && q["show_only_when"]["question"] == id);
            let someDependantQuestionUnsanswered = questionsDependingOnThis.some(q => q["show_only_when"]["answer"] == answers[id] && answers[q["id"]] === undefined);
            if (isQuestionAnswered(question.id) && !someDependantQuestionUnsanswered) {
              return (
                <div
                  className={"progress_step answered"}
                  key={question["id"]}
                  style={h_style}
                >
                  <a href={question_h_id}>{question["form_number"]}</a>
                </div>
              );
            } else
              return (
                <div
                  className={"progress_step"}
                  key={question["id"]}
                  style={h_style}
                >
                  <a href={question_h_id}>{question["form_number"]}</a>
                </div>
              );
          })
        }
      </div>
    );
  }
}
