import React, { Component } from 'react'
import I18n from 'i18n-js';
import jQuery from 'jquery';
import './ShareButton.scss';
import { BsCheck, BsCheckCircle, BsCheckLg, BsBookmark, BsBookmarks, BsBookmarkCheck, BsExclamationTriangle } from 'react-icons/bs';
import { NOTICE_PERIOD } from '../views/Results';
export default class RetakeButton extends Component {

    constructor(props)
    {
        super(props);
        this.state = {
            loading: false,
            retakeID: null,
            error: false,
            accessError: false,
            shareUUID: null
        }
    };

    render() {
        let {loading, error} = this.state;
        return (
            <div className='row'>
                    <button className={"btn btn-primary mb-2" +(loading?" disabled":"")} onClick={this.onClickIcalendar} id='icalendar-btn'>            
                    {!error ? I18n.t("results.download_calendar") : I18n.t("errors.retry")} {!loading && <BsBookmark className="mt-1 me-1 float-right"/>}
                        {loading && !error && <div className="spinner-border spinner-border-sm ms-2" role="status">
                            <span className="visually-hidden">{I18n.t("loading")}...</span>
                        </div>}
                    </button>
          </div>
        )
        
    }

    createDownloadICSFile = (startTime, link) => {
        // const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
        const timezone = 'Europe/Helsinki'
        let endTime = startTime
        endTime.setMonth(startTime.getMonth() + NOTICE_PERIOD)
        const title = I18n.t("email.title")
        const description =  `${I18n.t("email.description", {'link': link}).replaceAll(',','\\,').replaceAll(';', '\\;')}`
        
        const icsBody = 'BEGIN:VCALENDAR\r\n' +
        'VERSION:2.0\r\n' +
        'PRODID:Calendar\r\n' +
        'CALSCALE:GREGORIAN\r\n' +
        'METHOD:PUBLISH\r\n' +
        'BEGIN:VTIMEZONE\r\n' +
        'TZID:' + timezone + '\r\n' +
        'BEGIN:DAYLIGHT\r\n' +
        'TZNAME:EEST\r\n' +
        'TZOFFSETFROM:+0200\r\n' +
        'TZOFFSETTO:+0300\r\n' +
        'DTSTART:19700329T030000\r\n' +
        'RRULE:FREQ=YEARLY;BYMONTH=3;BYDAY=-1SU\r\n' +
        'END:DAYLIGHT\r\n' +
        'BEGIN:STANDARD\r\n' +
        'TZNAME:EET\r\n' +
        'TZOFFSETFROM:+0300\r\n' +
        'TZOFFSETTO:+0200\r\n' +
        'DTSTART:19701025T040000\r\n' +
        'RRULE:FREQ=YEARLY;BYMONTH=10;BYDAY=-1SU\r\n' +
        'END:STANDARD\r\n' +
        'END:VTIMEZONE\r\n' +
        'BEGIN:VEVENT\r\n' +
        'SUMMARY:' + title + '\r\n' +
        'UID:@Default\r\n' +
        'SEQUENCE:0\r\n' +
        'STATUS:CONFIRMED\r\n' +
        'TRANSP:TRANSPARENT\r\n' +
        'DTSTART;TZID=' + timezone + ':' + this.convertToICSDate(startTime) + '\r\n' +
        'DTEND;TZID=' + timezone + ':' + this.convertToICSDate(endTime)+ '\r\n' +
        'DTSTAMP:'+ this.convertToICSDate(new Date()) + '\r\n' +
        'DESCRIPTION:' + description + '\r\n' +
        'END:VEVENT\r\n' +
        'END:VCALENDAR\r\n';
        this.downloadICS(title + '.ics', icsBody);
      }
    
    convertToICSDate = (dateTime) => {
        const year = dateTime.getFullYear().toString();
        const month = (dateTime.getMonth() + 1) < 10 ? "0" + (dateTime.getMonth() + 1).toString() : (dateTime.getMonth() + 1).toString();
        const day = dateTime.getDate() < 10 ? "0" + dateTime.getDate().toString() : dateTime.getDate().toString();
        const hours = dateTime.getHours() < 10 ? "0" + dateTime.getHours().toString() : dateTime.getHours().toString();
        const minutes = dateTime.getMinutes() < 10 ? "0" +dateTime.getMinutes().toString() : dateTime.getMinutes().toString();

        return year + month + day + "T" + hours + minutes + "00";
    }

    downloadICS = (filename, fileBody) => {
        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(fileBody));
        element.setAttribute('download', filename);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    }

    onClickIcalendar = (e) => {
        if (!this.props.preSharedUUID){
            this.shareResult(this.getRetakeLink)
        } else {
            this.getRetakeLink()
        }

    }

    getRetakeLink = () => {
        let req_params = {jwt: this.props.accessToken, uuid: this.props.preSharedUUID || this.state.shareUUID}
        this.setState({error: false, loading: true}, () =>{
          jQuery.post(process.env.REACT_APP_PROXY+"get_retake_link", req_params).then(resp =>{
              if(resp.ok){
                  this.setState({error: false, loading:false, retakeID: resp.retake_id}, () => {
                      const retakeLink = process.env.REACT_APP_FRONTEND_URL + "/results/" + this.state.retakeID + '/r';
                      this.createDownloadICSFile(new Date(this.props.results_date), retakeLink)
                  })
              }else{
                  this.setState({error: true})
              }
          }).catch(e =>{
              console.log(e.statusText)
              if(e.status == 401){
                  this.setState({accessError: true})
              }else{
                  this.setState({error: true})
              }
          })
        })
    }

    getShareLink = (domain = true) =>{
        let {shareUUID} = this.state;
        let {preSharedUUID} = this.props;

       return (domain? process.env.REACT_APP_FRONTEND_URL : "") +"/results/"+(preSharedUUID? preSharedUUID : shareUUID);
    }

    shareResult = (callback) =>{
        let req_params = {jwt: this.props.accessToken}
        this.setState({error: false, loading: true}, () =>{
            jQuery.post(process.env.REACT_APP_PROXY+"get_share_link", req_params).then(resp =>{
                if(resp.ok){
                    this.setState({error: false, shareUUID: resp.uuid}, () => {
                        callback();
                        window.history.replaceState(null, null, this.getShareLink(false));
                    })
                }else{
                    this.setState({error: true})
                }
            }).catch(e =>{
                //console.log(e.statusText)
                if(e.status == 401){
                    this.setState({accessError: true})
                }else{
                    this.setState({error: true})
                }
            })
        })
    }

}