import React, { Component } from 'react'

export default class PersistentComponent extends Component {

    constructor(props)
    {
        // Calling the constructor of
        // Parent Class React.Component
        super(props);
        this.state = {
        }
    };

    loadState = () => {
        const serializedState = localStorage.getItem('state_' + this.savename);
        if(serializedState === null) {
            return undefined;
        }
        try {
            const decodedState = atob(serializedState)
            this.state = JSON.parse(decodedState);
        } catch (error) {
            try {
                this.state = JSON.parse(serializedState);
            } catch (error) {
                console.error(`Could not load persistent ${this.savename} component state.`)
            }
        }
    };
  
    saveState = (state) => {
        const serializedState = JSON.stringify(this.state);
        try {
            const encodedState = btoa(serializedState)
            localStorage.setItem('state_' + this.savename, encodedState);
        } catch (error) {
            console.error(`Could not save persistent component ${this.savename} state.`)
        }
    };
    
    deleteState = () => {
        localStorage.removeItem('state_' + this.savename);
    };
    
    componentDidUpdate = () => {
        this.saveState();
    }
}