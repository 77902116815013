import React, { Component } from 'react'
import I18n from 'i18n-js';
import jQuery from 'jquery';
import './ShareButton.scss';
import { BsCheck, BsCheckCircle, BsCheckLg, BsBookmark, BsBookmarks, BsBookmarkCheck, BsExclamationTriangle } from 'react-icons/bs';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Bookmark from 'react-bookmark';
import  { Redirect } from 'react-router-dom'

export default class ShareButton extends Component {

    constructor(props)
    {
        super(props);
        this.state = {
            show: false,
            loading: false,
            shareUUID: null,
            copied: false,
            error: false
        }
    };

    render() {
        let {show, shareUUID, loading, error, copied} = this.state;
        let {accessToken, preSharedUUID, width, popupDisabled, ...rest} = this.props;

        let shareLink = this.getShareLink();

        return (
            <>
            <button className={"btn-share dont-print btn btn-primary mb-4 mb-md-2 col-12 text-start"+(loading?" disabled":"")}  style={{width: width}} {...rest}>
                {!error ? I18n.t("results.share") : I18n.t("errors.retry")} {!loading && <BsBookmark className="mt-1 me-1 float-right"/>}
                {loading && !error && <div className="spinner-border spinner-border-sm ms-2" role="status">
                    <span className="visually-hidden">{I18n.t("loading")}...</span>
                </div>}
            </button>
            {!popupDisabled && <div className={"popup share-popup"+ (show?" show":"")}>
                <div className="btn btn-transparent close-btn float-right" style={{margin: "-1rem"}} onClick={() => this.toggleShow()}>
                    {"x"}
                </div>

                <strong><h5 style={{fontWeight: "600"}}>{I18n.t("results.share_title")}</h5></strong>
                <p className="mt-2" htmlFor="share_link_input">{I18n.t("results.share_link")}</p>
                <input className="form-control" type="text" id="share_link_input" name="share_link" readOnly={true} value={shareLink}>
                </input>
                <br/>


                {error && <div className="alert alert-danger" role="alert">
                {I18n.t("errors.retry")}
                </div>}

                <div className="close-btn btn btn-secondary" onClick={() => this.toggleShow()}>
                    {I18n.t("close")}
                </div>

                <CopyToClipboard text={shareLink}
                onCopy={() => this.setState({copied: true})}>
                <div className="copy-btn btn btn-primary float-right">
                    {I18n.t(copied?"copied":"copy")}
                    {copied && <BsCheckLg className="ms-2" style={{color:"green"}} />}
                </div>
                </CopyToClipboard>
                {/* <Bookmark locale={I18n.locale} className="btn btn-secondary float-right me-2" href={this.getShareLink(false)} title={"Mela Työkykymittari"} >
                </Bookmark> */}
            </div>}
            </>
        )
        
    }
    
    toggleShow =() =>{
        let changeShow = () => {this.setState(prevState => {
            return {show: !prevState.show, error: false, loading: false, copied: false}
        })}
        let {preSharedUUID} = this.props;
        let {shareUUID} = this.state
        if(!preSharedUUID && !shareUUID){
            this.shareResult(changeShow)
        }else{
            changeShow();
        }
    }

    componentDidMount = () =>{
        jQuery(window).click((event) => {
            //Hide the menus if visible
            if(this.state.show && !event.target.classList.contains("copy-btn"))
            this.toggleShow();
        })
        jQuery('.btn-share').click((event) => {
            this.toggleShow();
            event.stopPropagation();
        })
        jQuery('.popup').click((event) => {
            if(!event.target.classList.contains("copy-btn") && !event.target.classList.contains("close-btn"))
                event.stopPropagation();
        })
    }

    getShareLink = (domain = true) =>{
        let {shareUUID} = this.state;
        let {preSharedUUID} = this.props;

       return (domain? process.env.REACT_APP_FRONTEND_URL : "") +"/results/"+(preSharedUUID? preSharedUUID : shareUUID);
    }

    shareResult = (callback) =>{
        let req_params = {jwt: this.props.accessToken}
        this.setState({error: false, loading: true}, () =>{
            jQuery.post(process.env.REACT_APP_PROXY+"get_share_link", req_params).then(resp =>{
                if(resp.ok){
                    this.setState({error: false, shareUUID: resp.uuid}, () => {
                        callback();
                        window.history.replaceState(null, null, this.getShareLink(false));
                    })
                }else{
                    this.setState({error: true})
                }
            }).catch(e =>{
                //console.log(e.statusText)
                if(e.status == 401){
                    this.setState({accessError: true})
                }else{
                    this.setState({error: true})
                }
            })
        })
    }

}