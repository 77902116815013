export const questions = {
    "tyky_1":{id: "tyky_1", form_number: '1', type: "options_check", eval_type: "scale_5", title: 
      {fi: "Kuinka tyytyväinen olet nykyiseen työhösi maatalousyrittäjänä?",
       sv: "Hur nöjd är du med ditt nuvarande arbete som lantbruksföretagare?"}, 
       results_title: 
       {fi: "Työtyytyväisyys", sv: "Arbetstillfredsställelse"}
       , options: [
         {id: 1, fi: "Erittäin tyytyväinen", sv: "Mycket nöjd"},
         {id: 2, fi: "Melko tyytyväinen", sv: "Ganska nöjd"},
         {id: 3, fi: "Ei tyytyväinen, mutta ei tyytymätönkään", sv: "Varken nöjd eller missnöjd"},
         {id: 4, fi: "Melko tyytymätön", sv: "Ganska missnöjd"},
         {id: 5, fi: "Erittäin tyytymätön", sv: "Mycket missnöjd"},
         ]
    },
    "tyky_2":{id: "tyky_2", form_number: '2', type: "options_check", eval_type: "scale_4", title: 
      {fi: "Ehdin palautua riittävästi maataloustyön aiheuttamasta kuormituksesta työpäivän jälkeen (sekä henkinen että ruumiillinen kuormitus).", 
       sv: "Jag hinner återhämta mig tillräckligt från lantbruksarbetets belastning efter arbetsdagen (både från psykisk och fysisk belastning)."}, 
       results_title: 
       {fi: "Palautuminen", sv: "Återhämtning"}
      , options: [
        {id: 1, fi: "Täysin samaa mieltä", sv: "Helt av samma åsikt"},
        {id: 2, fi: "Jokseenkin samaa mieltä", sv: "Delvis av samma åsikt"},
        {id: 3, fi: "Jokseenkin eri mieltä", sv: "Delvis av annan åsikt"},
        {id: 4, fi: "Täysin eri mieltä", sv: "Helt av annan åsikt"},
        ]
    },
    "tyky_3":{id: "tyky_3", form_number: '3', type: "options_check", eval_type: "scale_4", title: 
      {fi: "Vapaa-ajalla/lomalla pääsen irtautumaan riittävästi maatilan töistä.", 
       sv: "På fritiden/semestern kan jag koppla bort från arbetet på gården i tillräckligt hög grad."}, 
       results_title: 
       {fi: "Työstä irtautuminen", sv: "Att koppla bort från arbetet"}
      , options: [
        {id: 1, fi: "Täysin samaa mieltä", sv: "Helt av samma åsikt"},
        {id: 2, fi: "Jokseenkin samaa mieltä", sv: "Delvis av samma åsikt"},
        {id: 3, fi: "Jokseenkin eri mieltä", sv: "Delvis av annan åsikt"},
        {id: 4, fi: "Täysin eri mieltä", sv: "Helt av annan åsikt"},
        ]
    },
    "tyky_4":{id: "tyky_4", form_number: '4', type: "options_check", eval_type: "scale_5", title: 
      {fi: "Oletko viime aikoina tuntenut itsesi toiveikkaaksi tulevaisuuden suhteen?", 
       sv: "Har du under den senaste tiden känt hoppfullhet inför framtiden?"}, 
       results_title: 
       {fi: "Toiveikkuus", sv: "Hoppfullhet"}
      , options: [
        {id: 1, fi: "Usein", sv: "Ofta"},
        {id: 2, fi: "Melko usein", sv: "Ganska ofta"},
        {id: 3, fi: "Silloin tällöin", sv: "Ibland"},
        {id: 4, fi: "Melko harvoin", sv: "Ganska sällan"},
        {id: 5, fi: "En koskaan", sv: "Aldrig"},
        ]
    },
    "tyky_5":{id: "tyky_5", form_number: '5', type: "options_check", eval_type: "scale_5", title: 
      {fi: "Stressillä tarkoitetaan tilannetta, jossa ihminen tuntee itsensä jännittyneeksi, levottomaksi, hermostuneeksi tai ahdistuneeksi taikka hänen on vaikea nukkua asioiden vaivatessa jatkuvasti mieltä. Tunnetko sinä nykyisin tällaista stressiä?", 
       sv: "Med stress avses en situation där man känner sig spänd, rastlös, nervös eller har ångest, eller har svårt att sova när man inte kan släppa tankarna på problemen. Brukar du känna stress av det här slaget?"}, 
       results_title: 
       {fi: "Stressi", sv: "Stress"}
      , options: [
        {id: 1, fi: "Ei lainkaan", sv: "Inte alls"},
        {id: 2, fi: "Vain vähän", sv: "Bara lite"},
        {id: 3, fi: "Jonkin verran", sv: "I viss mån"},
        {id: 4, fi: "Melko paljon", sv: "Ganska mycket"},
        {id: 5, fi: "Erittäin paljon", sv: "Väldigt mycket"},
        ]
    },
    "tyky_6":{id: "tyky_6", form_number: '6', type: "table", eval_type: "scale_3", title: 
      {fi: "Onko sinulla ollut viimeisen kuukauden aikana pitkäaikaisesti ", 
       sv: "Har du under den senaste månaden känt något av följande under en längre tid"}, 
       results_title: 
       {fi: "Psyykkinen hyvinvointi", sv: "Psykiskt välbefinnande"}
      , options: [
        {id: 1, fi: "muistin tai keskittymiskyvyn heikkenemistä", sv: "Sämre minne eller koncentrationsförmåga "},
        {id: 2, fi: "masentuneisuutta, alakuloisuutta tai toivottomuutta", sv: "Depression, nedstämdhet eller känsla av hopplöshet "},
        {id: 3, fi: "voimattomuutta tai väsymystä", sv: "Kraftlöshet eller trötthet "},
        {id: 4, fi: "unettomuutta tai nukahtamisvaikeuksia", sv: "Sömnlöshet eller insomningssvårigheter "},
        {id: 5, fi: "ärtyisyyttä tai kiukkuisuutta", sv: "Lättretlighet eller ilska "},
        {id: 6, fi: "mielenkiinnon puutetta tai haluttomuutta", sv: "Ointresse eller ovilja"},
        ]
    },
    "tyky_7":{id: "tyky_7", form_number: '7', type: "scale", eval_type: "scale_11", title: 
      {fi: "Oletetaan, että työkykysi on parhaimmillaan saanut 10 pistettä. Minkä pistemäärän antaisit nykyiselle työkyvyllesi asteikolla 0–10?  0 tarkoittaa, ettet nykyisin pysty lainkaan työhön – 10 työkyky parhaimmillaan", 
       sv: "Låt oss anta att din arbetsförmåga har fått fulla 10 poäng när den var på topp. Hur skulle du poängsätta din nuvarande arbetsförmåga på skalan 0–10?  0 betyder att du är helt oförmögen att arbeta – 10 betyder att arbetsförmågan är på topp."}, 
       results_title: 
       {fi: "Työkyky", sv: "Arbetsförmåga"}
      , options: [
        {id: 0, fi: "Punainen", sv: "Rött"},
        {id: 1, fi: "Punainen", sv: "Rött"},
        {id: 2, fi: "Punainen", sv: "Rött"},
        {id: 3, fi: "Punainen", sv: "Rött"},
        {id: 4, fi: "Punainen", sv: "Rött"},
        {id: 5, fi: "Punainen", sv: "Rött"},
        {id: 6, fi: "Keltainen", sv: "Gult"},
        {id: 7, fi: "Keltainen", sv: "Gult"},
        {id: 8, fi: "Vihreä", sv: "Grönt"},
        {id: 9, fi: "Vihreä", sv: "Grönt"},
        {id: 10, fi: "Vihreä", sv: "Grönt"},
        ]
    },
    "tyky_8":{id: "tyky_8", form_number: '8', type: "options_check", eval_type: "scale_5", title: 
      {fi: "Millaiseksi arvioit nykyisen työkykysi työsi ruumiillisten vaatimusten kannalta?", 
       sv: "Hur bedömer du din nuvarande arbetsförmåga med tanke på de fysiska krav som ditt arbete ställer? "}, 
       results_title: 
       {fi: "Fyysinen työkyky", sv: "Fysisk arbetsförmåga"}
      , options: [
        {id: 1, fi: "Erittäin hyvä", sv: "Mycket god"},
        {id: 2, fi: "Melko hyvä", sv: "Ganska god"},
        {id: 3, fi: "Kohtalainen", sv: "Måttlig"},
        {id: 4, fi: "Melko huono", sv: "Ganska dålig"},
        {id: 5, fi: "Erittäin huono", sv: "Mycket dålig"},
        ]
    },
    "tyky_9_0":{id: "tyky_9_0", form_number: '9', type: "options_check", results_hidden: true, title: 
      {fi: "Onko sinulle sattunut maataloustyössä työtapaturma tai läheltä piti -tilanne?", 
       sv: "Har du råkat ut för ett olycksfall i arbetet eller ett tillbud i samband med lantbruksarbetet?"}, 
       options: [
        {id: "true", fi: "Kyllä", sv: "Ja"},
        {id: "false", fi: "Ei", sv: "Nej"},
        ]
    },
    "tyky_9":{id: "tyky_9", show_only_when: {question: "tyky_9_0", answer: "true"}, type: "options_check", eval_type: "scale_6", title: 
      {fi: "Oletko tehnyt työhösi muutoksia tapaturman tai läheltä piti -tilanteen satuttua tai onko asenteesi työturvallisuutta kohtaan muuttunut?", 
       sv: "Har du gjort ändringar i ditt arbete efter en olycka eller ett olyckstillbud, eller har din inställning till säkerheten i arbetet förändrats?"}, 
       results_title: 
       {fi: "Työturvallisuusasenne", sv: "Inställning till arbetssäkerhet"}
      , options: [
        {id: 1, fi: "Aina", sv: "Alltid"},
        {id: 2, fi: "Lähes aina", sv: "Nästan alltid"},
        {id: 3, fi: "Toisinaan", sv: "Ibland"},
        {id: 4, fi: "Harvoin", sv: "Sällan"},
        {id: 5, fi: "En koskaan", sv: "Aldrig"},
        ]
    },
    "tyky_10":{id: "tyky_10", form_number: '10', type: "table", eval_type: "scale_3", title: 
      {fi: "Kuormittaako jokin näistä maatilayrityksen toimintaan liittyvistä asioista sinua liian paljon?", 
       sv: "Belastar någon av dessa uppgifter i anslutning till lantbruksföretagets verksamhet dig för mycket?"}, 
       results_title: 
       {fi: "Maatilan kuormitustekijät", sv: "Faktorer som utgör en belastning i lantbruket"}
      , options: [
        {id: 1, fi: "Taloustilanne", sv: "Den ekonomiska situationen"},
        {id: 2, fi: "Viranomaisselvitykset", sv: "Myndigheternas utredningar"},
        {id: 3, fi: "Tilan johtaminen", sv: "Arbetsledningen av gården"},
        {id: 4, fi: "Työkyky (oma tai puolison)", sv: "Arbetsförmågan (din eller din makas/makes)"},
        {id: 5, fi: "Oma osaaminen", sv: "Din egen kompetens"},
        {id: 6, fi: "Rästissä olevat työt", sv: "Ogjorda arbeten"},
        {id: 7, fi: "Ihmissuhteet", sv: "Sociala relationer"},
        {id: 8, fi: "Yllättävä ja äkillinen kriisi", sv: "En överraskande och plötslig kris"},
        {id: 9, fi: "Muutostilanne", sv: "En förändringssituation"},
        {id: 10, fi: "Huoli omasta/puolison jaksamisesta", sv: "Oro för att du eller din maka/make ska orka"},
        {id: 11, fi: "Jokin muu asia, joka vaivaa mieltä", sv: "Något annat som bekymrar dig"},
        ]
      },
      "tyky_11":{id: "tyky_11", form_number: '11', type: "user_profile", title: 
      {fi: "Lopuksi kysymme sinulta joitakin taustatietoja henkilökohtaista palautettasi varten.", 
       sv: "Slutligen ställer vi några frågor om din bakgrund för din personliga respons."},
       results_title: {
         fi: "Taustatiedot",
         sv: "Bakgrundsinformation"
        }
      }
  }

export const production_line_range = [ "milk","bovine",  "swine", "egg",  "poultry_meat", "sheep", "other_livestock_production","grain", "other_plant", "garden_plant", "other_specialty_plant", "forest", "other"];