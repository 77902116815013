import React, { Component } from 'react'
import I18n from 'i18n-js';
import jQuery from 'jquery';
import Select from 'react-select';
import Modal from './Modal';

import { production_line_range } from "../config/questions";

export default class GroupModal extends Modal {

    constructor(props)
    {
        super(props);
      
        this.state = {
            show: false,
            loading: false,
            error: false,
            selection_changed: false,
            current_selection_count: null,
            selected: {...this.props.group_properties}
        }
    };

    render() {
        let {show, selected, loading, error, current_selection_count, selection_changed} = this.state;
        let {group_properties, default_group_properties, current_group_count, selection_group_count, group_error} = this.props;

        let group_count = (selection_changed ? current_selection_count : selection_group_count);
        let selection_error = (selection_changed ? error : group_error);
        
        let {age, sex, production_line} = selected;
        return <>
        <a onClick={this.toggleShow} className=""  style={{cursor: "pointer", textDecoration:"none"}}>{I18n.t("results.edit_group")}</a>
        {this.renderModal(
            <>
            <div className={"btn btn-primary float-right ms-2 "+((loading || error || group_count == null)?"disabled":"")} onClick={this.applyChanges}>
            {I18n.t("apply")}{loading && <div className="spinner-border spinner-border-sm ms-2" role="status">
                <span className="visually-hidden">{I18n.t("loading")}...</span>
            </div>}</div>
            <div className="btn btn-secondary float-right" onClick={this.toggleShow}>{I18n.t("cancel")}</div>
            <strong><h5 style={{fontWeight: "600"}}>{I18n.t("results.edit_group")}</h5></strong>
            <strong className="black"><label className="mt-2">{I18n.t("age")}</label></strong><br/>
            <div className="form-check form-check-inline">
                <input type="checkbox" className="form-check-input" id="age1" onChange={this.handleChange} name="age" value={[1,2]} checked={age.includes(2)}/>
                <label className="form-check-label" htmlFor="age1">{I18n.t("less_than")} 30</label>
            </div>
            <div className="form-check form-check-inline">
                <input type="checkbox" className="form-check-input" id="age2" onChange={this.handleChange} name="age" value={[3]} checked={age.includes(3)}/>
                <label className="form-check-label" htmlFor="age2">30-39</label>
            </div>
            <div className="form-check form-check-inline">
                <input type="checkbox" className="form-check-input" id="age3" onChange={this.handleChange} name="age" value={[4]} checked={age.includes(4)}/>
                <label className="form-check-label" htmlFor="age3">40-49</label>
            </div>
            <div className="form-check form-check-inline">
                <input type="checkbox" className="form-check-input" id="age4" onChange={this.handleChange} name="age" value={[5]} checked={age.includes(5)}/>
                <label className="form-check-label" htmlFor="age4">50-59</label>
            </div>
            <div className="form-check form-check-inline">
                <input type="checkbox" className="form-check-input" id="age5" onChange={this.handleChange} name="age" value={[6]} checked={age.includes(6)}/>
                <label className="form-check-label" htmlFor="age5">60-69</label>
            </div>
            <div className="form-check form-check-inline">
                <input type="checkbox" className="form-check-input" id="age6" onChange={this.handleChange} name="age" value={[7,8,9,10,11]} checked={age.includes(7)}/>
                <label className="form-check-label" htmlFor="age6">70+</label>
            </div><br/>

            <strong className="black"><label className="mt-2">{I18n.t("sex")}</label></strong><br/>
            <div className="form-check form-check-inline">
                <input type="checkbox" className="form-check-input" id="sex1"  onChange={this.handleChange} name="sex" value={["m"]}checked={sex.includes("m")}/>
                <label className="form-check-label" htmlFor="sex1">{I18n.t("man")}</label>
            </div>
            <div className="form-check form-check-inline">
                <input type="checkbox" className="form-check-input" id="sex2"  onChange={this.handleChange} name="sex" value={["f"]} checked={sex.includes("f")}/>
                <label className="form-check-label" htmlFor="sex2">{I18n.t("woman")}</label>
            </div><br/>

            <strong className="black"><label className="mt-2" htmlFor="production_line_select">{I18n.t("production_line")}</label></strong>

            <div>
            {production_line_range.map((v, idx) => {
                return <div className="form-check form-check-inline col-12 col-md-6" key={idx} style={{marginRight: "0"}}>
                    <input type="checkbox" className="form-check-input" id={"production_line."+v}  onChange={this.handleChange} name="production_line" value={[v]} checked={production_line.includes(v)}/>
                    <label className="form-check-label" htmlFor={"production_line."+v}>{I18n.t("production_lines."+v)}</label>
                </div>
                })
            }
            </div>
            
            {/* <Select isMulti id="production_line_select"  name="production_line" options={production_line_range.map(v => {return {label: I18n.t("production_lines."+v), value: v}})} 
            value={
                production_line.map(v => {
                return {label: I18n.t("production_lines."+v), value: v};
                })
            } onChange={(a) => {this.handleProductionLineChange("production_line", a.map(v => {return v.value;})) }}></Select> */}
            {/* <select className="form-control" id="production_line_select" onChange={this.handleChange} name="production_line" value={production_line}>
                <option>{I18n.t("choose")+"..."}</option>
                <option>Something</option>
            </select> */}

            <br/>
            {selection_error && <div className="alert alert-danger" role="alert">
            {I18n.t("errors.small_group")}
            </div>}
            <div className="row">
                <div className="col md-6">
                    <p style={{fontSize: "90%"}}>{`${I18n.t("results.comparison_group_size")}: ${group_count != null ? group_count : `${I18n.t("loading")}...`}`}</p>
                </div>
                <div className="col md-6" style={{textAlign: "right"}}>
                    {selection_changed && <a style={{fontSize: "90%"}} onClick={this.resetGroup} href="#">{I18n.t("results.restore_comparison_group")}</a>} 
                </div>
            </div>
            <p style={{fontSize: "90%"}}>{I18n.t("results.comparison_group_information")}</p>


        </>, false)}</>
    }

    toggleShow =() =>{
        this.setState(prevState => {
            return {show: !prevState.show, error: false, loading: false, selected: this.props.group_properties, current_selection_count: null, selection_changed: false}
        })
    }

    addOrRemove(array, value) {
        var index = array.indexOf(value);
        if (index === -1) {
            array.push(value);
        } else {
            array.splice(index, 1);
        }
    }

    handleChange = (event) => {
        let key = event.target.name
        let values = event.target.value.split(",")
        this.setState(prevState => {
            let selected = {... prevState.selected}
            let prevValues = [...selected[key]]
            values.forEach(v => {
                this.addOrRemove(prevValues, isNaN(v) ? v : parseInt(v))
            })
            selected[key] = prevValues;
            return {selected: selected, selection_changed: true}
        }, this.getSelectionAnswers)
    }

    // handleProductionLineChange = (key, arr) => {
    //     this.setState(prevState => {
    //         let selected = {... prevState.selected}
    //         selected[key] = arr;
    //         return {selected: selected}
    //     })
    // }

    getSelectionAnswers = () => {
        this.setState({current_selection_count: null}, () =>{
            this.props.getGroupAnswers(false, this.state.selected).then(result =>{
                this.setState({current_selection_count: result.group_count, error: result.group_error})
            }).catch(e =>{
                this.setState({error: true})
            })
        })
        //this.setState({loading:true}, this.props.resetGroupProperties(()=>{}, this.showError))
    }
    resetGroup = () => {
        this.setState({selected: this.props.default_group_properties, selection_changed: false, error: false})
        //this.setState({loading:true}, this.props.resetGroupProperties(()=>{}, this.showError))
    }
    applyChanges = () => {
        if(!this.state.error){
            this.setState({loading:true}, this.props.setGroupProperties(this.state.selected, this.toggleShow, this.showError))
        }
    }
    showError = () => {
        this.setState({loading:false, error: true})
    }

}